import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { IconSpinner, ListingLink } from '../../components';
import { EditListingAvailabilityForm } from '../../forms';
import config from '../../config';


import css from './EditListingAvailabilityPanel.css';
import { DEFAULT_TIMEZONE, getDefaultTimeZoneOnBrowser } from '../../util/dates';

const { availableTimezones } = config.custom;


class EditListingAvailabilityPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentPublish: false,
      localTimezone: getDefaultTimeZoneOnBrowser()
    };
  }

  makeAvailblityPlanFromValue = (values, listingTimezone) => {
    const availabilityPlan = {
      type: 'availability-plan/time',
      timezone: listingTimezone || 'Asia/Singapore',
      entries: [
        { dayOfWeek: 'mon', seats: values.monday ? 1 : 0, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'tue', seats: values.tuesday ? 1 : 0, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'wed', seats: values.wednesday ? 1 : 0, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'thu', seats: values.thursday ? 1 : 0, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'fri', seats: values.friday ? 1 : 0, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'sat', seats: values.saturday ? 1 : 0, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'sun', seats: values.sunday ? 1 : 0, startTime: '00:00', endTime: '00:00' },
      ],
    };
    return availabilityPlan
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      availability,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      currentUser,
      disabled,
      ready,
      submitButtonId,
      onUpdateAvailability,
      timeZone,
      isNewListingFlow,
      onManageDisableScrolling
    } = this.props;

    const { localTimezone } = this.state;


    const {
      paddingHours,
      hourlyBooking = true,
      isHourlyUnBlocking = false,
      minimumHourlyDuration = 1,
      minimumDailyDuration = 1,
      instantBooking,
      longTermRental,
      location
    } = listing.attributes.publicData || {};

    const { timezone } = location;
    let listingTimezone = timezone;

    const isPCar =
      !!(
        listing &&
        listing.id &&
        listing.attributes.publicData &&
        listing.attributes.publicData.vehicleType &&
        listing.attributes.publicData.vehicleType.match(/^P/i)
      );

    console.log('isPCar', isPCar);

    const stripeConnected =
      !!currentUser && !!currentUser.id && currentUser.attributes.stripeConnected;

    // let isHourlyAvailabilityEligible = false;
    // if(currentUser) {
    //   isHourlyAvailabilityEligible = currentUser.attributes.profile && currentUser.attributes.profile.publicData.isHourlyAvailabilityEligible;
    // }
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    console.log('currentListing', currentListing)

    const listingAvailabilityPlan = currentListing.attributes.availabilityPlan;
    const listingAvailabilityPlanUpdated = { ...listingAvailabilityPlan, timezone: listingTimezone };
    const availCreateTimezone = listingTimezone ? listingTimezone: DEFAULT_TIMEZONE;

    const defaultAvailabilityPlan = {
      type: 'availability-plan/time',
      timezone: availableTimezones.includes(localTimezone) ? localTimezone : availCreateTimezone,
      entries: [
        { dayOfWeek: 'mon', seats: isPCar ? 0 : 1, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'tue', seats: isPCar ? 0 : 1, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'wed', seats: isPCar ? 0 : 1, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'thu', seats: isPCar ? 0 : 1, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'fri', seats: isPCar ? 0 : 1, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'sat', seats: 1, startTime: '00:00', endTime: '00:00' },
        { dayOfWeek: 'sun', seats: 1, startTime: '00:00', endTime: '00:00' },
      ],
    };

    let updatedEntries = [];
    if (listingAvailabilityPlanUpdated && listingAvailabilityPlanUpdated.entries) {
      updatedEntries = listingAvailabilityPlanUpdated.entries.map(entry => {
        if (isPCar && ['mon', 'tue', 'wed', 'thu', 'fri'].includes(entry.dayOfWeek)) {
          return { ...entry, seats: 0 };
        }
        return entry;
      });
    }

    const availabilityPlan = listingAvailabilityPlanUpdated.entries
      ? { ...listingAvailabilityPlanUpdated, entries: updatedEntries }
      : defaultAvailabilityPlan;


    const currentTimeZone = availableTimezones.includes(availabilityPlan.timezone) ?
                                     availabilityPlan.timezone :
                                     availCreateTimezone;
    const weekObj = {}
    const weeklyAvailblityPlan = availabilityPlan && availabilityPlan.entries.map(dayPlan => {
      if (dayPlan.dayOfWeek == 'mon') {
        weekObj['monday'] = dayPlan.seats ? true : false
      }
      if (dayPlan.dayOfWeek == 'tue') {
        weekObj['tuesday'] = dayPlan.seats ? true : false
      }
      if (dayPlan.dayOfWeek == 'wed') {
        weekObj['wednesday'] = dayPlan.seats ? true : false
      }
      if (dayPlan.dayOfWeek == 'thu') {
        weekObj['thursday'] = dayPlan.seats ? true : false
      }
      if (dayPlan.dayOfWeek == 'fri') {
        weekObj['friday'] = dayPlan.seats ? true : false
      }
      if (dayPlan.dayOfWeek == 'sat') {
        weekObj['saturday'] = dayPlan.seats ? true : false
      }
      if (dayPlan.dayOfWeek == 'sun') {
        weekObj['sunday'] = dayPlan.seats ? true : false
      }
    })

    const title = isPublished ? (
      <FormattedMessage
        id="EditListingAvailabilityPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : !this.state.sentPublish ? (
      <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
    ) : (
      <FormattedMessage id="EditListingAvailabilityPanel.thanks" />
    );

    const disableAllException = false;

    const content = this.state.sentPublish ? (
      <div className={classNames(css.title, css.thanksContainer)}>
        <FormattedMessage
          id="EditListingAvailabilityPanel.thanksText"
          values={{ newline: <br /> }}
        />
        {!stripeConnected ? (
          <div>
            <FormattedMessage id="EditListingVerificationForm.waitingForStripeChecking" />
            <div className={css.loader}><IconSpinner /></div>
          </div>
        ) : null}
      </div>
    ) : (
      <EditListingAvailabilityForm
        className={css.form}
        listingId={currentListing.id}
        onManageDisableScrolling={onManageDisableScrolling}
        initialValues={{
          ...weekObj,
          // availabilityPlan,
          paddingHours: paddingHours !== undefined ? paddingHours : 2,
          hourlyBooking,
          isHourlyUnBlocking,
          minimumDailyDuration,
          minimumHourlyDuration,
          timezone: listingTimezone || location.timezone || undefined
        }}
        availability={availability}
        availabilityPlan={availabilityPlan}
        onSubmit={values => {

          let { paddingHours = 2, hourlyBooking, isHourlyUnBlocking } = values;
          const availabilityPlan = this.makeAvailblityPlanFromValue(values);

          paddingHours = parseInt(paddingHours);
          const data = {
            availabilityPlan,
            publicData: {
              hourlyBooking,
              isHourlyUnBlocking,
              paddingHours,
            },
          };
          if (hourlyBooking) {
            data.publicData.minimumHourlyDuration = 1;
          } else {
            data.publicData.minimumDailyDuration = 1;
          }
          onSubmit(data);
        }}
        // isHourlyAvailabilityEligible={isHourlyAvailabilityEligible}
        isHourlyUnBlocking={isHourlyUnBlocking}
        onChange={onChange}
        onUpdateAvailability={onUpdateAvailability}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
        submitButtonId={submitButtonId}
        timeZone={currentTimeZone}
        disableAllException={disableAllException}
        isPCar={isPCar}
      />
    );

    return (
      <div className={classes}>
        {/* <h1 className={css.title}>{title}</h1> */}
        <div className={css.titleSection}>
          <h1 className={css.title}>Availability</h1>
          {/* <p>
            Set typical hours for days of the week your car is available and set availability for
            specific dates.
          </p> */}
        </div>
        {content}
      </div>
    );
  }
}

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
  }).isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
  onUpdateAvailability: func,
};

export default EditListingAvailabilityPanel;
