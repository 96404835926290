import React from 'react';
import { Modal } from '../../components';
import { bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import css from './ModalHelp.css';
import image from '../../assets/1032504550-huge.jpg';
import phoneIcon from '../../assets/phone-solid.svg';
import chatIcon from '../../assets/icon_chat.svg';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { event_trigger_ids } from '../../util/analyticsConstants';

const ModalHelp = props => {
  const { isOpen, onClose, onManageDisableScrolling } = props;

  const title = (
    <h2 className={css.title}>
      <FormattedMessage id="ModalHelp.Title" />
    </h2>
  );
  const description = (
    <p className={css.description}>
      <FormattedMessage id="ModalHelp.firstParagraph" />
    </p>
  );
  const openChat = async () => {

    window.Intercom('show');

    try {
      const response = await getEventsByBucket('browsing');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.BROWSE_NEED_HELP_INTERCOM_CHAT,
        eventData: response.data,
        props: {
          button:  'Chat with us',
        },
        userId: props.currentUser ? props.currentUser.id.uuid : null,
      });

    } catch (error) {
      console.log('Error fetching events:', error);
    }

  };

  const onWhatsAppClick = async () => {
    try {
      const response = await getEventsByBucket('browsing');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.BROWSE_NEED_HELP_WHATSAPP_CHAT,
        eventData: response.data,
        props: {
          button:  'Chat with us on Whatsapp',
        },
        userId: props.currentUser ? props.currentUser.id.uuid : null,
      });

    } catch (error) {
      console.log('Error fetching events:', error);
    }

  }

  return (
    <Modal
      isOpen={isOpen}
      containerClassName={css.modal}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={onClose}
      closeButtonMessage={' '}
    >
      {' '}
      <div className={css.main}>
        <img className={css.image} src={image} alt="" />
        <div className={css.content}>
          {title}
          {description}
          <div className={css.block}>
            <div className={css.imageContainer}>
              <img className={css.phoneIcon} src={phoneIcon} alt="telephone" />
            </div>
            <span className={css.optionText}>
              <FormattedMessage id="ModalHelp.urgentAssistance" />{' '}
              <a className={css.link} href="tel:+6564328307">
                +65 6432 8307
              </a>
            </span>
          </div>
          <div className={css.block}>
            <div className={css.imageContainer}>
              <img className={css.phoneIcon} src={phoneIcon} alt="telephone" />
            </div>
            <span className={css.optionText}>
              <FormattedMessage id="ModalHelp.generalHelp" />{' '}
              <a className={css.link} href="tel:+6564328307">
                +65 6432 8307
              </a>
            </span>
          </div>
          <div className={css.block}>
            <div className={css.imageContainer}>
              <img src={chatIcon} alt="chat icon" className={css.chatIcon} />
            </div>
            <span onClick={openChat} className={css.optionText}>
              <FormattedMessage id="ContactUsPage.chatWithUs" />
            </span>
          </div>
          <div className={css.block}>
            <div className={css.imageContainer}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path
                  id="whatsapp-brands"
                  d="M20.405,3.487A11.9,11.9,0,0,0,1.687,17.839L0,24l6.305-1.655a11.857,11.857,0,0,0,5.684,1.446h.005A12.007,12.007,0,0,0,24,11.9,11.94,11.94,0,0,0,20.405,3.487Zm-8.411,18.3a9.867,9.867,0,0,1-5.036-1.377L6.6,20.2l-3.739.98,1-3.648-.236-.375A9.9,9.9,0,1,1,21.991,11.9,10,10,0,0,1,11.995,21.787Zm5.421-7.4c-.295-.15-1.757-.868-2.03-.964s-.471-.15-.67.15-.766.964-.943,1.168-.348.225-.643.075a8.089,8.089,0,0,1-4.045-3.536c-.305-.525.305-.488.873-1.623a.551.551,0,0,0-.027-.52c-.075-.15-.67-1.613-.916-2.207-.241-.579-.488-.5-.67-.509s-.37-.011-.568-.011a1.1,1.1,0,0,0-.793.37,3.339,3.339,0,0,0-1.039,2.48,5.822,5.822,0,0,0,1.211,3.075,13.279,13.279,0,0,0,5.079,4.489c1.886.814,2.625.884,3.568.745a3.044,3.044,0,0,0,2-1.414,2.485,2.485,0,0,0,.171-1.414C17.909,14.6,17.711,14.529,17.416,14.384Z"
                  transform="translate(24) rotate(90deg)"
                  fill="#00a3ad"
                />
              </svg>
            </div>
            <span className={css.optionText}>
                <FormattedMessage id="ModalHelp.chatWithUs" />{' '}
                <a
                  className={css.link}
                  href="https://wa.me/6531384785"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={onWhatsAppClick}>
                  Whatsapp
                </a>
              </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalHelp.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onManageDisableScrolling: () => {},
};

ModalHelp.prototype = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default ModalHelp;
