import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { Button, Explanation, FieldToggleSwitch, Form } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { composeValidators, maxValue, minValue, required } from '../../util/validators';
import css from './EditListingAvailabilityForm.css';
import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import infoCircle from '../../assets/updatelisting/info-circle-solid.svg';
import FieldTextInputInfo from '../../components/FieldTextInputInfo/FieldTextInputInfo';

export class EditListingAvailabilityFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
       isHourlyUnBlocking: true,
      //isHourlyUnBlocking: true,
    };
  }

  handleHourlPriceChange = e => {
    if (e.target.value) {
      this.setState({
        isHourlyUnBlocking: true,
      });
    } else {
      this.setState({
        isHourlyUnBlocking: false,
      });
    }
  };

  handlePriceChange = ({ values }) => {
    if (this.state.isHourlyUnBlocking !== values.isHourlyUnBlocking) {
      this.setState({
        isHourlyUnBlocking: values.isHourlyUnBlocking,
      });
    }
  };

  handleDailyChange = e => {
    if (e.target.value) {
      this.setState({
        isHourlyUnBlocking: false,
      });
    } else {
      this.setState({
        isHourlyUnBlocking: true,
      });
    }
  };

  render() {
    const toggleSwitch = (
      <div className={css.btnContainer}>
        <input type="radio" name="preference" id={css.daily} checked={true} />
        <label htmlFor="daily" className={css.toggleLabel}>
          <span>Daily</span>
        </label>
        <input type="radio" name="preference" id={css.hourly} />
        <label htmlFor="hourly" className={css.toggleLabel}>
          <span>Hourly</span>
        </label>
      </div>
    );

    const demandType = 'Good';
    const meterDisplay =
      demandType === 'Bad'
        ? 'rotate(30deg)'
        : demandType === 'Average'
        ? 'rotate(80deg)'
        : demandType === 'Good'
        ? 'rotate(165deg)'
        : 'rotate(0)';

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
            isHourlyUnBlocking,
            initialValues,
            values,
            submitButtonId,
            onUpdateAvailability,
            timeZone,
            disableAllException,
            onManageDisableScrolling,
            isPCar
          } = formRenderProps;

          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}

              <div className={css.displayInlineContainer}>
                <div className={classNames(css.column, css.column12, css.fieldHourlyBooking)}></div>
                {/* <div className={classNames(css.column, css.contentLeft, css.column4)}>
                  <div className={css.leftContainer}>
                    <div className={css.formHeading}>Set weekly schedule</div>
                    <p>
                      Set weekly schedule for days and times when your listing will be available.
                    </p>
                    <div className={css.daysAvailabilityListing}>
                      {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
                        <div
                          className={
                            values[day] ? css.toggleWrapper : css.toggleWrapperDisabled
                          }
                          key={day}
                        >
                          <div className={classNames(css.toggleAvailabilityInput, css.field)}>
                            <FieldToggleSwitch
                              name={day}
                              className={css.availabilityToggle}
                              id={day}
                            />
                            <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                          </div>
                          <span className={css.availabilityLabel}>
                            {values[day] ? 'Available' : 'Blocked'}
                          </span>
                        </div>
                      ))}
                    </div>
                    <Button className={css.availabilitySaveButton} type="submit">
                      Save
                    </Button>
                  </div>
                </div> */}
                <div className={classNames(css.column, css.contentRight, css.column8)}>
                  <div className={css.calendarWrapper}>
                    {/* {this.props.isHourlyAvailabilityEligible ? ( */}
                    {true ? (
                      <div>
                        <div className={css.flexRow}>
                          <div className={classNames(css.formHeading, css.dateOverride)}>Dates Override</div>
                        </div>
                        <p>
                          Set your availability for specific dates by blocking them or setting
                          hourly schedule
                        </p>
                      </div>
                    ) : (
                      ''
                    )}

                    <ManageAvailabilityCalendar
                      // isHourlyAvailabilityEligible={this.props.isHourlyAvailabilityEligible}
                      intl={intl}
                      isHourlyUnBlocking={this.state.isHourlyUnBlocking}
                      onManageDisableScrolling={onManageDisableScrolling}
                      availability={availability}
                      availabilityPlan={availabilityPlan}
                      listingId={listingId}
                      onUpdateAvailability={onUpdateAvailability}
                      timeZone={availabilityPlan.timeZone || timeZone}
                      disableAllException={disableAllException}
                      isPCar={isPCar}
                    />
                  </div>
                </div>

                <div className={css.otherFormInputs}>
                  <div className={classNames(css.column, css.column12, css.inputWrapper)}>
                    <div className={css.customInputLabel}>Notice Period *</div>
                    <div className={css.inputWithPostFix}>
                      <FieldTextInputInfo
                        className={classNames(css.priceInput, css.field, css.newInput)}
                        id="paddingHours"
                        name="paddingHours"
                        type="number"
                        min={0}
                        max={12}
                        errorClassName={classNames(css.error, css.errorClassName)}
                        inputClass={classNames(css.input)}
                        inputContainerClass={classNames(css.inputContainerClass)}
                        postFix={<span className={css.inputPostFix}>hours</span>}
                        validate={composeValidators(
                          required('This field is required'),
                          minValue('Notice hours must be greater than or equal to 0 hour', 0),
                          maxValue('Notice hours must be less than or equal to 12', 12)
                        )}
                      />

                    </div>
                    <div className={css.infoText}>
                      <span className={css.infoIcon}>
                        <img src={infoCircle} alt="" />
                      </span>
                      <p>
                        The notice period you need before each booking. The lower the notice period
                        easier it is for users to book.
                      </p>
                    </div>
                  </div>
                  <div className={classNames(css.column, css.column12, css.fieldHourlyBooking)}>
                    <div className={classNames(css.customToggleInput, css.field)}>
                      <div className={css.toggleLabels}>
                        <label htmlFor="isPublicHolidayPeakDays">Accept hourly bookings</label>
                        <Explanation
                          className={css.customClass}
                          text="Allow rentals for less than 12 hours. Pricing is calculated basis your daily dates. This enables you to get more booking requests."
                        />
                      </div>
                      <div className={css.toggleInput}>
                        <FieldToggleSwitch id="hourlyBooking" name="hourlyBooking" />
                      </div>
                      <div className={css.infoText}>
                        <span className={css.infoIcon}>
                          <img src={infoCircle} alt="" />
                        </span>
                        <p>
                          Allow guests to rent your car for less than 12 hours. We strongly
                          recommend to enable this to boost demand.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={css.stickyButtons}>
                <div className={css.stickButtonsContainer}>
                  <div className={css.stickButtonsDescription}>
                    {/* <span className={css.descIcon}>
                      <img src={meterIcon} alt="" />
                      <img
                        src={meterNeedle}
                        alt=""
                        style={{ transform: meterDisplay }}
                        className={css.needle}
                      />
                    </span>
                    <p>
                      <strong>Good Demand:</strong> Based on your settings you are on your way to
                      attract good level of demand.
                    </p> */}
                  </div>
                  <div className={css.stickButton}>
                    <Button
                      className={css.submitButton}
                      type="submit"
                      inProgress={submitInProgress}
                      disabled={submitDisabled}
                      ready={submitReady}
                      id={submitButtonId}
                    >
                      {/* {saveActionMsg} */}
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
  submitButtonId: string,
  onUpdateAvailability: func,
  isPCar: bool.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
