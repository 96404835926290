import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import config from '../../config';
import { getUserTxRole } from '../../util/transaction';
import { ensureBooking, ensureUser, restoreTransaction } from '../../util/data';
import {
  fetchFuelChildTransaction,
  fetchUpdateBookingTransaction,
} from '../TransactionPage/TransactionPage.duck';

import {
  BookingInfoSection,
  IconSpinner,
  Logo,
  NamedLink,
  Page,
  PartnerInfo,
} from '../../components';
import { $bookingTx, loadData } from './TripDetailsPage.duck';

import css from './TripDetailsPage.css';
import FuelInclusionBreakdown from '../../components/BookingBreakdown/FuelInclusionBreakdown';
import { $fuelPriceByListing } from '../../ducks/FuelPrice.duck';
import HostFuelPackageBlock from '../../components/BookingBreakdown/HostFuelPackageBlock';
import UpdateBookingTxs from '../../components/TripPanel/UpdateBookingTxs';
import {
  $lastUpdateBookingTxByParentTxId,
  $updateBookingTxsByParentTxId,
} from '../EditTripPage/EditTripPage.duck';


const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TripDetailsPageComponent = props => {
  const {
    lastUpdateBookingTx,
    updateBookingTxs,
    fuelPriceByListing,
    scrollingDisabled,
    transaction: parentTransaction,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
    fetchFuelChildTransaction,
    fuelTransaction,
    accessToken
  } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isUsingMastercard, setIsUsingMastercard] = useState(false);

  useEffect(() => {
    const id = get(parentTransaction, 'attributes.metadata.fuelChildTransactionId', null);
    if (parentTransaction && !dataLoaded && id) {
      fetchFuelChildTransaction(id);
    }
  }, [parentTransaction, dataLoaded]);

  useEffect(() => {
    if (parentTransaction && !dataLoaded) {
      setDataLoaded(true);
    }
  }, [parentTransaction, dataLoaded]);


  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentListing = parentTransaction && parentTransaction.listing;
  const currentProvider = parentTransaction && ensureUser(parentTransaction.provider);
  const currentCustomer = parentTransaction && ensureUser(parentTransaction.customer);
  const listingTitle = currentListing && currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });
  const isLoading = !dataLoaded;
  const parentCreatedAt = parentTransaction && parentTransaction.attributes.createdAt;
  const parentDateCreated = parentCreatedAt && moment(parentCreatedAt).format('Do MMM, YYYY');

  const currentUserRole =
    currentUser &&
    currentUser.id &&
    parentTransaction &&
    getUserTxRole(currentUser.id, parentTransaction);
  const isProviderRole = currentUserRole === PROVIDER;
  const isCustomerRole = currentUserRole === CUSTOMER;
  const addonstTransactions = get(parentTransaction, 'attributes.metadata.addonsTransactions');
  const stripeReceiptUrl = get(parentTransaction, 'attributes.metadata.stripeReceiptUrl');
  const fuelPrice = fuelPriceByListing(currentListing);

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (isLoading) {
    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.loading}>
          <IconSpinner />
          <div>
            <FormattedMessage id={'TripDetailsPage.pleaseWait'} />
          </div>
        </div>
      </Page>
    );
  }

  const timeZone = getDefaultTimeZoneOnBrowser() || 'Asia/Singapore';
  const txBooking = parentTransaction && ensureBooking(parentTransaction.booking);
  const isFuelInclusion = get(parentTransaction, 'attributes.protectedData.isFuelInclusion') || get(parentTransaction, 'attributes.metadata.isFuelInclusion');

  const breakdown = parentTransaction && parentTransaction.id && txBooking.id && (
    <BookingBreakdownNew
      timeZone={timeZone}
      className={css.bookingBreakdown}
      userRole={currentUserRole}
      unitType={config.bookingUnitType}
      transaction={restoreTransaction(parentTransaction, isUsingMastercard, transactionRole)}
      booking={txBooking}
      shouldShowMastercardPromoLineItem={isUsingMastercard}
      isTripDetailsPage={true}
      isFuelInclusion={isFuelInclusion}
      fuelPrice={fuelPrice}
    />
  );

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {parentTransaction && (
                <NamedLink
                  className={css.arrowBack}
                  name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: parentTransaction.id.uuid }}
                >
                  <span className={css.arrowBackText}>&#8249;</span>

                  {listingTitle}
                </NamedLink>
              )}
            </div>
            <div className={css.stepTitle}>{'Trip price details'}</div>
          </div>
        </div>
        <div className={css.contentRow}>
          <div className={css.addonsSectionLeft}>
            <div
              className={isCustomerRole ? css.detailsContainerMainFirst : css.detailsContainerMain}
            >
              <h3 className={css.containerTitle}>
                <FormattedMessage
                  id={'TripDetailsPage.parentTransactionTitle'}
                  values={{ date: parentDateCreated }}
                />
              </h3>
              {breakdown}
            </div>

            {isCustomerRole && stripeReceiptUrl ? (
              <>
                <div className={css.linkContainer}>
                  <a href={stripeReceiptUrl} className={css.linkText} target="_blank">
                    <FormattedMessage id={'TripDetailsPage.downloadInvoice'} />
                  </a>
                </div>
              </>
            ) : null}
            <UpdateBookingTxs
              lastUpdateBookingTx={lastUpdateBookingTx}
              updateBookingTxs={updateBookingTxs}
              timeZone={timeZone}
              currentUserRole={currentUserRole}
              isUsingMastercard={isUsingMastercard}
            />
            <FuelInclusionBreakdown
              transaction={restoreTransaction(fuelTransaction, isUsingMastercard)}
              fuelTransaction={fuelTransaction}
              timeZone={timeZone}
              currentUserRole={currentUserRole}
              txBooking={txBooking}
              intl={intl}
            />
            <HostFuelPackageBlock
              isVisible={isProviderRole && isFuelInclusion && !fuelTransaction}
            />
            {addonstTransactions &&
              addonstTransactions.map(i => {
                const childTransaction = i.childTransaction && JSON.parse(i.childTransaction);
                const txBooking = childTransaction && ensureBooking(childTransaction.booking);
                const createdAt = childTransaction && childTransaction.attributes.createdAt;
                const dateCreated = createdAt && moment(createdAt).format('Do MMM, YYYY');
                const isDelivery = i.isDelivery;
                const isExcessReduction = i.isExcessReduction;
                const isFuelInclusion = i.isFuelInclusion;

                return childTransaction ? (
                  !isDelivery && isExcessReduction && isProviderRole ? null : (
                    <div className={css.detailsContainerMain}>
                      <h3 className={css.containerTitle}>
                        <FormattedMessage
                          id={'TripDetailsPage.addonsTitle'}
                          values={{ date: dateCreated }}
                        />
                      </h3>
                      <BookingBreakdownNew
                        timeZone={timeZone}
                        className={css.bookingBreakdown}
                        userRole={currentUserRole}
                        unitType={config.bookingUnitType}
                        transaction={restoreTransaction(childTransaction, isUsingMastercard, transactionRole)}
                        booking={txBooking}
                        isAddons={true}
                        fuelPrice={fuelPrice}
                      />
                    </div>
                  )
                ) : null;
              })}
          </div>
          {isMobileLayout ? null : (
            <div className={css.addonsSectionRight}>
              <div>
                {parentTransaction && currentListing && (
                  <BookingInfoSection
                    transaction={parentTransaction}
                    isCustomer={isCustomerRole}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                )}
                <PartnerInfo
                  currentUserRole={transactionRole}
                  transaction={parentTransaction}
                  otherUser={isProviderRole ? currentCustomer : currentProvider}
                  show={true}
                  isMobileLayout={isMobileLayout}
                  userTypeText={intl.formatMessage({
                    id: isCustomerRole
                      ? 'TripDetailsPage.userTypeText'
                      : 'TripDetailsPage.userTypeTextHost',
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

TripDetailsPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
  parentTransaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

TripDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  parentTransaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  fetchFuelChildTransaction: func.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {authToken} = state.Auth;
  const transaction = $bookingTx(state);
  const fuelTransactionId = get(transaction, 'attributes.metadata.fuelChildTransactionId', null);
  const fuelTransactionEntities = transaction && fuelTransactionId
    ? getMarketplaceEntities(state, [{ id: { uuid: fuelTransactionId }, type: 'transaction' }])
    : null
  ;
  const fuelTransaction = (fuelTransactionEntities && fuelTransactionEntities.length) ? fuelTransactionEntities[0] : null;

  const bookingTxId = transaction && transaction.id.uuid;
  return {
    currentUser,
    transaction,
    fuelTransaction,
    accessToken:authToken ? authToken.access_token:null,
    fuelPriceByListing: $fuelPriceByListing(state),
    updateBookingTxs: $updateBookingTxsByParentTxId(state, bookingTxId),
    lastUpdateBookingTx: $lastUpdateBookingTxByParentTxId(state, bookingTxId),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onFetchUpdateBookingTransaction: params => dispatch(fetchUpdateBookingTransaction(params)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    fetchFuelChildTransaction: id => dispatch(fetchFuelChildTransaction(id)),
  };
};

const TripDetailsPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TripDetailsPageComponent);

TripDetailsPage.loadData = loadData;

export default TripDetailsPage;
