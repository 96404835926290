import classNames from 'classnames';
import { cloneDeep, get } from 'lodash';
import moment from 'moment-timezone';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import deliveryIcon from '../../assets/newCheckoutPage/deliveryIcon.svg';
import addOnImage from '../../assets/newCheckoutPage/fuel-pump.svg';
import mileageIcon from '../../assets/tripPage/mileageIcon.svg';
import premiumProtectionIcon from '../../assets/tripPage/Premium_Cover.png';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import {
  currentUserIsYoungDriver,
  ensureBooking,
  ensureCurrentUser,
  ensureListing,
  ensurePaymentMethodCard,
  ensureStripeCustomer,
  ensureTransaction,
  ensureUser,
  restoreTransaction,
} from '../../util/data';
import {
  calculateBookingDays,
  getDefaultTimeZoneOnBrowser,
  minutesBetween,
} from '../../util/dates';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { findRouteByRouteName, pathByRouteName } from '../../util/routes';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { createSlug, parse } from '../../util/urlHelpers';
import { GTAG_ACTIONS, sendG4AEvent } from '../../util/gtag';

import {
  AddOnsSection,
  AvatarMedium,
  Button,
  CheckoutBreakdownLongTerm,
  IconSpinner,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
} from '../../components';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { handleCardPayment, retrievePaymentIntent } from '../../ducks/stripe.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { StripePaymentForm } from '../../forms';
import { formatMoney } from '../../util/currency';
import {
  isTransactionChargeDisabledError,
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionInitiateInvalidVoucher,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE,
  TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST,
  txIsPaymentExpired,
  txIsPaymentPending,
} from '../../util/transaction';

import { emitter, Experiment, Variant } from '@marvelapp/react-ab-test';
import {
  calculateAllowedMillage,
  getMileageValuefromSliderMileageValue,
  sliderOptionsList,
} from '../../components/AddOnsSection/AddonsUtils';
import AlertBox from '../../components/AlertBox/AlertBox';
import LineItemBookingPeriodNew from '../../components/BookingBreakdown/LineItemBookingPeriodNew';
import { $fuelPriceByListing } from '../../ducks/FuelPrice.duck';
import { isFuelAddonVisible } from '../../util/addonsHelpers';
import {
  ADDON_NAME__MAXIMUM_PROTECTION,
  ADDON_NAME__MILEAGE_PACKAGE,
  ADDON_NAME__PREMIUM_PROTECTION,
  FUEL_INCLUSION_ADDON__BRIEF,
  FUEL_INCLUSION_ADDON__UNITS,
} from '../../util/constants/addons';
import { distanceCountHelper } from '../../util/distanceCountHelper';
import { convertFuelPrice } from '../../util/fuelPrice.helper';
import {
  EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST,
  EVENT_BOOK_CREATED_REQUEST_GUEST,
  EVENT_BOOK_EXCESS_2_SUCCESS_GUEST,
  EVENT_BOOK_EXCESS_SUCCESS_GUEST,
  EVENT_BOOK_FUEL_SUCCESS_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_HOST,
  EVENT_BOOK_SAVED_CARD_DETAILS,
  EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST,
  EVENT_BOOK_USED_PRESAVED_DETAILS,
  EVENT_DELIVERY_ADDON_ADDED,
  EVENT_DELIVERY_ADDON_REMOVED, EVENT_DELIVERY_SUCCESS_GUEST,
  EVENT_EXCESS_2_ADDON_ADDED,
  EVENT_EXCESS_2_ADDON_REMOVED,
  EVENT_EXCESS_ADDON_ADDED,
  EVENT_EXCESS_ADDON_REMOVED,
  EVENT_FUEL_ADDON_ADDED,
  EVENT_FUEL_ADDON_REMOVED,
  EVENT_ORDER_COMPLETED,
  EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST,
  SEND_REQUEST_BOOKING_BUTTON_ID,
  SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST,
  SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST,
} from '../../util/gtm/gtmConstants';
import {
  createRawPropertiesForGTM,
  getEventsByBucket,
} from '../../util/gtm/gtmCreateProperties';
import {
  initiateEventFromTransaction,
  initiateExperimentEventFromListing,
  pushDataLayer,
  pushGTMBookEvent,
  pushGTMBookEventAfterOrderCompleted,
} from '../../util/gtm/gtmHelpers';
import { calculateLongTermPriceWithFeeOneMonth } from '../../util/longTermRentalHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  sendSmoveUserBookingNotification,
  sendStripeErrorSlackNotification,
} from '../../util/slackNotify';
import { getUpdateObjs, makeChildUpdateBookingDates } from '../../util/updateTransaction';
import { requestToUpdateBooking } from '../TransactionPage/TransactionPage.duck';
import css from './CheckoutPage.css';
import {
  confirmPayment,
  confirmPaymentDeposit,
  fetchDepositTx,
  initiateOrder,
  sendMessage,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
} from './CheckoutPage.duck';
import { checkCanUseMastercardPromo } from './CheckoutPage.helpers';
import { clearData, storeData, storedData } from './CheckoutPageSessionHelpers';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';
// import { TripFeesExperiment } from '../../util/constants';
// import { getTripFeesExperimentCase } from '../../util/helpers';

const STORAGE_KEY = 'CheckoutPage';
const LIMIT_DISTANCE = 6;
const LIMIT_START_HOUR = 9;
const LIMIT_END_HOUR = 21;
const sixMonthFromNow = new Date(new Date().setMonth(new Date().getMonth() + 5));
let particularVASParams = {
  booking_vas_excess: false,
  booking_vas_excess_2: false,
  booking_vas_fuel: false,
};

let EXPERIMENT_DATA = {};
let initialPageData = null;
let mapAbSegment = 'B';

const { LatLng } = sdkTypes;

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
export const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
export const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
    ? PAY_AND_SAVE_FOR_LATER_USE
    : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
    ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
    : false;
};

const getTransactionBookings = transactions => {
  if (!transactions || !Array.isArray(transactions)) return null;
  const transactionsWithBookings = transactions.filter(
    tx => tx.booking && tx.booking.type === 'booking'
  );
  const bookings = transactionsWithBookings.map(tx => tx.booking);
  return bookings.length > 0 ? bookings : bookings;
};

// return true if the time of booking1 matches exactly
// the time (days and hours) of booking2, false otherwise
const compareTwoBookingsTime = (booking1, booking2) => {
  const { displayStart: booking1StartTime, displayEnd: booking1EndTime } = booking1.attributes;
  const { displayStart: booking2StartTime, displayEnd: booking2EndTime } = booking2.attributes;
  if (!booking1StartTime || !booking1EndTime || !booking2StartTime || !booking2EndTime) {
    return false;
  }
  const b1StartTime = new Date(booking1StartTime).getTime();
  const b1EndTime = new Date(booking1EndTime).getTime();
  const b2StartTime = new Date(booking2StartTime).getTime();
  const b2EndTime = new Date(booking2EndTime).getTime();
  if (b1StartTime !== b2StartTime || b1EndTime !== b2EndTime) return false;
  return true;
};

// Count the number of distinct trips, trips that have same time and number are counted as one
const findNumberOfDistinctTrips = transactions => {
  const bookings = getTransactionBookings(transactions);
  const bookingLength = bookings.length;
  let visitedBookings = new Array(bookings.length);
  let finished = false;
  let processedBookingsCount = 0;
  let numberOfDistinctTrips = 0;

  for (var i = 0; i < bookingLength; i++) {
    const bookingI = cloneDeep(bookings[i]);
    if (!visitedBookings[i]) {
      for (var j = i + 1; j < bookingLength; j++) {
        if (!visitedBookings[j]) {
          const bookingJ = cloneDeep(bookings[j]);
          const areTwoBookingMadeAtTheSameTime = compareTwoBookingsTime(bookingI, bookingJ);
          if (areTwoBookingMadeAtTheSameTime) {
            visitedBookings[j] = true;
            processedBookingsCount++;
            if (processedBookingsCount === bookingLength) {
              finished = true;
              break;
            }
          }
        }
      }
      numberOfDistinctTrips++;
      if (finished) break;
      visitedBookings[i] = true;
      processedBookingsCount++;
      if (processedBookingsCount === bookingLength) break;
    }
  }
  return numberOfDistinctTrips;
};

export const checkoutStepConfiguration = {
  CHECKOUT_ADD_ONS: 'addOns',
  CHECKOUT_PAYMENT: 'payments',
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      isRetryButtonClicked: false,
      shouldRedirectToSearchPage: false,
      isUsingMastercard: false,
      enteredCard: null,
      nextLocation: {},
      isOpenConfirmLeaveModal: false,
      isBlocking: true,
      isDeliveryState: false,
      totalAmount: 0,
      isPtotectionPlanState: false,
      isFuelInclusionState: false,
      checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
      checkoutStepName: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
      isExcessReductionState: false,
      isExcessReduction2State: false,
      isMileagePackageIncludedState: false,
      isFuelSelectedManually: false,
      isAlert: false,
      selectedMileage: 0,
      millagePerDay: null,
      millagePerDayOld: null,
      bookEventData: null,
      // selectedTripFeesExperiment: this.props.tripFeesExperiment || getTripFeesExperimentCase(TripFeesExperiment.name),
    };
    this.stripe = null;
    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.pushEventEnterFormGTM = this.pushEventEnterFormGTM.bind(this);
    this.handleChangeAddOn = this.handleChangeAddOn.bind(this);
    this.handleSelectedMileageAddOn = this.handleSelectedMileageAddOn.bind(this);
  }

  async componentDidMount() {
    try {
      const response = await getEventsByBucket('book');
      // console.log('Events fetched successfully:', response.data);
      this.setState({ bookEventData : response.data });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
    if (window) {
      // console.log('componentDidMount Trip Fees Experiment: ', this.props.tripFeesExperiment, this.state.selectedTripFeesExperiment);
      this.loadInitialData();

    }


    // this.checkoutPageManagingStep();
  }

  checkoutPageManagingStep = stepNumber => {
    // const { location, history } = this.props;
    // const { searchParams, ...rest } = parse(location.search);
    // let checkoutStep = checkoutStepConfiguration.CHECKOUT_ADD_ONS;
    // if (searchParams && !searchParams.checkoutStep) {
    //   checkoutStep = checkoutStepConfiguration.CHECKOUT_ADD_ONS;
    // } else if (
    //   searchParams &&
    //   searchParams.checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS
    // ) {
    //   checkoutStep = checkoutStepConfiguration.CHECKOUT_ADD_ONS;
    // } else if (
    //   searchParams &&
    //   searchParams.checkoutStep === checkoutStepConfiguration.CHECKOUT_PAYMENT
    // ) {
    //   checkoutStep = checkoutStepConfiguration.CHECKOUT_PAYMENT;
    // }
    this.setState({
      checkoutStep: stepNumber,
    });
    this.loadInitialData();
  };

  pushEventEnterFormGTM(event, additionalProperties) {
    const { speculatedTransaction } = this.props;
    initiateEventFromTransaction({
      props: this.props,
      transaction: { ...speculatedTransaction, ...additionalProperties },
      event,
    });
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      fetchStripeCustomer,
      history,
      currentUser,
      timeSlotsObj,
    } = this.props;

    // Fetch currentUser with stripeCustomer entity
    // Note: since there's need for data loading in "componentWillMount" function,
    //       this is added here instead of loadData static function.
    fetchStripeCustomer();

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink = history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps = !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps && currentUser) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, currentUser, STORAGE_KEY, {
        timeSlotsObj,
      });
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction, timeSlotsObj }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      (currentUser || pageData.temporaryUserData) &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const user = currentUser ? currentUser : pageData.temporaryUserData;
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      const { listing } = pageData;
      const {
        isPaidAmount,
        paidAmount,
        diffHours,
        tripBookingStart,
        tripBookingEnd,
        updateBookingStart,
        updateBookingEnd,
      } = pageData.bookingData;
      const { location } = this.props;

      const commercialListing = listing.attributes.publicData.insurance === 'commercial';

      const millagePerDayOld =
        listing &&
        listing.attributes &&
        listing.attributes.publicData &&
        listing.attributes.publicData.millagePerDayOld;

      const millagePerDay =
        listing &&
        listing.attributes &&
        listing.attributes.publicData &&
        listing.attributes.publicData.millagePerDay;

      // console.log("millagePerDayOld", millagePerDayOld);
      // console.log("millagePerDay", millagePerDay);
      //Update the state
      this.setState({
        millagePerDayOld: millagePerDayOld,
        millagePerDay: millagePerDay
      })

      const isMinimumPrice = listing.attributes.price.amount <= 6000;
      const currentYear = new Date().getFullYear();
      const youngDriver =
        user &&
        user.attributes.profile.protectedData &&
        user.attributes.profile.protectedData.dateOfBirth &&
        currentYear - user.attributes.profile.protectedData.dateOfBirth.year <= 24;

      let bookingProcess = isPaidAmount
        ? config.updateBookingChargingProcessAlias
        : config.masterProcessAlias;
      let transition = null;
      if (!youngDriver) {
        if (commercialListing) {
          transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
        } else {
          transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
        }
      } else {
        if (commercialListing) {
          transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
        } else {
          transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
        }
      }

      if (isPaidAmount) {
        transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST;
      }
      const currentBookingDateStart = new Date(
        new Date(moment(tripBookingStart)).toISOString().slice(0, -1)
      );
      const currentBookingDateEnd = new Date(
        new Date(moment(tripBookingEnd)).toISOString().slice(0, -1)
      );
      const currentBookingStart = new Date(
        new Date(moment(updateBookingStart)).toISOString().slice(0, -1)
      );
      const currentBookingEnd = new Date(
        new Date(moment(updateBookingEnd)).toISOString().slice(0, -1)
      );
      const displayBookingDateStart = new Date(moment(updateBookingStart));
      const displayBookingDateEnd = new Date(moment(updateBookingEnd));

      const timeFromTripStart = moment().diff(moment(displayBookingDateStart), 'hours', true);
      const timeFromThreeDays = moment().add(3, 'days');

      const mileageExperiment =
      user &&
      user.attributes.profile.publicData &&
      user.attributes.profile.publicData.mileageExperiment || 'B';

      mapAbSegment = mileageExperiment;
      ;
      if(user &&
        user.attributes.profile.metadata &&
        user.attributes.profile.metadata.mileageExpOldUser) {
          mapAbSegment = 'B';
        }


      // console.log("mapAbSegment on checkout", mapAbSegment);

      const speculatedData = {
        listingId,
        bookingStart: location.state && location.state.updateBooking ? bookingStart : bookingStart,
        bookingEnd: location.state && location.state.updateBooking ? bookingEnd : bookingEnd,
        bookingDisplayStart:
          location.state && location.state.updateBooking ? bookingStart : bookingStart,
        bookingDisplayEnd: location.state && location.state.updateBooking ? bookingEnd : bookingEnd,
        customerPhoneNumber: user.attributes.profile.protectedData.phoneObj,
        customerLocation: user.attributes.profile.protectedData.location,
        isDrivelahGo: !!listing.attributes.metadata.isDrivelahGo,
        credits: pageData.bookingData.signupCredits,
        voucherCode: pageData.bookingData.voucherCode,
        payForFuel: pageData.bookingData.payForFuel,
        isPaidAmount,
        diffHours,
        currentTx: pageData.bookingData.currentTx,
        ...(pageData.bookingData.peakHoursDiff > 0 && {
          peakHoursDiff: pageData.bookingData.peakHoursDiff,
        }),
        ...(pageData.bookingData.regularHoursDiff > 0 && {
          regularHoursDiff: pageData.bookingData.regularHoursDiff,
        }),
        // paidAmount,
        isDelivery: this.state.isDeliveryState,
        isExcessReduction: this.state.isExcessReductionState,
        isExcessReduction2: this.state.isExcessReduction2State,
        isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
        // isMileagePackageIncluded: true,
        selectedMileage: this.state.selectedMileage,
        // tripFeesExperiment: this.state.selectedTripFeesExperiment,
        currentBookingStart:
          location.state && location.state.updateBooking
            ? moment(currentBookingStart).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingEnd:
          location.state && location.state.updateBooking
            ? moment(currentBookingEnd).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingStartTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingStart).getTime()
            : null,
        currentBookingEndTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingEnd).getTime()
            : null,
        currentBookingDateStart:
          location.state && location.state.updateBooking
            ? moment(currentBookingDateStart).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingDateEnd:
          location.state && location.state.updateBooking
            ? moment(currentBookingDateEnd).format('MMM DD, YYYY HH:mm')
            : null,
        currentBookingDateStartTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingDateStart).getTime()
            : null,
        currentBookingDateEndTimestamp:
          location.state && location.state.updateBooking
            ? new Date(currentBookingDateEnd).getTime()
            : null,
        stripeTKey: pageData.bookingData.stripeTKey,
        displayBookingDateStart:
          location.state && location.state.updateBooking
            ? moment(displayBookingDateStart).format('MMM DD, YYYY HH:mm')
            : null,
        displayBookingDateEnd:
          location.state && location.state.updateBooking
            ? moment(displayBookingDateEnd).format('MMM DD, YYYY HH:mm')
            : null,
      };

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData

      fetchSpeculatedTransaction(
        speculatedData,
        bookingProcess,
        isMinimumPrice,
        pageData.listing,
        transition
      );
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onConfirmPaymentDeposit,
      // onCheckBookingOverlap,
      fetchedDepositTransaction,
    } = this.props;
    const { shouldReduceBookingDeposit, isFuelInclusionState: isFuelInclusion } = this.state;

    const {
      pageData,
      speculatedTransaction,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const { bookingProcess, isMinimumPrice, listing, ...fnRestParams } = fnParams;
      const hasPaymentIntents =
        storedTx.attributes.protectedData && storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(
            fnRestParams,
            storedTx.id,
            bookingProcess,
            isMinimumPrice,
            listing,
            selectedPaymentFlow,
            handlePaymentParams,
            shouldReduceBookingDeposit
          );
    };

    const fnRequestUpdateBooking =  fnParams => {
      const { onRequestToUpdateBooking, location } = this.props;
      const updateBooking =
        location.state && location.state.updateBooking ? location.state.updateBooking : false;

      if (updateBooking) {
        const updateBookingParams = pageData.bookingData.updateBookingParams;
        const { transaction, transition, transactionId, listing } = updateBookingParams;

        const {
          updateBookingStart,
          updateBookingEnd,
          currentTx,
          regularHoursDiff,
          peakHoursDiff,
        } = pageData.bookingData;

        const params = {
          createUpdateBooking: true,
          transaction,
          transition,
          transactionId,
          listing,
          bookingStart: updateBookingStart,
          bookingEnd: updateBookingEnd,
          currentTx,
          regularHoursDiff,
          peakHoursDiff,
          protectedData: {
            childTransaction: [
              ...getUpdateObjs(transaction),
              {
                ...makeChildUpdateBookingDates(updateBookingStart, updateBookingEnd),
                txId: fnParams.id.uuid,
              },
            ],
          },
        };

        onRequestToUpdateBooking(params);

        // initiateEventFromTransaction({
        //   props: this.props,
        //   transaction: pageData.transaction,
        //   event: EVENT_TRIP_MODIFICATION_REQUEST_SENT_GUEST,
        // });
        this.callAnalyticsEvent({
          eventName: event_trigger_ids.BOOK_CREATED_MODIFICATION_GUEST,
          isBackendApiCall: true,
          data: {
            guest: this.props.currentUser,
            booking: pageData.transaction,
            trip:pageData.transaction,
            listing: this.props.listing,
            host: this.props.listing && this.props.listing.author ? this.props.listing.author.id.uuid: null,
            ui: {
              button: 'Book Now',
              page: 'CheckoutScreen'
            },
          }
        })

        try {
          const response =  getEventsByBucket('book');
          // console.log('Events fetched successfully:', response.data);
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.BOOK_CREATED_MODIFICATION_GUEST,
            eventData: response.data,
            props: {
              button:  'Book ',

            },
            userId: currentUser ? currentUser.id.uuid : null,
          });
        } catch (error) {
          console.log('Error fetching events:', error);
        }
      }

      return fnParams;
    };

    const fnHandleDepositCardPayment = fnParams => {
      const order = ensureTransaction(fnParams);
      const depositTx = order.deposit || fetchedDepositTransaction;
      if (!depositTx) {
        return Promise.resolve({
          mainTx: order,
          deposit: false,
        });
      }
      const hasPaymentIntents =
        depositTx.attributes.protectedData &&
        depositTx.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? depositTx.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentDepositIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};
      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const hasPaymentIntentUserActionsDone =
        paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status);
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: depositTx.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order: depositTx,
        userId: this.props.currentUser.id.uuid,
        mainTx: order,
        deposit: true,
      };
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({
            orderId: depositTx.id,
            paymentIntent: paymentDepositIntent,
            order: depositTx,
            mainTx: order,
            deposit: true,
            userId: this.props.currentUser.id.uuid,
          })
        : onHandleCardPayment(params);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, currentUser, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData && order.attributes.protectedData.stripePaymentIntents;
      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const { stripe, card, billingDetails, paymentIntent } = handlePaymentParams;
      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
              payment_method_data: {
                billing_details: billingDetails,
              },
            }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        paymentParams,
        order,
        userId: this.props.currentUser.id.uuid,
      };

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent, order })
        : onHandleCardPayment(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPaymentDeposit = ({ deposit, transactionId, order, mainTx }) => {
      if (deposit) {
        return onConfirmPaymentDeposit({
          order,
        }).then(() => {
          return mainTx;
        });
      } else {
        return Promise.resolve(mainTx);
      }
    };
    const fnConfirmPayment = fnParams => {
      createdPaymentIntent = fnParams.paymentIntent;
      const { listing, bookingData } = pageData;
      const { location } = this.props;
      const { isPaidAmount } = bookingData;
      const transactionId = location.state && location.state.transactionId;

      return onConfirmPayment({
        ...fnParams,
        savedListing: listing,
        isPaidAmount,
        userId: this.props.currentUser.id.uuid,
        parentTransaction: { transactionId },
      });
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnRequestUpdateBooking,
      fnHandleDepositCardPayment,
      fnConfirmPaymentDeposit,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod
    );

    const { listing, bookingData } = this.state.pageData;
    const { isPaidAmount } = bookingData;

    const isMinimumPrice = listing.attributes.price.amount <= 6000;
    const commercialListing = listing.attributes.publicData.insurance === 'commercial';
    const currentYear = new Date().getFullYear();
    const youngDriver =
      currentUser &&
      currentUser.attributes.profile.protectedData &&
      currentUser.attributes.profile.protectedData.dateOfBirth &&
      currentYear - currentUser.attributes.profile.protectedData.dateOfBirth.year <= 24;

    // let bookingProcess = config.masterProcessAlias;
    let bookingProcess = isPaidAmount
      ? config.updateBookingChargingProcessAlias
      : config.masterProcessAlias;
    let transition = null;
    if (!youngDriver) {
      if (commercialListing) {
        transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
      } else {
        transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
      }
    } else {
      if (commercialListing) {
        transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
      } else {
        transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
      }
    }

    if (isPaidAmount) {
      transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST;
    }

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const { hasMastercardPromoBeenUsed } = currentUser.attributes.profile.metadata;
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
        ? { setupPaymentMethodForSaving: true }
        : {};

    const shouldUseMastercardPromoLineItem =
      this.state.isUsingMastercard && checkCanUseMastercardPromo(currentUser);



    const orderParams = {
      transition,
      listingId: pageData.listing.id,
      bookingStart: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayStart)
        : tx.booking.attributes.start,
      bookingEnd: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayEnd)
        : tx.booking.attributes.end,
      bookingDisplayStart: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayStart)
        : tx.booking.attributes.displayStart,
      bookingDisplayEnd: isPaidAmount
        ? new Date(tx.attributes.protectedData.bookingDisplayEnd)
        : tx.booking.attributes.displayEnd,
      protectedData: tx.attributes.protectedData,
      preauthenListingId: config.preauthenListingId,
      preauthenListingIdWithDepositDiscount: config.preauthenListingIdWithDepositDiscount,
      quantity: isPaidAmount
        ? calculateBookingDays(
            tx.attributes.protectedData.bookingDisplayStart,
            tx.attributes.protectedData.bookingDisplayEnd
          )
        : calculateBookingDays(
            tx.booking.attributes.displayStart,
            tx.booking.attributes.displayEnd
          ),
      credits: pageData.bookingData.signupCredits,
      voucherCode: pageData.bookingData.voucherCode,
      isPaidAmount: pageData.bookingData.isPaidAmount,
      ...(pageData.bookingData.regularHoursDiff &&
        pageData.bookingData.regularHoursDiff > 0 && {
          regularHoursDiff: pageData.bookingData.regularHoursDiff,
        }),
      ...(pageData.bookingData.peakHoursDiff &&
        pageData.bookingData.peakHoursDiff > 0 && {
          peakHoursDiff: pageData.bookingData.peakHoursDiff,
        }),
      currentTx: pageData.bookingData.currentTx,
      diffHours: pageData.bookingData.diffHours,
      // paidAmount: pageData.bookingData.paidAmount,
      isMinimumPrice,
      bookingProcess,
      listing: pageData.listing,
      userId: this.props.currentUser.id.uuid,
      shouldUseMastercardPromoLineItem,
      isDelivery: this.state.isDeliveryState,
      isExcessReduction: this.state.isExcessReductionState,
      ...(isFuelInclusion ? { isFuelInclusion } : {}),
      isExcessReduction2: this.state.isExcessReduction2State,
      // tripFeesExperiment: this.state.selectedTripFeesExperiment,
      isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
      selectedMileage: this.state.selectedMileage,
      ...optionalPaymentParams,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true, isBlocking: false });

    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
      listing,
      intl,
      paymentDepositIntent,
    } = this.props;
    const { card, message, paymentMethod, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
            address: {
              city: city,
              country: country,
              line1: addressLine1,
              line2: addressLine2,
              postal_code: postal,
              state: state,
            },
          }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      // ...addressMaybe,
    };

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
      paymentDepositIntent,
    };

    const { listing: savedListing, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const ensuredSpeculatedTransaction = ensureTransaction(speculatedTransaction);
    const tx = existingTransaction.booking ? existingTransaction : ensuredSpeculatedTransaction;
    // console.log('data for segment test', existingTransaction, ensuredSpeculatedTransaction);
    const isExcessReduction =
      tx && tx.attributes.protectedData && tx.attributes.protectedData.isExcessReduction;
    const isExcessReduction2 =
      tx && tx.attributes.protectedData && tx.attributes.protectedData.isExcessReduction2;

    const listingGTM = ensureListing(listing || savedListing || (tx && tx.listing));
    const rawProperties = createRawPropertiesForGTM({
      props: this.props,
      button: {
        buttonId: SEND_REQUEST_BOOKING_BUTTON_ID,
        text: intl.formatMessage({ id: 'StripePaymentForm.submitPaymentInfo' }),
      },
      listing: listingGTM,
      transaction: tx,
    });
    let particularVASParamsFrom = particularVASParams;
    if (isExcessReduction) {
      particularVASParamsFrom = { ...particularVASParamsFrom, booking_vas_excess_from: 'checkout' };
      // this.pushEventEnterFormGTM(EVENT_BOOK_EXCESS_SUCCESS_GUEST, particularVASParamsFrom);
      this.callAnalyticsEvent({
        eventName: event_trigger_ids.BOOK_EXCESS_SUCCESS_GUEST,
        isBackendApiCall: true,
        data: {
          guest: this.props.currentUser,
          vas: {
            listing: this.props.listing,
            isDelivery: this.state.isDeliveryState,
            isExcessReduction: this.state.isExcessReductionState,
            isExcessReduction2: this.state.isExcessReduction2State,
            // tripFeesExperiment: this.state.selectedTripFeesExperiment,
            isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
            selectedMileage: this.state.selectedMileage,
            isFuelInclusion: this.state.isFuelInclusionState,
          },
          listing: this.props.listing,
          booking: this.props.speculatedTransaction,
          host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
          ui: {
            button: '',
            page: 'CheckoutScreen'
          },
        }
      })
    } else if (isExcessReduction2) {
      particularVASParamsFrom = {
        ...particularVASParamsFrom,
        booking_vas_excess_2_from: 'checkout',
      };
      // this.pushEventEnterFormGTM(EVENT_BOOK_EXCESS_2_SUCCESS_GUEST, particularVASParamsFrom);
      this.callAnalyticsEvent({
        eventName: event_trigger_ids.BOOK_EXCESS_2_SUCCESS_GUEST,
        isBackendApiCall: true,
        data: {
          guest: this.props.currentUser,
          vas: {
            listing: this.props.listing,
            isDelivery: this.state.isDeliveryState,
            isExcessReduction: this.state.isExcessReductionState,
            isExcessReduction2: this.state.isExcessReduction2State,
            // tripFeesExperiment: this.state.selectedTripFeesExperiment,
            isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
            selectedMileage: this.state.selectedMileage,
            isFuelInclusion: this.state.isFuelInclusionState,
          },
          listing: this.props.listing,
          booking: this.props.speculatedTransaction,
          host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
          ui: {
            button: '',
            page: 'CheckoutScreen'
          },
        }
      })

    } else {
      // Do nothing
    }
    if (this.state.isFuelInclusionState) {
      particularVASParamsFrom = { ...particularVASParamsFrom, booking_vas_fuel_from: 'checkout' };
      // this.pushEventEnterFormGTM(EVENT_BOOK_FUEL_SUCCESS_GUEST, particularVASParamsFrom);
      this.callAnalyticsEvent({
        eventName: event_trigger_ids.BOOK_FUEL_SUCCESS_GUEST,
        isBackendApiCall: true,
        data: {
          guest: this.props.currentUser,
          vas: {
            listing: this.props.listing,
            isDelivery: this.state.isDeliveryState,
            isExcessReduction: this.state.isExcessReductionState,
            isExcessReduction2: this.state.isExcessReduction2State,
            // tripFeesExperiment: this.state.selectedTripFeesExperiment,
            isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
            selectedMileage: this.state.selectedMileage,
            isFuelInclusion: this.state.isFuelInclusionState,
          },
          listing: this.props.listing,
          booking: this.props.speculatedTransaction,
          host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
          ui: {
            button: '',
            page: 'CheckoutScreen'
          },
        }
      })
    }
    if(this.state.isDeliveryState) {
      this.callAnalyticsEvent({
        eventName: event_trigger_ids.BOOK_DELIVERY_SUCCESS_GUEST,
        isBackendApiCall: true,
        data: {
          guest: this.props.currentUser,
          vas: {
            listing: this.props.listing,
            isDelivery: this.state.isDeliveryState,
            isExcessReduction: this.state.isExcessReductionState,
            isExcessReduction2: this.state.isExcessReduction2State,
            // tripFeesExperiment: this.state.selectedTripFeesExperiment,
            isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
            selectedMileage: this.state.selectedMileage,
            isFuelInclusion: this.state.isFuelInclusionState,
          },
          listing: this.props.listing,
          booking: this.props.speculatedTransaction,
          host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
          ui: {
            button: '',
            page: 'CheckoutScreen'
          },
        }
      })
    }
    this.callAnalyticsEvent({
      eventName: event_trigger_ids.BOOK_CREATED_REQUEST_GUEST,
      isBackendApiCall: true,
      data: {
        guest: this.props.currentUser,
        vas: {
          listing: this.props.listing,
          isDelivery: this.state.isDeliveryState,
          isExcessReduction: this.state.isExcessReductionState,
          isExcessReduction2: this.state.isExcessReduction2State,
          // tripFeesExperiment: this.state.selectedTripFeesExperiment,
          isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
          selectedMileage: this.state.selectedMileage,
          isFuelInclusion: this.state.isFuelInclusionState,
        },
        listing: this.props.listing,
        booking: this.props.speculatedTransaction,
        host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
        ui: {
          button: '',
          page: 'CheckoutScreen'
        },
      }
    })

    if (paymentMethod === 'defaultCard') {
      // pushGTMBookEvent(rawProperties, EVENT_BOOK_USED_PRESAVED_DETAILS);
      this.callAnalyticsEvent({
        eventName: event_trigger_ids.BOOK_USED_PRESAVEDDETAILS,
        data: {
          guest: this.props.currentUser,
          vas: {
            listing: this.props.listing,
            isDelivery: this.state.isDeliveryState,
            isExcessReduction: this.state.isExcessReductionState,
            isExcessReduction2: this.state.isExcessReduction2State,
            // tripFeesExperiment: this.state.selectedTripFeesExperiment,
            isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
            selectedMileage: this.state.selectedMileage,
            isFuelInclusion: this.state.isFuelInclusionState,
          },
          listing: this.props.listing,
          booking: this.props.speculatedTransaction,
          host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
          ui: {
            button: '',
            page: 'CheckoutScreen'
          },
        }
      })
    }

    this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { messageSuccess, paymentMethodSaved } = res;
        this.setState({ submitting: false });
        let orderId = requestPaymentParams.pageData.bookingData.isPaidAmount
          ? requestPaymentParams.pageData.bookingData.transactionId
          : res.orderId;

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, { id: orderId.uuid });
        const initialValues = {
          initialMessageFailedToTransaction,
          savePaymentMethodFailed: !paymentMethodSaved,
        };

        initializeOrderPage(initialValues, routes, dispatch);

        const particularParams = { existTransaction: true };
        rawProperties.ui.button = null;
        rawProperties.transaction.id = orderId;
        if (!!saveAfterOnetimePayment) {
          // pushGTMBookEvent(rawProperties, EVENT_BOOK_SAVED_CARD_DETAILS, particularParams);
          this.callAnalyticsEvent({
            eventName: event_trigger_ids.BOOK_SAVED_CARD_DETAILS,
            data: {
              guest: this.props.currentUser,
              vas: {
                listing: this.props.listing,
                isDelivery: this.state.isDeliveryState,
                isExcessReduction: this.state.isExcessReductionState,
                isExcessReduction2: this.state.isExcessReduction2State,
                // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                selectedMileage: this.state.selectedMileage,
                isFuelInclusion: this.state.isFuelInclusionState,
              },
              listing: this.props.listing,
              booking: this.props.speculatedTransaction,
              host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
              ui: {
                button: '',
                page: 'CheckoutScreen'
              },
            }
          })
        }
        // Enhanced Data Conversion
        if (tx && orderId && currentUser) {
          const payInTotal = tx.attributes.payinTotal;
          const enhancedConversionData = {
            event: 'order_complete',
            order_id: orderId, // fetch the order id variable
            order_value: payInTotal.amount, // fetch the order value variable
            order_currency: 'USD',
            enhanced_conversion_data: {
              email: currentUser.attributes.email, // change sadadastfgnf@gmail.com with user dynamic email var
            },
          };
          // pushDataLayer(enhancedConversionData);
        }

        // pushGTMBookEvent(rawProperties, EVENT_BOOK_SENT_REQUEST_SUCCESS_GUEST, particularParams);
        this.callAnalyticsEvent({
          eventName: event_trigger_ids.BOOK_SENT_REQUEST_SUCCESS_GUEST,
          isBackendApiCall: true,
          data: {
            guest: this.props.currentUser,
            vas: {
              listing: this.props.listing,
              isDelivery: this.state.isDeliveryState,
              isExcessReduction: this.state.isExcessReductionState,
              isExcessReduction2: this.state.isExcessReduction2State,
              // tripFeesExperiment: this.state.selectedTripFeesExperiment,
              isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
              selectedMileage: this.state.selectedMileage,
              isFuelInclusion: this.state.isFuelInclusionState,
            },
            listing: this.props.listing,
            booking: this.props.speculatedTransaction,
            host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
            ui: {
              button: '',
              page: 'CheckoutScreen'
            },
          }
        })
        sendG4AEvent(rawProperties.transaction, GTAG_ACTIONS.ACTION_PURCHASE, {});

        this.callAnalyticsEvent({
          eventName:  event_trigger_ids.BOOK_RECEIVED_REQUEST_SUCCESS_HOST,
          isBackendApiCall: true,
          data: {
            guest: this.props.currentUser,
            vas: {
              listing: this.props.listing,
              isDelivery: this.state.isDeliveryState,
              isExcessReduction: this.state.isExcessReductionState,
              isExcessReduction2: this.state.isExcessReduction2State,
              // tripFeesExperiment: this.state.selectedTripFeesExperiment,
              isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
              selectedMileage: this.state.selectedMileage,
              isFuelInclusion: this.state.isFuelInclusionState,
            },
            listing: this.props.listing,
            booking: this.props.speculatedTransaction,
            host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
            ui: {
              button: '',
              page: 'CheckoutScreen'
            },
            userId: this.props.listing && this.props.listing.author ? this.props.listing.author.id.uuid: null
          }
        })
        // pushGTMBookEvent(rawProperties, SERVER_EVENT_BOOK_RECEIVED_REQUEST_SUCCESS_HOST, {
        //   ...particularParams,
        //   userDiffActionTaker: true,
        //   isServerSideEvent: true,
        // });

        const instantBooking = get(listingGTM, 'attributes.publicData.instantBooking');
        if (instantBooking) {
          // pushGTMBookEvent(rawProperties, EVENT_BOOK_REQUEST_ACCEPTED_HOST, {
          //   ...particularParams,
          //   userDiffActionTaker: true,
          // });
          this.callAnalyticsEvent({
            eventName: event_trigger_ids.BOOK_REQUEST_ACCEPTED_HOST,
            isBackendApiCall: true,
            data: {
              guest: this.props.currentUser,
              vas: {
                listing: this.props.listing,
                isDelivery: this.state.isDeliveryState,
                isExcessReduction: this.state.isExcessReductionState,
                isExcessReduction2: this.state.isExcessReduction2State,
                // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                selectedMileage: this.state.selectedMileage,
                isFuelInclusion: this.state.isFuelInclusionState,
              },
              listing: this.props.listing,
              booking: this.props.speculatedTransaction,
              host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
              ui: {
                button: '',
                page: 'CheckoutScreen'
              },
            }
          })
          this.callAnalyticsEvent({
            eventName: event_trigger_ids.BOOK_REQUEST_ACCEPTED_GUEST,
            isBackendApiCall: true,
            data: {
              guest: this.props.currentUser,
              vas: {
                listing: this.props.listing,
                isDelivery: this.state.isDeliveryState,
                isExcessReduction: this.state.isExcessReductionState,
                isExcessReduction2: this.state.isExcessReduction2State,
                // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                selectedMileage: this.state.selectedMileage,
                isFuelInclusion: this.state.isFuelInclusionState,
              },
              listing: this.props.listing,
              booking: this.props.speculatedTransaction,
              host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
              ui: {
                button: '',
                page: 'CheckoutScreen'
              },
            }
          })
          // pushGTMBookEvent(rawProperties, EVENT_BOOK_REQUEST_ACCEPTED_GUEST, particularParams);
          // this.callAnalyticsEvent({
          //   eventName: EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
          //   isBackendApiCall: true,
          //   data: {
          //     ...rawProperties,
          //   }
          // })
        }
        clearData(STORAGE_KEY);
        if (
          currentUser &&
          currentUser.attributes &&
          currentUser.attributes.profile &&
          currentUser.attributes.profile.metadata &&
          Object.keys(currentUser.attributes.profile.metadata).length &&
          currentUser.attributes.profile.metadata.smoveType3 &&
          currentUser.attributes.profile.metadata.smoveType3 === 'HF'
        ) {
          sendSmoveUserBookingNotification({
            transactionId: orderId.uuid,
            hostId: currentUser.id.uuid,
          });
        }
        // pushGTMBookEventAfterOrderCompleted(rawProperties, EVENT_ORDER_COMPLETED, particularParams);
        history.push(orderDetailsPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
        rawProperties.ui.button = null;
        rawProperties.fail_reason =
          err.code && err.decline_code
            ? `${err.code} ${err.decline_code}`
            : 'Stripe card error code is not given';
        // pushGTMBookEvent(rawProperties, EVENT_BOOK_CREATED_REQUEST_FAIL_GUEST);
        this.callAnalyticsEvent({
          eventName: event_trigger_ids.BOOK_CREATED_REQUEST_FAIL_GUEST,
          isBackendApiCall: true,
          data: {
            guest: this.props.currentUser,
            vas: {
              listing: this.props.listing,
              isDelivery: this.state.isDeliveryState,
              isExcessReduction: this.state.isExcessReductionState,
              isExcessReduction2: this.state.isExcessReduction2State,
              // tripFeesExperiment: this.state.selectedTripFeesExperiment,
              isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
              selectedMileage: this.state.selectedMileage,
              isFuelInclusion: this.state.isFuelInclusionState,
            },
            listing: this.props.listing,
            booking: this.props.speculatedTransaction,
            host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
            ui: {
              button: '',
              page: 'CheckoutScreen'
            },
          }
        })
        // pushGTMBookEvent(rawProperties, SERVER_EVENT_BOOK_CREATED_REQUEST_FAIL_HOST, {
        //   userDiffActionTaker: true,
        //   isServerSideEvent: true,
        // });
        // this.callAnalyticsEvent({
        //   eventName: 'book_created_request_fail_host',
        //   isBackendApiCall: true,
        //   data: {
        //     ...rawProperties,
        //   }
        // })
        //Reading the error code and decline code to show the error message, if not given then showing the default error message.
        let errorMessage =
          err.code && err.decline_code
            ? `${err.code} ${err.decline_code}`
            : err.message || 'Stripe card error code is not given';
        sendStripeErrorSlackNotification({
          transactionId: currentUser.id.uuid,
          error: errorMessage,
        });
      });
  }

  onRetrievePaymentDepositIntent = ({ depositId, stripe }) => {
    const { onFetchDepositTx, onRetrievePaymentIntent } = this.props;
    onFetchDepositTx({ id: depositId }).then(deposit => {
      const { stripePaymentIntentClientSecret } =
        deposit.attributes.protectedData && deposit.attributes.protectedData.stripePaymentIntents
          ? deposit.attributes.protectedData.stripePaymentIntents.default
          : {};
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret, deposit: true });
    });
  };

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe && !paymentIntent && tx && tx.id && !checkIsPaymentExpired(tx);
    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};
      const { depositTx } = tx.attributes.protectedData || {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
      if (depositTx) {
        this.onRetrievePaymentDepositIntent({ depositId: depositTx, stripe });
      }
    }
  }

  handleNavigation = nextLocation => {
    // this.setState({ isOpenConfirmLeaveModal: true, nextLocation });
    // if (!this.state.isBlocking) return true;
    // return false;
    const { history } = this.props;
    this.setState(
      {
        isBlocking: false,
        isOpenConfirmLeaveModal: false,
      },
      () => {
        history && history.push(nextLocation);
      }
    );
  };

  onConfirmLeave = () => {
    const { nextLocation } = this.state;
    const { history } = this.props;
    this.setState(
      {
        isBlocking: false,
        isOpenConfirmLeaveModal: false,
      },
      () => {
        history && history.push(nextLocation);
      }
    );
  };

  callAnalyticsEvent = async ({ eventName, isBackendApiCall= false, data = {} }) => {
    // console.log('eventName for addons is called', eventName, data);
    // console.log('Data during addon is called', this.props, this.state);
    try {
      const response = await getEventsByBucket('book');
      // console.log('Events fetched successfully:', response.data);
      triggerAnalyticsEvent({
        event_id: eventName,
        eventData: response.data,
        isBackendApiCall: isBackendApiCall,
        props: {
          ...data
        },
        userId: data.userId ? data.userId : this.props.currentUser ? this.props.currentUser.id.uuid : null
      });
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  }

  handleChangeAddOn = (e, addon, fuelCategory) => {
    if (e.currentTarget.id === 'addOn1')
      this.setState({ isDeliveryState: !this.state.isDeliveryState }, () => {
        // this.loadInitialData();
        if (this.state.isDeliveryState) {
          // this.pushEventEnterFormGTM(EVENT_DELIVERY_ADDON_ADDED);
          // history,
          //   speculatedTransaction,
          //   currentUser,
          //   paymentIntent,
          //   dispatch,
          //   listing,
          //   intl,
          //   paymentDepositIntent,
          // } = this.props;
          this.callAnalyticsEvent({
            eventName: event_trigger_ids.BOOK_DELIVERY_ADDON_ADDED,
            data: {
              guest: this.props.currentUser,
              vas: {
                listing: this.props.listing,
                isDelivery: this.state.isDeliveryState,
                isExcessReduction: this.state.isExcessReductionState,
                isExcessReduction2: this.state.isExcessReduction2State,
                // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                selectedMileage: this.state.selectedMileage,
                isFuelInclusion: this.state.isFuelInclusionState,
              },
              listing: this.props.listing || (this.props.speculatedTransaction && this.props.speculatedTransaction.listing),
              booking: this.props.speculatedTransaction,
              host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
              ui: {
                button: 'Add delivery',
                page: 'CheckoutScreen'
              },
            }
          })
        } else {
          // this.pushEventEnterFormGTM(EVENT_DELIVERY_ADDON_REMOVED);
          this.callAnalyticsEvent({
            eventName:  event_trigger_ids.BOOK_DELIVERY_ADDON_REMOVED,
            data: {
              guest: this.props.currentUser,
              vas: {
                listing: this.props.listing,
                isDelivery: this.state.isDeliveryState,
                isExcessReduction: this.state.isExcessReductionState,
                isExcessReduction2: this.state.isExcessReduction2State,
                // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                selectedMileage: this.state.selectedMileage,
                isFuelInclusion: this.state.isFuelInclusionState,
              },
              listing: this.props.listing,
              booking: this.props.speculatedTransaction,
              host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
              ui: {
                button: 'Remove delivery',
                page: 'CheckoutScreen'
              },
            }
          })

        }
      });
    const isExcessReductionChecked = e.currentTarget.id === 'addOn2';
    const isExcessReduction2Checked = e.currentTarget.id === 'addOn3';
    const isMileagePackageIncludedChecked = e.currentTarget.id === 'addOn4';
    if (isExcessReductionChecked || isExcessReduction2Checked || isMileagePackageIncludedChecked) {
      const updatedState = {
        ...(isExcessReductionChecked && {
          isExcessReduction2State: false,
          isExcessReductionState: !this.state.isExcessReductionState,
        }),
        ...(isExcessReduction2Checked && {
          isExcessReductionState: false,
          isExcessReduction2State: !this.state.isExcessReduction2State,
        }),
        ...(isMileagePackageIncludedChecked && {
          isMileagePackageIncludedState: !this.state.isMileagePackageIncludedState,
        }),
      };
      // console.log("Update state data", updatedState);
      const oldPackageState = {
        isExcessReductionState : this.state.isExcessReductionState,
        isExcessReduction2State:  this.state.isExcessReduction2State,
        isFuelInclusionState: this.state.isFuelInclusionState,
        isMileagePackageIncludedState:  this.state.isMileagePackageIncludedState
      }
      this.setState(updatedState, () => {
        // this.loadInitialData();

        if (
          this.state.isExcessReductionState ||
          this.state.isExcessReduction2State ||
          this.state.isFuelInclusionState ||
          this.state.isMileagePackageIncludedState
        ) {
          if (this.state.isExcessReductionState) {
            particularVASParams = {
              ...particularVASParams,
              booking_vas_excess: true,
              booking_vas_excess_2: false,
            };
            // console.log("Excess state", this.state, updatedState, this.state.isExcessReductionState);
            // this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED, particularVASParams);
            this.callAnalyticsEvent({
              eventName: event_trigger_ids.BOOK_EXCESS_ADDON_ADDED,
              data: {
                guest: this.props.currentUser,
                vas: {
                  listing: this.props.listing,
                  isDelivery: this.state.isDeliveryState,
                  isExcessReduction: this.state.isExcessReductionState,
                  isExcessReduction2: this.state.isExcessReduction2State,
                  // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                  isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                  selectedMileage: this.state.selectedMileage,
                  isFuelInclusion: this.state.isFuelInclusionState,
                },
                listing: this.props.listing,
                booking: this.props.speculatedTransaction,
                host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
                ui: {
                  button: 'Add Premium Protection',
                  page: 'CheckoutScreen'
                },
              }
            })
            if(oldPackageState.isExcessReduction2State) {
              this.callAnalyticsEvent({
                eventName: event_trigger_ids.BOOK_EXCESS_2_ADDON_REMOVED,
                data: {
                  guest: this.props.currentUser,
                  vas: {
                    listing: this.props.listing,
                    isDelivery: this.state.isDeliveryState,
                    isExcessReduction: this.state.isExcessReductionState,
                    isExcessReduction2: this.state.isExcessReduction2State,
                    // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                    isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                    selectedMileage: this.state.selectedMileage,
                    isFuelInclusion: this.state.isFuelInclusionState,
                  },
                  listing: this.props.listing,
                  booking: this.props.speculatedTransaction,
                  host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
                  ui: {
                    button: 'Remove Maximum Protection',
                    page: 'CheckoutScreen'
                  },
                }
              })
            }

            // this.callAnalyticsEvent({
            //   eventName: event_trigger_ids.BOOK_EXCESS_2_ADDON_REMOVED,
            //   data: particularVASParams
            // })
          } else if (this.state.isExcessReduction2State) {
            particularVASParams = {
              ...particularVASParams,
              booking_vas_excess_2: true,
              booking_vas_excess: false,
            };
            // this.pushEventEnterFormGTM(EVENT_EXCESS_2_ADDON_ADDED, particularVASParams);
            // this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_REMOVED, particularVASParams);
            // console.log("Excess 2 state", this.state)
            this.callAnalyticsEvent({
              eventName: event_trigger_ids.BOOK_EXCESS_2_ADDON_ADDED,
              data: {
                guest: this.props.currentUser,
                vas: {
                  listing: this.props.listing,
                  isDelivery: this.state.isDeliveryState,
                  isExcessReduction: this.state.isExcessReductionState,
                  isExcessReduction2: this.state.isExcessReduction2State,
                  // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                  isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                  selectedMileage: this.state.selectedMileage,
                  isFuelInclusion: this.state.isFuelInclusionState,
                },
                listing: this.props.listing,
                booking: this.props.speculatedTransaction,
                host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
                ui: {
                  button: 'Add Maximum Protection',
                  page: 'CheckoutScreen'
                },
              }
            })
            if(oldPackageState.isExcessReductionState) {
              this.callAnalyticsEvent({
                eventName: event_trigger_ids.BOOK_EXCESS_ADDON_REMOVED,
                data: {
                  guest: this.props.currentUser,
                  vas: {
                    listing: this.props.listing,
                    isDelivery: this.state.isDeliveryState,
                    isExcessReduction: this.state.isExcessReductionState,
                    isExcessReduction2: this.state.isExcessReduction2State,
                    // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                    isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                    selectedMileage: this.state.selectedMileage,
                    isFuelInclusion: this.state.isFuelInclusionState,
                  },
                  listing: this.props.listing,
                  booking: this.props.speculatedTransaction,
                  host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
                  ui: {
                    button: 'Remove Premium Protection',
                    page: 'CheckoutScreen'
                  },
                }
              })
            }

            // this.callAnalyticsEvent({
            //   eventName: event_trigger_ids.BOOK_EXCESS_ADDON_REMOVED,
            // })
          } else {
            // this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED, { ...particularVASParams, booking_vas_fuel: false });
          }
        }
      });
    }
    // if (e.currentTarget.id === 'addOn3') {
    // this.setState({
    //       isExcessReduction2State: !this.state.isExcessReduction2State
    //     },
    //     () => {
    //       this.loadInitialData();
    //       if(this.state.isExcessReduction2State) {
    //         this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED);
    //       } else {
    //         this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_REMOVED);
    //       }
    //     }
    //   );
    // }
    if (addon.addonKey === 'fuelInclusion') {
      this.setState(
        {
          isFuelInclusionState: fuelCategory === 3 ? true : !this.state.isFuelInclusionState,
          isFuelSelectedManually: true,
        },
        () => {
          if (this.state.isFuelInclusionState) {
            // console.log("Fuel state", this.state)
            particularVASParams = { ...particularVASParams, booking_vas_fuel: true };
            // this.pushEventEnterFormGTM(EVENT_FUEL_ADDON_ADDED, particularVASParams);
            this.callAnalyticsEvent({
              eventName: event_trigger_ids.BOOK_FUEL_ADDON_ADDED,
              data: {
                guest: this.props.currentUser,
                vas: {
                  listing: this.props.listing,
                  isDelivery: this.state.isDeliveryState,
                  isExcessReduction: this.state.isExcessReductionState,
                  isExcessReduction2: this.state.isExcessReduction2State,
                  // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                  isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                  selectedMileage: this.state.selectedMileage,
                  isFuelInclusion: this.state.isFuelInclusionState,
                },
                listing: this.props.listing,
                booking: this.props.speculatedTransaction,
                host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
                ui: {
                  button: 'Add Fuel',
                  page: 'CheckoutScreen'
                },
              }
            })
            // this.callAnalyticsEvent({
            //   eventName: event_trigger_ids.BOOK_FUEL_ADDON_ADDED,
            //   data: particularVASParams
            // })
          } else {
            // console.log("Fuel removed state", this.state)
            particularVASParams = { ...particularVASParams, booking_vas_fuel: false };
            // this.pushEventEnterFormGTM(EVENT_FUEL_ADDON_REMOVED, particularVASParams);
            this.callAnalyticsEvent({
              eventName: event_trigger_ids.BOOK_FUEL_ADDON_REMOVED,
              data: {
                guest: this.props.currentUser,
                vas: {
                  listing: this.props.listing,
                  isDelivery: this.state.isDeliveryState,
                  isExcessReduction: this.state.isExcessReductionState,
                  isExcessReduction2: this.state.isExcessReduction2State,
                  // tripFeesExperiment: this.state.selectedTripFeesExperiment,
                  isMileagePackageIncluded: this.state.isMileagePackageIncludedState,
                  selectedMileage: this.state.selectedMileage,
                  isFuelInclusion: this.state.isFuelInclusionState,
                },
                listing: this.props.listing,
                booking: this.props.speculatedTransaction,
                host: this.props.speculatedTransaction ? this.props.speculatedTransaction.provider : null,
                ui: {
                  button: 'Remove Fuel',
                  page: 'CheckoutScreen'
                },
              }
            })
            // this.callAnalyticsEvent({
            //   eventName: event_trigger_ids.BOOK_FUEL_ADDON_REMOVED,
            //   data: particularVASParams
            // });
          }
        }
      );
    }
  };

  handleSelectedMileageAddOn = (mileage, flag) => {
    this.setState({ isMileagePackageIncludedState: flag, selectedMileage: mileage }, () => {
      // this.loadInitialData();

      if (this.state.isMileagePackageIncludedState) {
        // if (this.state.isExcessReductionState) {
        //   particularVASParams = {
        //     ...particularVASParams,
        //     booking_vas_excess: true,
        //     booking_vas_excess_2: false,
        //   };
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED, particularVASParams);
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_2_ADDON_REMOVED, particularVASParams);
        // } else if (this.state.isExcessReduction2State) {
        //   particularVASParams = {
        //     ...particularVASParams,
        //     booking_vas_excess_2: true,
        //     booking_vas_excess: false,
        //   };
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_2_ADDON_ADDED, particularVASParams);
        //   this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_REMOVED, particularVASParams);
        // } else {
        //   // this.pushEventEnterFormGTM(EVENT_EXCESS_ADDON_ADDED, { ...particularVASParams, booking_vas_fuel: false });
        // }
      }
    });
  };

  getTotalPrice = totalAmount => {
    this.setState({
      totalAmount,
    });
  };

  fuelInclusionUnavailable() {
    const { pageData } = this.state;
    const { bookingDates } = pageData || {};
    const { bookingStart, bookingEnd } = bookingDates || {};
    return !bookingStart || !bookingEnd || !isFuelAddonVisible(bookingStart, bookingEnd);
  }


  render() {
    const {
      fuelPriceByListing,
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      onManageDisableScrolling,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
      stripeCustomerFetched,
      location,
      bookingOverlapError,
      retrievePaymentDepositIntentError,
      paymentDepositIntent,
    } = this.props;

    const {
      isBlocking = true,
      isOpenConfirmLeaveModal,
      checkoutStep,
      isFuelInclusionState: isFuelInclusion,
    } = this.state;
    const isLongTermRental = get(
      speculatedTransactionMaybe,
      'attributes.protectedData.isLongTermRental'
    );
    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const voucherIsInvalid = isTransactionInitiateInvalidVoucher(initiateOrderError);
    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, transaction } = this.state.pageData;
    const isInstantBooking =
      listing && listing.attributes && listing.attributes.publicData.instantBooking;

    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentTransaction = ensureTransaction(speculatedTransactionMaybe, {}, null);
    const currentListing = ensureListing(listing);
    const currentBooking = ensureBooking(currentTransaction.booking);
    const currentAuthor = ensureUser(currentListing.author);
    const timezone = currentTransaction
      ? (currentTransaction.attributes &&
          currentTransaction.attributes.protectedData &&
          currentTransaction.attributes.protectedData.transactionTimezone) ||
        'Asia/singapore'
      : (currentListing.attributes &&
          currentListing.attributes.publicData &&
          currentListing.attributes.publicData.listingTimezone) ||
        'Asia/singapore';

    let insuranceType = currentListing.attributes.publicData.insurance;
    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' }, { listingTitle });
    const isPayForFuel = get(speculatedTransaction, 'attributes.protectedData.payForFuel');
    const isDelivery = currentListing.attributes.publicData.delivery;

    const currentUserLocation = get(
      currentUser,
      'attributes.profile.protectedData.location.selectedPlace.origin'
    );
    const listingLocation = get(listing, 'attributes.geolocation');
    const distance =
      currentUserLocation &&
      listingLocation &&
      distanceCountHelper(currentUserLocation, listingLocation);
    const startTransaction =
      currentTransaction && currentTransaction.attributes.protectedData.bookingDisplayStart;
    const endTransaction =
      currentTransaction && currentTransaction.attributes.protectedData.bookingDisplayEnd;
    const startHour = startTransaction && moment(startTransaction).format('HH');
    const endHour = endTransaction && moment(endTransaction).format('HH');
    const endMinutes = endTransaction && moment(endTransaction).format('mm');
    const fuelPrice = fuelPriceByListing(currentListing);

    const fuelCategory = fuelPrice && fuelPrice.category;

    let isLessEndTime = false;

    if (endHour < LIMIT_END_HOUR) {
      isLessEndTime = true;
    }
    if (endHour == LIMIT_END_HOUR) {
      isLessEndTime = endMinutes && parseInt(endMinutes) == 0;
    }

    const showDelivery =
      isDelivery && distance <= LIMIT_DISTANCE && startHour >= LIMIT_START_HOUR && isLessEndTime;
    const pageProps = { title, scrollingDisabled, className: css.root };

    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return (
        <Page {...pageProps}>
          {topbar}
          <div className={css.loading}>
            <IconSpinner />
            <div>Please wait...</div>
          </div>
        </Page>
      );
    }

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const authorDisplayName =  currentAuthor.attributes && currentAuthor.attributes.profile && currentAuthor.attributes.profile.displayName;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error('Missing or invalid data for checkout, redirecting back to listing page.', {
        transaction: speculatedTransaction,
        bookingDates,
        listing,
      });
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    if (this.state.shouldRedirectToSearchPage) {
      const searchParams = parse(location.search);
      const searchPageParams = searchParams && searchParams['searchPageParams'];
      const defaultSearchPageParams =
        '?address=Singapore&bounds=1.4708809%2C104.04157989999999%2C1.216611%2C103.60650989999999';
      return (
        <NamedRedirect name="SearchPage" search={searchPageParams || defaultSearchPageParams} />
      );
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking ? existingTransaction : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const { profile } = ensureCurrentUser(currentUser).attributes;
    const hasMastercardPromoBeenUsed =
      profile && profile.metadata && profile.metadata.hasMastercardPromoBeenUsed;
    const isNewCar = get(listing, 'attributes.metadata.isNewCar');
    const canUseMastercardPromo = isNewCar
      ? false
      : checkCanUseMastercardPromo(ensureCurrentUser(currentUser));
    const timeZone = getDefaultTimeZoneOnBrowser(timezone) || 'Asia/Singapore';

    const breakdownNormal = (
      <BookingBreakdownNew
        timeZone={timeZone}
        className={css.bookingBreakdown}
        userRole="customer"
        unitType={config.bookingUnitType}
        transaction={restoreTransaction(tx, this.state.isUsingMastercard && canUseMastercardPromo)}
        booking={txBooking}
        shouldShowMastercardPromoLineItem={this.state.isUsingMastercard}
        getTotalPrice={this.getTotalPrice}
        checkoutStep={this.state.checkoutStep}
        changeCheckoutStep={step => this.setState({ checkoutStep: step })}
        isFuelInclusion={isFuelInclusion}
        fuelPrice={fuelPrice}
      />
    );

    const breakdownLongTerm = (
      <div className={css.remainBreakdownLongTerm}>
        <h3 className={css.priceBreakdownLongTermTitle}>
          <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
        </h3>
        <CheckoutBreakdownLongTerm
          timeZone={timeZone}
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={restoreTransaction(
            tx,
            this.state.isUsingMastercard && canUseMastercardPromo
          )}
          booking={txBooking}
          shouldShowMastercardPromoLineItem={this.state.isUsingMastercard}
          hideBookingPeriod={true}
          hidePaymentMade
        />
      </div>
    );

    const breakdown = tx.id && (isLongTermRental ? breakdownLongTerm : breakdownNormal);

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);
    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired &&
      !bookingOverlapError &&
      !retrievePaymentDepositIntentError
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
    const isBookingTimeNotAvailableError =
      isTransactionInitiateBookingTimeNotAvailableError(initiateOrderError) || bookingOverlapError;
    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let showErrorAlert = null;
    let retryButton = null;
    let listingNotFoundErrorMessage = null;

    const redirectToListingButton = (
      <Button
        className={css.retryButton}
        inProgress={this.state.isRetryButtonClicked}
        onClick={() => {
          if (typeof window !== 'undefined') {
            const checkoutStrPos = window.location.href.indexOf('/checkout');
            const currentListingUrl = window.location.href.substr(0, checkoutStrPos);
            this.setState({ isRetryButtonClicked: true });
            window.location.href = currentListingUrl;
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    const reloadButton = (
      <Button
        className={css.retryButton}
        inProgress={this.state.isRetryButtonClicked}
        onClick={() => {
          if (typeof window !== 'undefined') {
            this.setState({ isRetryButtonClicked: true });
            window.location.reload();
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    const redirectToSearchPageButton = (
      <Button
        className={css.retryButton}
        onClick={() => {
          if (typeof window !== 'undefined') {
            this.setState({ shouldRedirectToSearchPage: true });
          }
        }}
      >
        <FormattedMessage id={'CheckoutPage.retryButton'} />
      </Button>
    );

    // if(this.state.isAlert){
    //   const voucherInvalidMessage = <FormattedMessage id="CheckoutPage.invalidVoucher" />
    //
    //   showErrorAlert = (
    //     <AlertBox title="Voucher is invalid" message={voucherInvalidMessage} type="error" button={redirectToSearchPageButton} />
    //   )
    // }

    if (voucherIsInvalid) {
      const voucherInvalidMessage = <FormattedMessage id="CheckoutPage.invalidVoucher" />;

      showErrorAlert = (
        <AlertBox title="Voucher is invalid" message={voucherInvalidMessage} type="error" />
      );
    } else if (listingNotFound) {
      const listingNotFoundErrorMessage = (
        <FormattedMessage id="CheckoutPage.listingNotFoundError" />
      );
      showErrorAlert = (
        <AlertBox
          title="Voucher is invalid"
          message={listingNotFoundErrorMessage}
          type="error"
          button={redirectToSearchPageButton}
        />
      );
    } else if (isAmountTooLowError) {
      const initiateOrderAmountTooLow = (
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      );
      showErrorAlert = (
        <AlertBox
          title="Order amount is too low"
          message={initiateOrderAmountTooLow}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (isBookingTimeNotAvailableError) {
      const bookingTimeNotAvailableMessage = (
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      );
      showErrorAlert = (
        <AlertBox
          title="Booking time not available"
          message={bookingTimeNotAvailableMessage}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (isChargeDisabledError) {
      const chargeDisabledMessage = <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />;
      showErrorAlert = (
        <AlertBox
          title="Charge disabled"
          message={chargeDisabledMessage}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      const initiateOrderStripeError = (
        <FormattedMessage
          id="CheckoutPage.initiateOrderStripeError"
          values={{ stripeErrors: stripeErrorsAsString }}
        />
      );
      showErrorAlert = (
        <AlertBox
          title="Failed to confirm your payment! "
          message="Please try again with another credit/debit card."
          type="error"
        />
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      if (initiateOrderError.name === 'insufficient_funds') {
        const initiateOrderErrorInsufficientFunds = (
          <FormattedMessage
            id="CheckoutPage.initiateOrderErrorInsufficientFunds"
            values={{ listingLink }}
          />
        );
        showErrorAlert = (
          <AlertBox
            title="Infufficient funds"
            message={initiateOrderErrorInsufficientFunds}
            type="error"
            button={reloadButton}
          />
        );
      } else if (initiateOrderError.status === 'card_error') {
        const initiateOrderErrorCardError = (
          <FormattedMessage
            id="CheckoutPage.initiateOrderErrorCardError"
            values={{ listingLink, message: initiateOrderError.message }}
          />
        );
        showErrorAlert = (
          <AlertBox
            title="Card error"
            message={initiateOrderErrorCardError}
            type="error"
            button={reloadButton}
          />
        );
      } else if (initiateOrderError.message) {
        if (initiateOrderError.status === 411) {
          const initiateOrderOverlapMessage = (
            <FormattedMessage id="CheckoutPage.initiateOrderOverlapMessage" />
          );
          showErrorAlert = (
            <AlertBox
              title="Order error"
              message={initiateOrderOverlapMessage}
              type="error"
              button={redirectToListingButton}
            />
          );
        } else {
          const initiateOrderErrorWithMessage = (
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorWithMessage"
              values={{ listingLink, message: initiateOrderError.message }}
            />
          );
          showErrorAlert = (
            <AlertBox
              title="Order error"
              message={initiateOrderErrorWithMessage}
              type="error"
              button={redirectToListingButton}
            />
          );
        }
        retryButton = redirectToListingButton;
      } else {
        const initiateOrderErrorMessages = (
          <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
        );
        showErrorAlert = (
          <AlertBox
            title="Order error"
            message={initiateOrderErrorMessages}
            type="error"
            button={reloadButton}
          />
        );
      }
    }
    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      const providerStripeAccountMissingError = (
        <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
      );
      showErrorAlert = (
        <AlertBox
          title="Stripe account missing"
          message={providerStripeAccountMissingError}
          type="error"
          button={redirectToSearchPageButton}
        />
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      const bookingTimeNotAvailableMessage = (
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      );
      showErrorAlert = (
        <AlertBox
          title="Booking time not available"
          message={bookingTimeNotAvailableMessage}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      const initiateOrderAmountTooLow = (
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      );
      showErrorAlert = (
        <AlertBox
          title="Order amount too low"
          message={initiateOrderAmountTooLow}
          type="error"
          button={redirectToListingButton}
        />
      );
    } else if (speculateTransactionError) {
      const speculateFailedMessage = <FormattedMessage id="CheckoutPage.speculateFailedMessage" />;
      showErrorAlert = (
        <AlertBox
          title="Failed to confirm your payment! "
          message="Please try again with another credit/debit card."
          type="error"
        />
      );
    }

    if (voucherIsInvalid) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.invalidVoucher" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
      retryButton = redirectToSearchPageButton;
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
      retryButton = reloadButton;
    } else if (initiateOrderError) {
      // Generic initiate order error

      if (initiateOrderError.name === 'insufficient_funds') {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorInsufficientFunds"
              values={{ listingLink }}
            />
          </p>
        );
        retryButton = reloadButton;
      } else if (initiateOrderError.status === 'card_error') {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage
              id="CheckoutPage.initiateOrderErrorCardError"
              values={{ listingLink, message: initiateOrderError.message }}
            />
          </p>
        );
        retryButton = reloadButton;
      } else if (initiateOrderError.message) {
        if (initiateOrderError.status === 411) {
          initiateOrderErrorMessage = (
            <p className={css.orderError}>
              <FormattedMessage id="CheckoutPage.initiateOrderOverlapMessage" />
            </p>
          );
        } else {
          initiateOrderErrorMessage = (
            <p className={css.orderError}>
              <FormattedMessage
                id="CheckoutPage.initiateOrderErrorWithMessage"
                values={{ listingLink, message: initiateOrderError.message }}
              />
            </p>
          );
        }
        retryButton = redirectToListingButton;
      } else {
        initiateOrderErrorMessage = (
          <p className={css.orderError}>
            <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
          </p>
        );
        retryButton = reloadButton;
      }
    }

    // const speculateTransactionErrorMessage = speculateTransactionError ? (
    //   <p className={css.speculateError}>
    //     <FormattedMessage id="CheckoutPage.speculateTransactionError" />
    //   </p>
    // ) : null;
    // let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
      retryButton = redirectToSearchPageButton;
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
      retryButton = redirectToListingButton;
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
      retryButton = reloadButton;
    }

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const transactionData = restoreTransaction(
      tx,
      this.state.isUsingMastercard && canUseMastercardPromo
    );

    const unitTranslationKey = isLongTermRental
      ? 'ListingPage.perMonth'
      : isDaily
      ? 'CheckoutPage.perDay'
      : 'CheckoutPage.perUnit';

    const longTermPrice = calculateLongTermPriceWithFeeOneMonth(speculatedTransactionMaybe);
    const price = isLongTermRental ? longTermPrice : currentListing.attributes.price;
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice} ${intl.formatMessage({ id: unitTranslationKey })}`;

    const showInitialMessageInput =
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE;

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName = null;
      // currentUser && currentUser.attributes
      //   ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
      //   : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      (paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status)) ||
      (paymentDepositIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentDepositIntent.status));

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const isYoungDriver = currentUserIsYoungDriver(currentUser);
    const isPremiumUser = price && price.amount >= 10000;

    // console.log("LISting PRRRRRRRRRRRR", price);

    let reductionAmount = 4320;

    if (isYoungDriver) {
      reductionAmount += isPremiumUser ? 3600 : 1440;
    } else if (isPremiumUser) {
      reductionAmount += 2160;
    }

    const stringPremiumExt = `Reduce your potential liability by $${reductionAmount}`;


    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
    const metadata = ensuredCurrentUser.attributes.profile.metadata || {};
    const { blockNo, building, floorUnit, city, country, postalCode } = protectedData;
    const { selectedPlace } = protectedData.location || {};
    const { address, origin } = selectedPlace || {};
    const { mileageExpOldUser = false  } = metadata


    const initalValuesForStripePayment = {
      name: userName,
      blockNo,
      addressLine1: blockNo + ' ' + address,
      addressLine2: floorUnit,
      location: {
        search: address,
        selectedPlace: {
          address,
          origin: new LatLng(origin && origin.lat, origin && origin.lng),
        },
      },
      building,
      floorUnit,
      city,
      country,
      postalCode,
      postal: postalCode,
    };

    const fuelType = currentListing.attributes.publicData.fuelType;

    const leavingPopup = (
      <div className={css.confirmPopup}>
        <div className={css.message}>
          {' '}
          <FormattedMessage
            id="CheckoutPage.confirmLeaveMessage"
            values={{ firstName: profile && profile.firstName }}
          />{' '}
        </div>
        <div className={css.buttonWrapper}>
          <Button
            className={css.buttonConfirmLeave}
            onClick={() => this.setState({ isOpenConfirmLeaveModal: false })}
          >
            <FormattedMessage id="CheckoutPage.confirmStay" />
          </Button>
          <Button className={css.buttonConfirmLeave} onClick={this.onConfirmLeave}>
            <FormattedMessage id="CheckoutPage.confirmLeave" />
          </Button>
        </div>
      </div>
    );

    const addOnsContent = [
      {
        id: 5,
        addonKey: 'fuelInclusion',
        addOnName: 'Fuel package',
        addOnIcon: addOnImage,
        addonUniqueName: 'FUEL_PACKAGE',
        description: 'No refueling required! Just enjoy your drive.',
        brief: FUEL_INCLUSION_ADDON__BRIEF,
        isAdded: false,
        isUnit: '/km',
        isBanner: false,
        bannerText: 'Recommended for you',
        bannerMobileText: 'Recommended for you',
        bannerHighlightType: 'new',
        addOnAmount: convertFuelPrice(fuelPrice && fuelPrice.price ? fuelPrice.price : 0),
        units: FUEL_INCLUSION_ADDON__UNITS,
        isExpanded: false,
        unavailable: this.fuelInclusionUnavailable(),
      },
      {
        id: 2,
        isUnit: '/day',
        isBanner: true,
        bannerText: 'Popular - 78% of our users opt for protection plan',
        bannerMobileText: 'Popular',
        bannerHighlightType: '',
        addOnName: ADDON_NAME__PREMIUM_PROTECTION,
        addonUniqueName: 'PREMIUM_PROTECTION',
        addOnIcon: premiumProtectionIcon,
        description: stringPremiumExt,
        brief: 'For just $8/day, save up to $4280 on your liability',
        isAdded: false,
        addOnAmount: 8,
        isExpanded: false,
      },
      {
        id: 3,
        isUnit: '/day',
        isBanner: false,
        bannerText: '68% of users opt for this',
        bannerMobileText: '68% of users opt for this',
        bannerHighlightType: 'Popular',
        addOnName: ADDON_NAME__MAXIMUM_PROTECTION,
        addonUniqueName: 'MAXIMUM_PROTECTION',
        addOnIcon: premiumProtectionIcon,
        description: 'Fully insure your trip with ZERO liability.',
        brief: 'For just $20/day reduce your liability to ZERO and get complete peace of mind.',
        isAdded: false,
        addOnAmount: 20,
        isExpanded: false,
      },
      {
        id: 4,
        isUnit: '/km',
        isBanner: true,
        bannerText: 'Recommended for you',
        bannerMobileText: 'Recommended for you',
        bannerHighlightType: 'new',
        isTooltip: true,
        addOnName: ADDON_NAME__MILEAGE_PACKAGE,
        addonUniqueName: 'MILEAGE_PACKAGE',
        addOnIcon: mileageIcon,
        description:
          'Include more kms for $0.29 per km (50% discount). If you exceed the included mileage, then you will be charged $0.60 per additional km driven.',
        brief: 'For just $20/day reduce your liability to ZERO and get complete peace of mind.',
        isAdded: false,
        addOnAmount: 0.29,
        isExpanded: false,
      },
      {
        id: 1,
        isUnit: '',
        isBanner: false,
        bannerText: '',
        bannerMobileText: '',
        isTooltip: true,
        addOnName: 'Home Delivery',
        addonUniqueName: 'HOME_DELIVERY',
        addOnIcon: deliveryIcon,
        description: 'Get the car delivered right to your doorstop.',
        brief: 'Get the car delivered right to your home.',
        isAdded: false,
        addOnAmount: 20,
        isExpanded: false,
      },
    ];

    // Data needed for addon slider
    let startDateTimeMaybe =
      transactionData &&
      transactionData.attributes &&
      transactionData.attributes.protectedData &&
      transactionData.attributes.protectedData.bookingDisplayStart;
    let endDateTimeMaybe =
      transactionData &&
      transactionData.attributes &&
      transactionData.attributes.protectedData &&
      transactionData.attributes.protectedData.bookingDisplayEnd;

    const totalTripHours = moment(endDateTimeMaybe).diff(moment(startDateTimeMaybe), 'hours', true);

    const hoursToTripStart = moment(startDateTimeMaybe).diff(moment(), 'hours', true);
    const futureHourCancelTime = moment().add(1, 'hours').tz('Asia/Singapore').format('Do MMM h:mm a');
    const cancelTime =  hoursToTripStart > 72 ? moment(startDateTimeMaybe).subtract(72, 'hours').tz('Asia/Singapore').format('Do MMM h:mm a'): futureHourCancelTime;

    const bookingDays = calculateBookingDays(startDateTimeMaybe, endDateTimeMaybe);
    const sliderOptions = sliderOptionsList(totalTripHours);
    const allowedMillage = calculateAllowedMillage({
      totalTripHours,
      sliderOptions,
      millagePerDayOld: this.state.millagePerDayOld,
      millagePerDay: this.state.millagePerDay
    });
    // console.log("YYYYYYYYYYYY", allowedMillage);
    let allowedMillageString
    if(allowedMillage === 999 || allowedMillage === "Unlimited" || allowedMillage === "unlimited") {
      allowedMillageString = "Unlimited"
    } else {
      allowedMillageString = totalTripHours > 8 ? (allowedMillage + " kms/day") : (allowedMillage + " kms")
    }
    // console.log("allowedMillage", allowedMillageString);

    const customerSelectedMileageValue = this.state.selectedMileage
      ? sliderOptions.defaultValue >= this.state.selectedMileage
        ? sliderOptions.defaultValue
        : this.state.selectedMileage
      : sliderOptions.defaultValue;
    const sliderDefaultValue = getMileageValuefromSliderMileageValue(
      customerSelectedMileageValue,
      totalTripHours
    );

    // console.log("Map ab segment", mapAbSegment);
    return (
      <>
        <Prompt when={isBlocking} message={this.handleNavigation} />
        {/* <Modal
          isOpen={isOpenConfirmLeaveModal}
          onClose={() => {
            this.setState({ isOpenConfirmLeaveModal: false });
          }}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {leavingPopup}
        </Modal> */}
        <Page {...pageProps}>
          {topbar}
          {showErrorAlert}
          {mapAbSegment && mapAbSegment === 'A' ? (
            <div className={css.contentContainer}>
              <div className={css.contentRow}>
                <div className={css.checkoutPageTitles}>
                  {/* <div className={css.checkoutBreadcrumbs}>
                  Checkout &gt; Step{' '}
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS && !(location && location.state && location.state.updateBooking)
                    ? '1'
                    : '2'}{' '}
                  of 2
                </div> */}
                  <div className={css.stepTitle}>
                    {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                    !(location && location.state && location.state.updateBooking)
                      ? 'Customize your trip'
                      : 'Pay & complete booking'}
                  </div>
                  {/* {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS && !(location && location.state && location.state.updateBooking) ? (
                  <p>
                    Make your trip safer and convenient by adding one of the awesome Drive lah add-ons. */}
                  {/* Some of the add-ons are charged after the trip is completed. */}
                  {/* </p>
                ) : (
                  <p>Select a payment method for paying for your trip.</p>
                )} */}
                </div>
              </div>

              <div className={css.contentRow}>
                <div className={css.aspectWrapper}>
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    alt={listingTitle}
                    image={firstImage}
                    variants={['landscape-crop', 'landscape-crop2x']}
                  />
                </div>
                <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
                  <AvatarMedium user={currentAuthor} disableProfileLink />
                </div>
                <div className={css.checkoutSectionLeft}>
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                  !(location && location.state && location.state.updateBooking) ? (
                    <div className={css.bookListingContainer}>
                      <AddOnsSection
                        mileageExpOldUser={mileageExpOldUser}
                        totalTripHours={totalTripHours}
                        customerSelectedMileage={customerSelectedMileageValue}
                        bookingDays={bookingDays ? bookingDays : 1}
                        sliderOptions={sliderOptions}
                        bookingDates={bookingDates}
                        sliderDefaultValue={sliderDefaultValue.slideValue}
                        currentUser={currentUser}
                        mapAbSegment={mapAbSegment}
                        addOnsContent={addOnsContent.filter(a => !a.unavailable)}
                        transaction={transactionData}
                        isProvider={false}
                        intl={intl}
                        diffHours={this.props.diffHours}
                        loadInitialData={this.loadInitialData}
                        listing={currentListing}
                        updateCheckoutSteps={stepNumber =>
                          this.checkoutPageManagingStep(stepNumber)
                        }
                        isDelivery={showDelivery}
                        isDeliveryState={this.state.isDeliveryState}
                        isExcessReductionState={this.state.isExcessReductionState}
                        isExcessReduction2State={this.state.isExcessReduction2State}
                        isMileagePackageIncludedState={this.state.isMileagePackageIncludedState}
                        isMileageIncludedState={this}
                        isFuelInclusionState={this.state.isFuelInclusionState}
                        handleChangeAddOn={this.handleChangeAddOn}
                        handleSelectedMileageAddOn={this.handleSelectedMileageAddOn}
                        selectedMileage={this.state.selectedMileage}
                        isCheckoutPage={true}
                        isFuelSelectedManually={this.state.isFuelSelectedManually}
                        fuelCategory={fuelCategory}
                        fuelType={fuelType}
                      />
                    </div>
                  ) : (
                    <div className={css.bookListingContainer}>
                      {/* TODO detailsContainerLeft */}

                      <div className={css.detailsContainerLeft}>
                        <div className={css.detailsLeftAspectWrapper}>
                          <ResponsiveImage
                            rootClassName={css.rootForImage}
                            alt={listingTitle}
                            image={firstImage}
                            variants={['landscape-crop', 'landscape-crop2x']}
                          />
                        </div>
                        <div className={css.avatarWrapperLeft}>
                          <AvatarMedium user={currentAuthor} disableProfileLink />
                        </div>
                        <div className={css.detailsHeadings}>
                          <h2 className={css.detailsTitle}>{listingTitle}</h2>
                          {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                          <LineItemBookingPeriodNew
                            booking={transactionData.booking}
                            unitType={unitType}
                            timezone={timeZone}
                            transaction={tx}
                          />
                        </div>
                      </div>

                      <div className={css.priceBreakdownContainer}>
                        <h3>Trip price breakdown</h3>
                        {/* {speculateTransactionErrorMessage} */}
                        {breakdown}
                      </div>

                      <section className={css.paymentContainer}>
                        {retrievePaymentIntentError || retrievePaymentDepositIntentError ? (
                          <p className={css.orderError}>
                            <FormattedMessage
                              id="CheckoutPage.retrievingStripePaymentIntentFailed"
                              values={{ listingLink }}
                            />
                          </p>
                        ) : null}
                        {/* {retryButton} */}
                        {showPaymentForm ? (
                          <StripePaymentForm
                            className={css.paymentForm}
                            onSubmit={this.handleSubmit}
                            inProgress={this.state.submitting}
                            formId="CheckoutPagePaymentForm"
                            paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                            authorDisplayName={currentAuthor.attributes.profile.displayName}
                            showInitialMessageInput={showInitialMessageInput}
                            initialValues={initalValuesForStripePayment}
                            initiateOrderError={initiateOrderError}
                            handleCardPaymentError={handleCardPaymentError}
                            confirmPaymentError={confirmPaymentError}
                            currentUser={currentUser}
                            transaction={transactionData}
                            isProvider={false}
                            intl={intl}
                            hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                            loadingData={!stripeCustomerFetched}
                            defaultPaymentMethod={
                              hasDefaultPaymentMethod
                                ? currentUser.stripeCustomer.defaultPaymentMethod
                                : null
                            }
                            isUsingMastercard={this.state.isUsingMastercard}
                            setIsUsingMastercard={val => {
                              this.setState({ isUsingMastercard: val });
                            }}
                            paymentIntent={paymentIntent}
                            onStripeInitialized={this.onStripeInitialized}
                            isInstantBooking={isInstantBooking}
                            submitButtonId={SEND_REQUEST_BOOKING_BUTTON_ID}
                            pushEventEnterFormGTM={this.pushEventEnterFormGTM}
                            isLongTermRental={isLongTermRental}
                            isPayForFuel={isPayForFuel}
                            isFuelInclusion={isFuelInclusion}
                            isDelivery={showDelivery}
                            isTotalPriceVisible={true}
                            isDeliveryState={this.state.isDeliveryState}
                            onChangeDelivery={this.onDeliveryChange}
                          />
                        ) : null}
                        {isPaymentExpired ? (
                          <p className={css.orderError}>
                            <FormattedMessage
                              id="CheckoutPage.paymentExpiredMessage"
                              values={{ listingLink }}
                            />
                          </p>
                        ) : null}
                      </section>

                      {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                      !(location && location.state && location.state.updateBooking) ? (
                        ''
                      ) : location && location.state && location.state.updateBooking ? (
                        ''
                      ) : (
                        <div className={css.cancellationDepositContainer}>
                          {/* {speculateTransactionErrorMessage} */}
                          <h3>Free cancellation</h3>
                          <p>
                            {isInstantBooking
                              ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`
                              : `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                          </p>
                        </div>
                      )}

                      {/* TODO TripAddonContainer */}
                      {location && location.state && location.state.updateBooking ? (
                        ''
                      ) : (
                        <div className={css.detailsAddonContainerMain}>
                          <h3>Trip customization</h3>
                          <div className={css.addonBreakdown}>
                            {mapAbSegment && mapAbSegment === 'B' ? (
                              <div className={css.addonLineItem}>
                                <span className={css.addonLineItemLabel}>Allowed mileage</span>
                                <span className={css.addonLineItemValue}>
                                  {allowedMillageString}
                                </span>
                              </div>
                            ) : (
                              <div className={css.addonLineItem}>
                                <span className={css.addonLineItemLabel}>Total mileage</span>
                                <span className={css.addonLineItemValue}>
                                  {this.state.selectedMileage < sliderOptions.defaultValue
                                    ? `${sliderOptions.defaultValue * bookingDays} kms (${
                                        sliderOptions.defaultValue
                                      } kms x ${bookingDays}  ${bookingDays > 1 ? 'days' : 'day'})`
                                    : this.state.selectedMileage === sliderOptions.max
                                    ? 'Unlimited'
                                    : `${this.state.selectedMileage * bookingDays} kms (${
                                        this.state.selectedMileage
                                      } kms x ${bookingDays}  ${
                                        bookingDays > 1 ? 'days' : 'day'
                                      })`}{' '}
                                  {this.state.selectedMileage < sliderOptions.max ? (
                                    <span
                                      className={css.editSingleAddon}
                                      onClick={() =>
                                        this.setState({
                                          checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                        })
                                      }
                                    >
                                      {' '}
                                      Add more
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            )}
                            <div className={css.addonLineItem}>
                              <span className={css.addonLineItemLabel}>Protection plan</span>
                              <span className={css.addonLineItemValue}>
                                {this.state.isExcessReduction2State
                                  ? 'Maximum '
                                  : this.state.isExcessReductionState
                                  ? 'Premium '
                                  : 'Basic '}
                                {!(
                                  this.state.isExcessReductionState ||
                                  this.state.isExcessReduction2State
                                ) ? (
                                  <span
                                    onClick={() =>
                                      this.setState({
                                        checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                      })
                                    }
                                    className={css.editSingleAddon}
                                  >
                                    Upgrade now{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                            <div className={css.addonLineItem}>
                              <span className={css.addonLineItemLabel}>Fuel Package</span>
                              <span className={css.addonLineItemValue}>
                                {this.state.isFuelInclusionState ? 'Included' : 'Not included '}
                                {!this.state.isFuelInclusionState ? (
                                  <span
                                    onClick={e =>
                                      this.handleChangeAddOn(
                                        e,
                                        {
                                          id: 5,
                                          addonKey: 'fuelInclusion',
                                          addOnName: 'Fuel package',
                                          addOnIcon: addOnImage,
                                          addonUniqueName: 'FUEL_PACKAGE',
                                          description:
                                            'No refueling required! Just enjoy your drive.',
                                          brief: FUEL_INCLUSION_ADDON__BRIEF,
                                          isAdded: false,
                                          isUnit: '/km',
                                          isBanner: true,
                                          bannerText: 'Recommended for you',
                                          bannerMobileText: 'Recommended for you',
                                          bannerHighlightType: 'new',
                                          addOnAmount: convertFuelPrice(
                                            fuelPrice && fuelPrice.price ? fuelPrice.price : 0
                                          ),
                                          units: FUEL_INCLUSION_ADDON__UNITS,
                                          isExpanded: false,
                                          unavailable: this.fuelInclusionUnavailable(),
                                        },
                                        fuelCategory
                                      )
                                    }
                                    className={css.editSingleAddon}
                                  >
                                    {this.state.isFuelInclusionState ? 'Remove' : ' Add now'}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                          </div>
                          {/* {breakdown} */}
                        </div>
                      )}
                    </div>
                  )}

                  {/* <div className={css.heading}>
                    <h1 className={css.title}>{title}</h1>
                    <div className={css.author}>
                      <FormattedMessage
                        id="CheckoutPage.hostedBy"
                        values={{ name: currentAuthor.attributes.profile.displayName }}
                      />
                    </div>
                  </div> */}
                </div>

                <div className={css.checkoutSectionRight}>
                  <div className={css.detailsContainerDesktop}>
                    {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                    !(location && location.state && location.state.updateBooking) ? (
                      <div className={css.detailsContainerTop}>
                        <div className={css.detailsAspectWrapper}>
                          <ResponsiveImage
                            rootClassName={css.rootForImage}
                            alt={listingTitle}
                            image={firstImage}
                            variants={['landscape-crop', 'landscape-crop2x']}
                          />
                        </div>
                        <div className={css.avatarWrapper}>
                          <AvatarMedium user={currentAuthor} disableProfileLink />
                        </div>
                        <div className={css.detailsHeadings}>
                          <h2 className={css.detailsTitle}>{listingTitle}</h2>
                          {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                          <LineItemBookingPeriodNew
                            booking={transactionData.booking}
                            unitType={unitType}
                            timezone={timeZone}
                            transaction={tx}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={css.detailsContainerMain}>
                        <h3>Trip price breakdown</h3>
                        {breakdown}
                      </div>
                    )}
                    {speculateTransactionErrorMessage}
                    {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                  </div>
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                  !(location && location.state && location.state.updateBooking) ? (
                    ''
                  ) : location && location.state && location.state.updateBooking ? (
                    ''
                  ) : (
                    <div className={css.detailsContainerDesktopCancellation}>
                      {location && location.state && location.state.updateBooking ? (
                        ''
                      ) : (
                        <div className={css.detailsContainerMain}>
                          <h3>Free cancellation</h3>
                          <p>
                            {isInstantBooking
                              ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`
                              : `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                          </p>
                        </div>
                      )}

                      {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                    </div>
                  )}
                </div>
                {/* Info slider */}
                {/*<InfoPromptSlider currentAuthor={currentAuthor} listing={listing} />*/}
              </div>
            </div>
          ) : (
            <div className={css.contentContainer}>
              <div className={css.contentRow}>
                <div className={css.checkoutPageTitles}>
                  {/* <div className={css.checkoutBreadcrumbs}>
                  Checkout &gt; Step{' '}
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS && !(location && location.state && location.state.updateBooking)
                    ? '1'
                    : '2'}{' '}
                  of 2
                </div> */}
                  <div className={css.stepTitle}>
                    {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                    !(location && location.state && location.state.updateBooking)
                      ? 'Customize your trip'
                      : 'Pay & complete booking'}
                  </div>
                  {/* {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS && !(location && location.state && location.state.updateBooking) ? (
                  <p>
                    Make your trip safer and convenient by adding one of the awesome Drive lah add-ons. */}
                  {/* Some of the add-ons are charged after the trip is completed. */}
                  {/* </p>
                ) : (
                  <p>Select a payment method for paying for your trip.</p>
                )} */}
                </div>
              </div>

              <div className={css.contentRow}>
                <div className={css.aspectWrapper}>
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    alt={listingTitle}
                    image={firstImage}
                    variants={['landscape-crop', 'landscape-crop2x']}
                  />
                </div>
                <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
                  <AvatarMedium user={currentAuthor} disableProfileLink />
                </div>
                <div className={css.checkoutSectionLeft}>
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                  !(location && location.state && location.state.updateBooking) ? (
                    <div className={css.bookListingContainer}>
                      <AddOnsSection
                        mileageExpOldUser={mileageExpOldUser}
                        totalTripHours={totalTripHours}
                        customerSelectedMileage={customerSelectedMileageValue}
                        bookingDays={bookingDays ? bookingDays : 1}
                        sliderOptions={sliderOptions}
                        bookingDates={bookingDates}
                        sliderDefaultValue={sliderDefaultValue.slideValue}
                        currentUser={currentUser}
                        mapAbSegment={mapAbSegment}
                        addOnsContent={addOnsContent.filter(a => !a.unavailable)}
                        transaction={transactionData}
                        isProvider={false}
                        intl={intl}
                        diffHours={this.props.diffHours}
                        loadInitialData={this.loadInitialData}
                        listing={currentListing}
                        updateCheckoutSteps={stepNumber =>
                          this.checkoutPageManagingStep(stepNumber)
                        }
                        isDelivery={showDelivery}
                        isDeliveryState={this.state.isDeliveryState}
                        isExcessReductionState={this.state.isExcessReductionState}
                        isExcessReduction2State={this.state.isExcessReduction2State}
                        isMileagePackageIncludedState={this.state.isMileagePackageIncludedState}
                        isMileageIncludedState={this}
                        isFuelInclusionState={this.state.isFuelInclusionState}
                        handleChangeAddOn={this.handleChangeAddOn}
                        handleSelectedMileageAddOn={this.handleSelectedMileageAddOn}
                        selectedMileage={this.state.selectedMileage}
                        isCheckoutPage={true}
                        isFuelSelectedManually={this.state.isFuelSelectedManually}
                        fuelCategory={fuelCategory}
                        fuelType={fuelType}
                      />
                    </div>
                  ) : (
                    <div className={css.bookListingContainer}>
                      {/* TODO detailsContainerLeft */}

                      <div className={css.detailsContainerLeft}>
                        <div className={css.detailsLeftAspectWrapper}>
                          <ResponsiveImage
                            rootClassName={css.rootForImage}
                            alt={listingTitle}
                            image={firstImage}
                            variants={['landscape-crop', 'landscape-crop2x']}
                          />
                        </div>
                        <div className={css.avatarWrapperLeft}>
                          <AvatarMedium user={currentAuthor} disableProfileLink />
                        </div>
                        <div className={css.detailsHeadings}>
                          <h2 className={css.detailsTitle}>{listingTitle}</h2>
                          {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                          <LineItemBookingPeriodNew
                            booking={transactionData.booking}
                            unitType={unitType}
                            timezone={timeZone}
                            transaction={tx}
                          />
                        </div>
                      </div>

                      <div className={css.priceBreakdownContainer}>
                        <h3>Trip price breakdown</h3>
                        {/* {speculateTransactionErrorMessage} */}
                        {breakdown}
                      </div>

                      <section className={css.paymentContainer}>
                        {/* {initiateOrderErrorMessage}
                      {listingNotFoundErrorMessage}
                      {speculateErrorMessage} */}
                        {retrievePaymentIntentError || retrievePaymentDepositIntentError ? (
                          <p className={css.orderError}>
                            <FormattedMessage
                              id="CheckoutPage.retrievingStripePaymentIntentFailed"
                              values={{ listingLink }}
                            />
                          </p>
                        ) : null}
                        {/* {retryButton} */}
                        {showPaymentForm ? (
                          <StripePaymentForm
                            className={css.paymentForm}
                            onSubmit={this.handleSubmit}
                            inProgress={this.state.submitting}
                            formId="CheckoutPagePaymentForm"
                            paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                            authorDisplayName={currentAuthor.attributes.profile.displayName}
                            showInitialMessageInput={showInitialMessageInput}
                            initialValues={initalValuesForStripePayment}
                            initiateOrderError={initiateOrderError}
                            handleCardPaymentError={handleCardPaymentError}
                            confirmPaymentError={confirmPaymentError}
                            currentUser={currentUser}
                            transaction={transactionData}
                            isProvider={false}
                            intl={intl}
                            hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                            loadingData={!stripeCustomerFetched}
                            defaultPaymentMethod={
                              hasDefaultPaymentMethod
                                ? currentUser.stripeCustomer.defaultPaymentMethod
                                : null
                            }
                            isUsingMastercard={this.state.isUsingMastercard}
                            setIsUsingMastercard={val => {
                              this.setState({ isUsingMastercard: val });
                            }}
                            paymentIntent={paymentIntent}
                            onStripeInitialized={this.onStripeInitialized}
                            isInstantBooking={isInstantBooking}
                            submitButtonId={SEND_REQUEST_BOOKING_BUTTON_ID}
                            pushEventEnterFormGTM={this.pushEventEnterFormGTM}
                            isLongTermRental={isLongTermRental}
                            isPayForFuel={isPayForFuel}
                            isFuelInclusion={isFuelInclusion}
                            isDelivery={showDelivery}
                            isTotalPriceVisible={true}
                            isDeliveryState={this.state.isDeliveryState}
                            onChangeDelivery={this.onDeliveryChange}
                          />
                        ) : null}
                        {isPaymentExpired ? (
                          <p className={css.orderError}>
                            <FormattedMessage
                              id="CheckoutPage.paymentExpiredMessage"
                              values={{ listingLink }}
                            />
                          </p>
                        ) : null}
                      </section>

                      {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                      !(location && location.state && location.state.updateBooking) ? (
                        ''
                      ) : location && location.state && location.state.updateBooking ? (
                        ''
                      ) : (
                        <div className={css.cancellationDepositContainer}>
                          {/* {speculateTransactionErrorMessage} */}
                          <h3>Free cancellation</h3>
                          <p>
                            {isInstantBooking
                              ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`
                              : `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                          </p>
                        </div>
                      )}

                      {/* TODO TripAddonContainer */}
                      {location && location.state && location.state.updateBooking ? (
                        ''
                      ) : (
                        <div className={css.detailsAddonContainerMain}>
                          <h3>Trip customization</h3>
                          <div className={css.addonBreakdown}>
                            {(mapAbSegment && mapAbSegment) === 'B' ? (
                              <div className={css.addonLineItem}>
                                <span className={css.addonLineItemLabel}>Allowed mileage</span>
                                <span className={css.addonLineItemValue}>
                                  {allowedMillageString}
                                </span>
                              </div>
                            ) : (
                              <div className={css.addonLineItem}>
                                <span className={css.addonLineItemLabel}>Total mileage</span>
                                <span className={css.addonLineItemValue}>
                                  {this.state.selectedMileage < sliderOptions.defaultValue
                                    ? `${sliderOptions.defaultValue * bookingDays} kms (${
                                        sliderOptions.defaultValue
                                      } kms x ${bookingDays} ${bookingDays > 1 ? 'days' : 'day'})`
                                    : this.state.selectedMileage === sliderOptions.max
                                    ? 'Unlimited'
                                    : `${this.state.selectedMileage * bookingDays} kms ${
                                        this.state.selectedMileage
                                      } kms x ${bookingDays} ${
                                        bookingDays > 1 ? 'days' : 'day'
                                      }`}{' '}
                                  {this.state.selectedMileage < sliderOptions.max ? (
                                    <span
                                      className={css.editSingleAddon}
                                      onClick={() =>
                                        this.setState({
                                          checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                        })
                                      }
                                    >
                                      {' '}
                                      Add more
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            )}
                            <div className={css.addonLineItem}>
                              <span className={css.addonLineItemLabel}>Protection plan</span>
                              <span className={css.addonLineItemValue}>
                                {this.state.isExcessReduction2State
                                  ? 'Maximum '
                                  : this.state.isExcessReductionState
                                  ? 'Premium '
                                  : 'Basic '}
                                {!(
                                  this.state.isExcessReductionState ||
                                  this.state.isExcessReduction2State
                                ) ? (
                                  <span
                                    onClick={() =>
                                      this.setState({
                                        checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                      })
                                    }
                                    className={css.editSingleAddon}
                                  >
                                    Upgrade now{' '}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                            <div className={css.addonLineItem}>
                              <span className={css.addonLineItemLabel}>Fuel Package</span>
                              <span className={css.addonLineItemValue}>
                                {this.state.isFuelInclusionState ? 'Included ' : 'Not included '}
                                <span
                                  onClick={() =>
                                    this.setState({
                                      checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                    })
                                  }
                                  className={css.editSingleAddon}
                                >
                                  {this.state.isFuelInclusionState ? ' Remove' : ' Add now'}
                                </span>
                              </span>
                            </div>
                            { showDelivery &&
                              <div className={css.addonLineItem}>
                                <span className={css.addonLineItemLabel}>Home delivery</span>
                                <span className={css.addonLineItemValue}>
                                  {this.state.isDeliveryState ? 'Included ' : 'Not included '}
                                  <span
                                    onClick={() =>
                                      this.setState({
                                        checkoutStep: checkoutStepConfiguration.CHECKOUT_ADD_ONS,
                                      })
                                    }
                                    className={css.editSingleAddon}
                                  >
                                  {this.state.isDeliveryState ? ' Remove' : ' Add now'}
                                </span>
                                </span>
                              </div>
                            }
                          </div>
                          {/* {breakdown} */}
                        </div>
                      )}
                    </div>
                  )}

                  {/* <div className={css.heading}>
                    <h1 className={css.title}>{title}</h1>
                    <div className={css.author}>
                      <FormattedMessage
                        id="CheckoutPage.hostedBy"
                        values={{ name: currentAuthor.attributes.profile.displayName }}
                      />
                    </div>
                  </div> */}
                </div>

                <div className={css.checkoutSectionRight}>
                  <div className={css.detailsContainerDesktop}>
                    {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                    !(location && location.state && location.state.updateBooking) ? (
                      <div className={css.detailsContainerTop}>
                        <div className={css.detailsAspectWrapper}>
                          <ResponsiveImage
                            rootClassName={css.rootForImage}
                            alt={listingTitle}
                            image={firstImage}
                            variants={['landscape-crop', 'landscape-crop2x']}
                          />
                        </div>
                        <div className={css.avatarWrapper}>
                          <AvatarMedium user={currentAuthor} disableProfileLink />
                        </div>
                        <div className={css.detailsHeadings}>
                          <h2 className={css.detailsTitle}>{listingTitle}</h2>
                          {/* <p className={css.detailsSubtitle}>{detailsSubTitle}</p> */}
                          <LineItemBookingPeriodNew
                            booking={transactionData.booking}
                            unitType={unitType}
                            timezone={timeZone}
                            transaction={tx}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={css.detailsContainerMain}>
                        <h3>Trip price breakdown</h3>
                        {breakdown}
                      </div>
                    )}
                    {speculateTransactionErrorMessage}
                    {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                  </div>
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                  !(location && location.state && location.state.updateBooking)
                    ? ''
                    : location && location.state && location.state.updateBooking
                    ? ''
                    : ''}
                  {checkoutStep === checkoutStepConfiguration.CHECKOUT_ADD_ONS &&
                  !(location && location.state && location.state.updateBooking) ? (
                    ''
                  ) : location && location.state && location.state.updateBooking ? (
                    ''
                  ) : (
                    <div className={css.detailsContainerDesktopCancellation}>
                      {location && location.state && location.state.updateBooking ? (
                        ''
                      ) : (
                        <div className={css.detailsContainerMain}>
                          <h3>Free cancellation</h3>
                          <p>
                            {isInstantBooking
                              ? ` You can cancel the trip anytime. 100% refund if the cancellation is
                              done before ${cancelTime}`
                              : `You're in control: You won't be charged until ${authorDisplayName} accepts your booking. Withdraw hassle-free before that with no deductions.`}
                          </p>
                        </div>
                      )}

                      {/* <InsurancePanel
                    listing={currentListing}
                    className={css.insuranceWrapper}
                    hasBookingData={!!currentTransaction.id && !!currentBooking.id}
                    insuranceType={insuranceType}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                    </div>
                  )}
                </div>
                {/* Info slider */}
                {/*<InfoPromptSlider currentAuthor={currentAuthor} listing={listing} />*/}
              </div>
            </div>
          )}
        </Page>
      </>
    );
  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    bookingOverlapError,
    timeSlotsObj,
    depositTx,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
  } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    bookingOverlapError,
    timeSlotsObj,
    retrievePaymentDepositIntentError,
    paymentDepositIntent,
    fetchedDepositTransaction: depositTx,
    fuelPriceByListing: $fuelPriceByListing(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchSpeculatedTransaction: (
    params,
    bookingProcess = null,
    minimumPrice = false,
    savedListing = null,
    transition
  ) =>
    dispatch(speculateTransaction(params, bookingProcess, minimumPrice, savedListing, transition)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
  onInitiateOrder: (
    params,
    transactionId,
    bookingProcess = null,
    minimumPrice = false,
    savedListing = null,
    selectedPaymentFlow = null,
    handlePaymentParams = null
  ) =>
    dispatch(
      initiateOrder(
        params,
        transactionId,
        bookingProcess,
        minimumPrice,
        savedListing,
        selectedPaymentFlow,
        handlePaymentParams
      )
    ),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onConfirmPaymentDeposit: params => dispatch(confirmPaymentDeposit(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  // onCheckBookingOverlap: (listingId, start, end) =>
  // dispatch(checkBookingOverlap(listingId, start, end)),
  onFetchDepositTx: params => dispatch(fetchDepositTx(params)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
