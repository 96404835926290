import React, { memo, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import css from './AlertBox.css';
import classNames from 'classnames';
import closeAlert from '../../assets/closeIconBlack.svg';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const AlertMessageStructure = props => {
  const { style, options, close, message } = props;
  const alertTypeClass =
    options.type === 'info'
      ? css.alertInformation
      : options.type === 'success'
      ? css.alertSuccess
      : options.type === 'error'
      ? css.alertError
      : '';
  return (
    <div style={style} className={classNames(css.alertBoxWrapper, alertTypeClass)}>
      {message}
      <button className={css.alertCloseButton} onClick={close}>
        <img src={closeAlert} alt="alert close" />
      </button>
    </div>
  );
};

const AlertBox = memo(
  props => {
    const { title = '', message = '', type, button = '', autoCloseTime } = props;
    const alert = useAlert();
    const history = useHistory();
    const alertMessageStructure = (
      <>
        {title && <h3>{title}</h3>}
        {message && <p>{message}</p>}
        {button && <div className={css.buttonOuter}>
          {button}
        </div>}
      </>
    );
    useEffect(() => {
      const alertInstance = alert.show(alertMessageStructure, { type });

      // Set up the timer to auto-close the alert only if autoCloseTime is defined
      let timer;
      if (autoCloseTime) {
        timer = setTimeout(() => {
          alert.remove(alertInstance); // Remove the alert after the timeout
        }, autoCloseTime);
      }

      return () => {
        if (timer) clearTimeout(timer);
      };
    }, [alert, alertMessageStructure, type, autoCloseTime]);

    return null; // No need to render anything from this component directly
  },
  (prev, next) => isEqual(prev.listings, next.listings)
);

export default AlertBox;
