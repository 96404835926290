import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import config from '../../config';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import { addMarketplaceEntities, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  findEndBoundary,
  findNextBookingTimeBoundary,
  findNextBoundary,
  findStartBoundary,
  monthIdStringInTimeZone,
  nextMonthFn,
} from '../../util/dates';
import {
  fetchCurrentUserHasOrdersSuccess,
  fetchCurrentUserNotifications,
} from '../../ducks/user.duck';
import { drivelahApiPost, drivelahApiPut } from '../../util/apiHelper';
import { getCurrentUser } from '../../util/browserStorageHelper';
import {
  denormalisedEntities,
  denormalisedResponseEntities,
  ensureCurrentUser,
  ensureListing,
  updatedEntities,
} from '../../util/data';
import { USER_SEND_MESSAGE } from '../../util/emailNotify';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import { sendGAEvent } from '../../util/googleAnalytics';
import { cancelTransaction, captureTransaction } from '../../util/lightrail';
import * as log from '../../util/log';
import {
  lockShuCar,
  sendNotification,
  sendShuCarPushConfig,
  startShuCar,
  stopShuCar,
  unlockShuCar,
} from '../../util/notification';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  FUEL_NOTIFICATION_FOR_500,
  FUEL_NOTIFICATION_WITHOUT_CHARGE,
  sendTransactionMsgContainingPhoneNoOrEmailNoti,
} from '../../util/slackNotify';
import {
  calculateBookingDays,
  getReview1Transition,
  getReview2Transition,
  isNewAdminActionTransition,
  TRANSITION_ACCEPT,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_CONFIRM_DROP_OFF,
  TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
  TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
  TRANSITION_DECLINE,
  TRANSITION_DEPOSIT_FAIL,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
  TRANSITION_FUEL_CHARGING_ACCEPT,
  TRANSITION_FUEL_CHARGING_CONFIRM_PAYMENT,
  TRANSITION_FUEL_CHARGING_DECLINE,
  TRANSITION_FUEL_CHARGING_REQUEST,
  TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
  TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
  TRANSITION_REQUEST_DROP_OFF,
  TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
  TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UNVERIFIED_WITHDRAW,
  TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CUSTOMER_CANCEL,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_CUSTOMER,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_PROVIDER_CANCEL,
  TRANSITION_WITHDRAW,
  txHasBeenAccepted,
  txIsEnquired,
  txIsInFirstReviewBy,
} from '../../util/transaction';
import {
  TRANSITION_ADDITIONAL_ACCEPT,
  TRANSITION_ADDITIONAL_CUSTOMER_CANCEL,
  TRANSITION_ADDITIONAL_DECLINE,
  TRANSITION_ADDITIONAL_PROVIDER_CANCEL,
  TRANSITION_ADDITIONAL_WITHDRAW,
} from '../../util/transactionAddons';
import {
  getReview1TransitionLTL,
  getReview2TransitionLTL,
  PROCESS_NAME_LTR_LAST,
  txIsInFirstReviewByLTL,
} from '../../util/transactionLongTermLast';
import {
  doesMessageContainPhoneNumberOrEmail,
  encodeMsgContainingPhoneNoOrEmailMaybe,
} from '../../util/validators';
import { entityRefs, sortedTransactions } from '../InboxPage/InboxPage.duck';
import { getTransactionPhoneNumbers } from './TransactionPage.helper';
import { bookingProcessAliasFuelCharging } from '../FuelCharging/fuelChargingHelpers';

import { updateParentTransaction } from '../../ducks/Transactions.duck';
import { getUpdateBookingTxs } from '../../ducks/UpdateBookingTxs.duck';
import { getLastUpdateObj } from '../../util/updateTransaction';
import { $updateBookingTxsByParentTxId } from '../EditTripPage/EditTripPage.duck';
import {
  REQUEST_STATUS__ERROR,
  REQUEST_STATUS__NULL,
  REQUEST_STATUS__PENDING,
  REQUEST_STATUS__SUCCESS,
} from '../../util/constants';
import {
  ACTIVE_TRIPS_TRANSITIONS,
  ExcludeProcessNames,
} from '../../components/TripPanel/transitionHelper';
import { countDistanceTwoPoints } from '../../util/maps';
import { pickUpDropOffByAdmin } from "../../modules/admin-actions/update-booking/adminUpdateBooking.duck";

const apiUrl = config.apiUrl;
const { UUID, Money } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const CUSTOMER = 'customer';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST =
  'app/TransactionPage/FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST';
export const FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS =
  'app/TransactionPage/FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS';
export const FETCH_UPDATE_BOOKING_TRANSACTION_ERROR =
  'app/TransactionPage/FETCH_UPDATE_BOOKING_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const TRANSIT_REQUEST = 'app/TransactionPage/TRANSIT_REQUEST';
export const TRANSIT_SUCCESS = 'app/TransactionPage/TRANSIT_SUCCESS';
export const TRANSIT_ERROR = 'app/TransactionPage/TRANSIT_ERROR';

export const FETCH_ACCEPTED_BOOKING_TX_SUCCESS =
  'app/ListingPage/FETCH_ACCEPTED_BOOKING_TX_SUCCESS';
export const FETCH_ACCEPTED_BOOKING_TX_ERROR = 'app/ListingPage/FETCH_ACCEPTED_BOOKING_TX_ERROR';

export const FETCH_TX_PHONE_NUMBER_REQUEST = 'app/TransactionPage/FETCH_TX_PHONE_NUMBER_REQUEST';
export const FETCH_TX_PHONE_NUMBER_SUCCESS = 'app/TransactionPage/FETCH_TX_PHONE_NUMBER_SUCCESS';
export const FETCH_TX_PHONE_NUMBER_FAIL = 'app/TransactionPage/FETCH_TX_PHONE_NUMBER_FAIL';

export const ADD_CHILD_LONG_TERM_TRANSACTIONS =
  'app/TransactionPage/ADD_CHILD_LONG_TERM_TRANSACTIONS';
export const ADD_NEXT_CHILD_TRANSACTION = 'app/TransactionPage/ADD_NEXT_CHILD_TRANSACTION';
export const ADD_CURRENT_CHILD_TRANSACTION = 'app/TransactionPage/ADD_CURRENT_CHILD_TRANSACTION';

export const UPLOAD_INTERIOR_PHOTO_REQUEST = 'app/TransactionPage/UPLOAD_INTERIOR_PHOTO_REQUEST';
export const UPLOAD_INTERIOR_PHOTO_SUCCESS = 'app/TransactionPage/UPLOAD_INTERIOR_PHOTO_SUCCESS';
export const UPLOAD_INTERIOR_PHOTO_ERROR = 'app/TransactionPage/UPLOAD_INTERIOR_PHOTO_ERROR';

export const CUSTOMER_UPDATE_BOOKING_REQUEST =
  'app/TransactionPage/CUSTOMER_UPDATE_BOOKING_REQUEST';
export const CUSTOMER_UPDATE_BOOKING_SUCCESS =
  'app/TransactionPage/CUSTOMER_UPDATE_BOOKING_SUCCESS';
export const CUSTOMER_UPDATE_BOOKING_ERROR = 'app/TransactionPage/CUSTOMER_UPDATE_BOOKING_ERROR';

export const UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST =
  'app/TransactionPage/UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST';
export const UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS =
  'app/TransactionPage/UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS';
export const UPDATE_BOOKING_BEFORE_PICK_UP_ERROR =
  'app/TransactionPage/UPDATE_BOOKING_BEFORE_PICK_UP_ERROR';

export const UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST =
  'app/TransactionPage/UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST';
export const UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS =
  'app/TransactionPage/UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS';
export const UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR =
  'app/TransactionPage/UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR';

export const INITIATE_ORDER_REQUEST = 'app/TransactionPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/TransactionPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/TransactionPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/TransactionPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/TransactionPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/TransactionPage/CONFIRM_PAYMENT_ERROR';

export const ESTIMATE_BREAKDOWN_REQUEST = 'app/TransactionPage/ESTIMATE_BREAKDOWN_REQUEST';
export const ESTIMATE_BREAKDOWN_SUCCESS = 'app/TransactionPage/ESTIMATE_BREAKDOWN_SUCCESS';
export const ESTIMATE_BREAKDOWN_ERROR = 'app/TransactionPage/ESTIMATE_BREAKDOWN_ERROR';

export const ADD_LISTING_FOR_MAP = 'app/TransactionPage/ADD_LISTING_FOR_MAP';

export const GET_USER_DISTANCE_FROM_PICK_UP = 'app/TransactionPage/GET_USER_DISTANCE_FROM_PICK_UP';
export const GET_USER_LOCATION = 'app/StaticGoogleMap/GET_USER_LOCATION'

export const FUEL_TRANSACTION_REQUEST = 'app/TransactionPage/FUEL_TRANSACTION_REQUEST';
export const FUEL_TRANSACTION_SUCCESS = 'app/TransactionPage/FUEL_TRANSACTION_SUCCESS';
export const FUEL_TRANSACTION_FAIL = 'app/TransactionPage/FUEL_TRANSACTION_FAIL';

export const DLGO_TRIP_DISTANCE_REQUEST = 'app/TransactionPage/DLGO_TRIP_DISTANCE_REQUEST';
export const DLGO_TRIP_DISTANCE_SUCCESS = 'app/TransactionPage/DLGO_TRIP_DISTANCE_SUCCESS';
export const DLGO_TRIP_DISTANCE_ERROR = 'app/TransactionPage/DLGO_TRIP_DISTANCE_ERROR';

export const CANCEL_UPDATE_BOOKINGS_REQUEST = 'app/TransactionPage/CANCEL_UPDATE_BOOKINGS_REQUEST';
export const CANCEL_UPDATE_BOOKINGS_SUCCESS = 'app/TransactionPage/CANCEL_UPDATE_BOOKINGS_SUCCESS';
export const CANCEL_UPDATE_BOOKINGS_ERROR = 'app/TransactionPage/CANCEL_UPDATE_BOOKINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,

  fetchUpdateBookingTransactionInProgress: false,
  fetchUpdateBookingTransactionError: null,
  transactionUpdateBookingRef: null,

  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transitInProgress: false,
  transitError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  nextLongTermTransaction: null,
  childLongTermTransactions: [],
  currentChildLongTermTransaction: null,
  uploadInteriorPhotoProgress: false,
  uploadInteriorSuccess: false,
  uploadInteriorError: false,
  monthlyTimeSlots: {},
  customerUpdateBookingSuccess: false,
  customerUpdateBookingInProgress: false,
  customerUpdateBookingError: null,

  updateBookingBeforePickUpSuccess: false,
  updateBookingBeforePickUpInProgress: false,
  updateBookingBeforePickUpError: null,

  updateBookingBeforeDropOffSuccess: false,
  updateBookingBeforeDropOffInProgress: false,
  updateBookingBeforeDropOffError: null,

  estimateBreakdownInProgress: false,
  estimatedTx: null,
  estimateError: null,

  transaction: null,
  initiateOrderError: null,

  confirmPaymentInProgress: false,
  confirmPaymentError: null,

  listingForMap: null,
  initiateOrderInProgress: false,
  isFuelTransactionPending: false,

  dlGoTripDistance: null,
  dlGoTripDistanceInProgress: false,
  cancelUpdateBookingsRequestStatus: REQUEST_STATUS__NULL,
  getDistanceFromPickUp: {
    distance: null,
    withinRange: null,
    locationPermission: null,
    error: "listing_distance_calculating"
  },

  userLocation: {
    lat: null,
    lng: null
  }
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionInProgress: false, fetchTransactionError: payload };

    case FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchUpdateBookingTransactionInProgress: true,
        fetchUpdateBookingTransactionError: null,
      };
    case FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return {
        ...state,
        fetchUpdateBookingTransactionInProgress: false,
        transactionUpdateBookingRef: transactionRef,
      };
    }
    case FETCH_UPDATE_BOOKING_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchUpdateBookingTransactionInProgress: false,
        fetchUpdateBookingTransactionError: payload,
      };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_ACCEPTED_BOOKING_TX_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchAcceptedBookingTxError: false,
        transactionRefs: entityRefs(transactions),
      };
    }
    case FETCH_ACCEPTED_BOOKING_TX_ERROR: {
      return {
        ...state,
        fetchAcceptedBookingTxError: true,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
    }

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderInProgress: true, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, initiateOrderInProgress: false, transaction: payload };
    case INITIATE_ORDER_ERROR:
      return { ...state, initiateOrderInProgress: false, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentInProgress: true, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return { ...state, confirmPaymentInProgress: false };
    case CONFIRM_PAYMENT_ERROR:
      return { ...state, confirmPaymentInProgress: false, confirmPaymentError: payload };

    case ESTIMATE_BREAKDOWN_REQUEST:
      return { ...state, estimateBreakdownInProgress: true, estimateError: false };
    case ESTIMATE_BREAKDOWN_SUCCESS:
      return { ...state, estimateBreakdownInProgress: false, estimatedTx: payload };
    case ESTIMATE_BREAKDOWN_ERROR:
      return { ...state, estimateBreakdownInProgress: false, estimateError: payload };

    case TRANSIT_REQUEST:
      return { ...state, transitInProgress: true };
    case TRANSIT_SUCCESS:
      return { ...state, transitInProgress: false };
    case TRANSIT_ERROR:
      return { ...state, transitInProgress: false, transitError: payload };
    case ADD_CHILD_LONG_TERM_TRANSACTIONS:
      return { ...state, childLongTermTransactions: payload };
    case ADD_NEXT_CHILD_TRANSACTION:
      return { ...state, nextLongTermTransaction: payload };
    case ADD_CURRENT_CHILD_TRANSACTION:
      return { ...state, currentChildLongTermTransaction: payload };
    case UPLOAD_INTERIOR_PHOTO_REQUEST:
      return { ...state, uploadInteriorPhotoProgress: true };
    case UPLOAD_INTERIOR_PHOTO_SUCCESS:
      return { ...state, uploadInteriorSuccess: true, uploadInteriorPhotoProgress: false };
    case UPLOAD_INTERIOR_PHOTO_ERROR:
      return { ...state, uploadInteriorError: true, uploadInteriorPhotoProgress: false };

    case CUSTOMER_UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        customerUpdateBookingInProgress: true,
        customerUpdateBookingSuccess: false,
        customerUpdateBookingError: null,
      };
    case CUSTOMER_UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        customerUpdateBookingInProgress: false,
        customerUpdateBookingSuccess: true,
        customerUpdateBookingError: false,
      };
    case CUSTOMER_UPDATE_BOOKING_ERROR:
      return {
        ...state,
        customerUpdateBookingInProgress: false,
        customerUpdateBookingSuccess: false,
        customerUpdateBookingError: payload,
      };

    case UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST:
      return {
        ...state,
        updateBookingBeforePickUpInProgress: true,
        updateBookingBeforePickUpSuccess: false,
        updateBookingBeforePickUpError: null,
      };
    case UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS:
      return {
        ...state,
        updateBookingBeforePickUpInProgress: false,
        updateBookingBeforePickUpSuccess: true,
        updateBookingBeforePickUpError: false,
      };
    case UPDATE_BOOKING_BEFORE_PICK_UP_ERROR:
      return {
        ...state,
        updateBookingBeforePickUpInProgress: false,
        updateBookingBeforePickUpSuccess: false,
        updateBookingBeforePickUpError: payload,
      };

    case UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST:
      return {
        ...state,
        updateBookingBeforeDropOffInProgress: true,
        updateBookingBeforeDropOffSuccess: false,
        updateBookingBeforeDropOffError: null,
      };
    case UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS:
      return {
        ...state,
        updateBookingBeforeDropOffInProgress: false,
        updateBookingBeforeDropOffSuccess: true,
        updateBookingBeforeDropOffError: false,
      };
    case UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR:
      return {
        ...state,
        updateBookingBeforeDropOffInProgress: false,
        updateBookingBeforeDropOffSuccess: false,
        updateBookingBeforeDropOffError: payload,
      };

    case ADD_LISTING_FOR_MAP:
      return { ...state, listingForMap: payload };

    case FUEL_TRANSACTION_REQUEST:
      return { ...state, isFuelTransactionPending: true };
    case FUEL_TRANSACTION_SUCCESS:
      return { ...state, isFuelTransactionPending: false };
    case FUEL_TRANSACTION_FAIL:
      return { ...state, isFuelTransactionPending: true };

    case DLGO_TRIP_DISTANCE_REQUEST:
      return { ...state, dlGoTripDistanceInProgress: true };
    case DLGO_TRIP_DISTANCE_SUCCESS:
      return { ...state, dlGoTripDistanceInProgress: false, dlGoTripDistance: payload };
    case DLGO_TRIP_DISTANCE_ERROR:
      return { ...state, dlGoTripDistanceInProgress: false };

    case CANCEL_UPDATE_BOOKINGS_REQUEST:
      return { ...state, cancelUpdateBookingsRequestStatus: REQUEST_STATUS__PENDING };

    case CANCEL_UPDATE_BOOKINGS_SUCCESS:
      return { ...state, cancelUpdateBookingsRequestStatus: REQUEST_STATUS__SUCCESS };

    case CANCEL_UPDATE_BOOKINGS_ERROR:
      return { ...state, cancelUpdateBookingsRequestStatus: REQUEST_STATUS__ERROR };

    case GET_USER_DISTANCE_FROM_PICK_UP:
      return {
        ...state,
        distanceFromPickUp: payload,
      };

    case GET_USER_LOCATION:
      return {
        ...state,
        userLocation: payload
      }

    default:
      return state;
  }
}

export const setDistanceFromPickUp = data => ({
  type: GET_USER_DISTANCE_FROM_PICK_UP,
  payload: data
});

export const setUserLocation = data => ({
  type: GET_USER_LOCATION,
  payload: data
})

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};

export const requestToUpdateBookingInProgress = state => {
  return (
    state.TransactionPage.customerAcceptInProgress ||
    state.TransactionPage.customerDeclineInProgress
  );
};

export const transitInProgress = state => {
  return (
    state.TransactionPage.acceptInProgress ||
    state.TransactionPage.declineInProgress ||
    state.TransactionPage.transitInProgress
  );
};

export const $nextLongTermTransaction = state => {
  return state.TransactionPage.nextLongTermTransaction;
};

export const $childLongTermTransactions = state => {
  return state.TransactionPage.childLongTermTransactions;
};

export const $currentChildLongTermTransaction = state => {
  return state.TransactionPage.currentChildLongTermTransaction;
};

export const $bookingTxRef = state => {
  return state.TransactionPage.transactionRef;
};

export const $bookingTx = state => {
  const txRef = $bookingTxRef(state);
  const nextLongTermTransaction = $nextLongTermTransaction(state);
  const childLongTermTransactions = $childLongTermTransactions(state);
  const currentChildLongTermTransaction = $currentChildLongTermTransaction(state);
  const txs = getMarketplaceEntities(state, txRef ? [txRef] : []);
  const tx = txs.length > 0 ? txs[0] : null;
  if (nextLongTermTransaction && tx) {
    tx.nextTransaction = nextLongTermTransaction;
    tx.childTransaction = childLongTermTransactions;
    tx.currentChildTransaction = currentChildLongTermTransaction;
  }
  return tx;
};

export const $updateBookingTxs = state => {
  const bookingTx = $bookingTx(state);
  const childTxIds = get(bookingTx, 'attributes.protectedData.childTransaction', []);
  const childTxsRefs = childTxIds.map(txId => ({ type: 'transaction', id: new UUID(txId) }));
  return getMarketplaceEntities(state, childTxsRefs);
};

export const $lastUpdateBookingTx = state => {
  const bookingTxs = $updateBookingTxs(state);
  return bookingTxs.length ? bookingTxs[bookingTxs.length - 1] : null;
};

export const $isCancelUpdateBookingsPending = state => {
  return state.TransactionPage.cancelUpdateBookingsRequestStatus === REQUEST_STATUS__PENDING;
};

export const $isCancelUpdateBookingsError = state => {
  return state.TransactionPage.cancelUpdateBookingsRequestStatus === REQUEST_STATUS__ERROR;
};

// ================ Action creators ================ //
const cancelUpdateBookingsRequest = () => ({
  type: CANCEL_UPDATE_BOOKINGS_REQUEST,
});

const cancelUpdateBookingsSuccess = () => ({
  type: CANCEL_UPDATE_BOOKINGS_SUCCESS,
});

const cancelUpdateBookingsError = () => ({
  type: CANCEL_UPDATE_BOOKINGS_ERROR,
});

export const dlGoTripDistanceRequest = () => ({
  type: DLGO_TRIP_DISTANCE_REQUEST,
});

export const dlGoTripDistanceSuccess = distance => ({
  type: DLGO_TRIP_DISTANCE_SUCCESS,
  payload: distance,
});

export const dlGoTripDistanceError = () => ({
  type: DLGO_TRIP_DISTANCE_ERROR,
});

export const fuelTransactionRequest = () => ({
  type: FUEL_TRANSACTION_REQUEST,
});

export const fuelTransactionSuccess = () => ({
  type: FUEL_TRANSACTION_SUCCESS,
});

export const fuelTransactionFail = () => ({
  type: FUEL_TRANSACTION_FAIL,
});

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({
  type: FETCH_UPDATE_BOOKING_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const fetchUpdateBookingTransactionRequest = () => ({
  type: FETCH_UPDATE_BOOKING_TRANSACTION_REQUEST,
});
const fetchUpdateBookingTransactionSuccess = response => ({
  type: FETCH_UPDATE_BOOKING_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchUpdateBookingTransactionError = e => ({
  type: FETCH_UPDATE_BOOKING_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });
const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});
const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });
const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});
const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const acceptSaleRequest = () => ({ type: ACCEPT_SALE_REQUEST });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = () => ({ type: DECLINE_SALE_REQUEST });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages, ...pagination },
});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

const transitRequest = () => ({ type: TRANSIT_REQUEST });
const transitSuccess = () => ({
  type: TRANSIT_SUCCESS,
});
const transitError = e => ({
  type: TRANSIT_ERROR,
  error: true,
  payload: e,
});

export const fetchAcceptedBookingTxSuccess = response => ({
  type: FETCH_ACCEPTED_BOOKING_TX_SUCCESS,
  payload: response,
});

export const fetchAcceptedBookingTxError = error => ({
  type: FETCH_ACCEPTED_BOOKING_TX_ERROR,
  payload: error,
});

const addChildLongTermTransactions = childLongTermTransactions => ({
  type: ADD_CHILD_LONG_TERM_TRANSACTIONS,
  payload: childLongTermTransactions,
});

const addNextLongTermTransaction = nextLongTermTransaction => ({
  type: ADD_NEXT_CHILD_TRANSACTION,
  payload: nextLongTermTransaction,
});

const addCurrentChildLongTermTransaction = currentChildLongTermTransaction => ({
  type: ADD_CURRENT_CHILD_TRANSACTION,
  payload: currentChildLongTermTransaction,
});

const addListingForMap = listing => ({
  type: ADD_LISTING_FOR_MAP,
  payload: listing,
});

const uploadInteriorPhotoRequest = () => ({ type: UPLOAD_INTERIOR_PHOTO_REQUEST });
const uploadInteriorPhotoSuccess = () => ({ type: UPLOAD_INTERIOR_PHOTO_SUCCESS });
const uploadInteriorPhotoError = () => ({ type: UPLOAD_INTERIOR_PHOTO_ERROR });

const customerUpdateBookingRequest = () => ({ type: CUSTOMER_UPDATE_BOOKING_REQUEST });
const customerUpdateBookingSuccess = () => ({ type: CUSTOMER_UPDATE_BOOKING_SUCCESS });
const customerUpdateBookingError = e => ({
  type: CUSTOMER_UPDATE_BOOKING_ERROR,
  error: true,
  payload: e,
});

const updateBookingBeforePickUpRequest = () => ({ type: UPDATE_BOOKING_BEFORE_PICK_UP_REQUEST });
const updateBookingBeforePickUpSuccess = () => ({ type: UPDATE_BOOKING_BEFORE_PICK_UP_SUCCESS });
const updateBookingBeforePickUpError = e => ({
  type: UPDATE_BOOKING_BEFORE_PICK_UP_ERROR,
  error: true,
  payload: e,
});

const updateBookingBeforeDropOffRequest = () => ({ type: UPDATE_BOOKING_BEFORE_DROP_OFF_REQUEST });
const updateBookingBeforeDropOffSuccess = () => ({ type: UPDATE_BOOKING_BEFORE_DROP_OFF_SUCCESS });
const updateBookingBeforeDropOffError = e => ({
  type: UPDATE_BOOKING_BEFORE_DROP_OFF_ERROR,
  error: true,
  payload: e,
});

export const getDistanceFromPickUp = listingLocation => dispatch => {
  if (!(listingLocation && listingLocation.lat)) {
    return dispatch(setDistanceFromPickUp({
      distance: null,
      error: 'no_listing_location'
    }));
  }
  let watchId;
  const handleSuccess = position => {
    console.log("================locationPermissionSuccess", position)
    if (position && position.coords) {
      const lat = position.coords.latitude
      const lng = position.coords.longitude
      dispatch(setUserLocation({
        lat,
        lng
      }))
      console.log('listingLocation.lat', listingLocation.lat)
      console.log('listingLocation.lng', listingLocation.lng)
      console.log('listingLocation.lnglat, lng',lat, lng)
      const distance = countDistanceTwoPoints(listingLocation.lat, listingLocation.lng, lat, lng);
      console.log('distance came', distance)
      if (distance * 1000 <= 250) {
        return dispatch(setDistanceFromPickUp({
          distance: distance * 1000,
          withinRange: true
        }));
      }
      return dispatch(setDistanceFromPickUp({
        distance: distance * 1000,
        withinRange: false
      }));
    }
  };
  const handleError = error => {
    console.log("================locationPermissionError", error)
    if(error.code == 1) {
      console.log("user permission denied")
    }
    return dispatch(setDistanceFromPickUp({
      distance: null,
      locationPermission: 'denied'
    }));
  };

  watchId = navigator.geolocation.watchPosition(handleSuccess, handleError);
  return () => {
    navigator.geolocation.clearWatch(watchId);
  };
};

// ================ Thunks ================ //

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

// const getSmsData = denormalised => {
//   const { displayName: customerName } = denormalised.customer.attributes.profile;
//   const { displayName: providerName } = denormalised.provider.attributes.profile;
//   const { title: listingTitle } = denormalised.listing.attributes;
//   const {
//     bookingDisplayStart,
//     bookingDisplayEnd,
//     customerPhoneNumberObj,
//   } = denormalised.attributes.protectedData;
//   const pickupDateTime = moment(bookingDisplayStart).format('DD/MM/YYYY hh:mm a');
//   const dropoffDateTime = moment(bookingDisplayEnd).format('DD/MM/YYYY hh:mm a');
//   return {
//     customerName,
//     providerName,
//     listingTitle,
//     pickupDateTime,
//     dropoffDateTime,
//     customerPhoneNumber: `${customerPhoneNumberObj.phoneCode}${customerPhoneNumberObj.rawNumber}`,
//   };
// };

const sendTransitEmailToAdmin = (id, transition, transaction = null, currentUser = null) => {
  const { start, end } =
    (transaction && transaction.booking && transaction.booking.attributes) || {};
  const bookingDays = calculateBookingDays(start, end);

  switch (transition) {
    case TRANSITION_CANCEL:
    case TRANSITION_CUSTOMER_CANCEL_REFUNDABLE:
    case TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_REFUNDABLE:
    case TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE:
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Trip Cancelled!',
        eventLabel: bookingDays,
      });
    // const smsData = getSmsData(transaction);
    // return sendEventGeneral({
    //   eventType: BOOKING_REQUEST_CANCELLED,
    //   transactionUUID: id.uuid,
    //   transition,
    //   ...smsData,
    // });
    // return sendEventGeneral({ eventType: BOOKING_REQUEST_CANCELLED, transactionUUID: id.uuid });
    case TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE:
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Trip Cancelled!',
        eventLabel: bookingDays,
      });
    // return sendEventGeneral({ eventType: BOOKING_REQUEST_CANCELLED, transactionUUID: id.uuid });
    case TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE:
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Host Confirms Pickup',
      });
    // return sendEventGeneral({
    //   eventType: EMAIL_PICKUP_CONFIRMED,
    //   transactionUUID: id.uuid,
    //   listingName: transaction.listing.attributes.title,
    //   guestPhoneNumber: `${transaction.attributes.protectedData.customerPhoneNumberObj.phoneCode}${transaction.attributes.protectedData.customerPhoneNumberObj.rawNumber}`,
    //   hostName: transaction.provider.attributes.profile.displayName,
    // });
    case TRANSITION_CONFIRM_DROP_OFF: {
      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Host Confirms Dropoff',
      });
      return sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Complete A Trip Successfully',
        eventValue: 4,
        eventLabel: bookingDays,
      });
      // return sendEventGeneral({
      //   eventType: EMAIL_DROPOFF_CONFIRMED,
      //   transactionUUID: id.uuid,
      //   listingName: transaction.listing.attributes.title,
      //   guestPhoneNumber: `${transaction.attributes.protectedData.customerPhoneNumberObj.phoneCode}${transaction.attributes.protectedData.customerPhoneNumberObj.rawNumber}`,
      //   hostName: transaction.provider.attributes.profile.displayName,
      // });
    }
    case TRANSITION_REQUEST_DROP_OFF:
    // const { provider, listing } = transaction;
    // return sendEventGeneral({
    //   eventType: EMAIL_DROPOFF_REQUEST,
    //   transactionUUID: id.uuid,
    //   listingName: listing.attributes.title,
    //   listingId: listing.id.uuid,
    //   guestName: currentUser.attributes.profile.displayName,
    //   guestId: currentUser.id.uuid,
    //   hostName: provider.attributes.profile.displayName,
    //   hostId: provider.id.uuid,
    //   guestReferralCodeData: currentUser.attributes.profile.metadata.guestReferralCodeData,
    // });
    case TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE:
    // return sendEventGeneral({
    //   eventType: EMAIL_PICKUP_REQUEST,
    //   transactionUUID: id.uuid,
    //   listingName: transaction.listing.attributes.title,
    //   guestName: transaction.customer.attributes.profile.displayName,
    // });
    case TRANSITION_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW:
    case TRANSITION_UNVERIFIED_WITHDRAW_INSTANT:
    // return sendGAEvent({
    //   eventCategory: 'Transaction',
    //   eventAction: 'A Trip Withdrawn',
    //   eventLabel: bookingDays,
    // });
    default:
      return;
  }
};

const updateTransaction = (metadata, id, dispatch) => {
  const { pickupSentAt, dropoffSentAt, pickupFrom, dropoffFrom } = metadata
  if (pickupSentAt && pickupFrom) {
    dispatch(updateParentTransaction(id.uuid, { pickupSentAt: pickupSentAt, pickupFrom: pickupFrom }));
  }
  if (dropoffSentAt && dropoffFrom) {
    dispatch(updateParentTransaction(id.uuid, { dropoffSentAt: dropoffSentAt, dropoffFrom: dropoffFrom }));
  }
}

export const transit = (
  id,
  transition,
  dataTransit = {},
  photoObjects = null,
  certificate = null,
  isShuCar = null,
  isStartCar = null,
  isLockCar = null,
  isUnlockCar = null,
  isLocateCar = null,
  isDlGoV3 = null,
  isStopCar = null,
  cleanlinessScore = null,
  isDropOff = null
) => async (dispatch, getState, sdk) => {
  if (transitInProgress(getState()) && transition !== TRANSITION_DEPOSIT_FAIL) {
    return Promise.reject(new Error('A pending transit already in progress'));
  }

  dispatch(transitRequest());
  const state = getState();
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];
  const additionalTransactions = transaction.attributes.metadata.addonsTransactions;
  const fuelChildTransactionId = transaction.attributes.metadata.fuelChildTransactionId;

  const {
    cancelReason,
    cancelType,
    pickUpOdometer,
    dropOffOdometer,
    currentTransaction,
    providerDropOffAction,
    fuelCharge,
    distanceTravelled,
    pickupSentAt,
    dropoffSentAt,
    pickupFrom,
    dropoffFrom
  } = dataTransit || {};
  const currentUser = state.user.currentUser;
  const metadata = {}

  if (pickupSentAt && pickupFrom) {
    metadata.pickupSentAt = pickupSentAt;
    metadata.pickupFrom = pickupFrom;
  }
  if (dropoffSentAt && dropoffFrom) {
    metadata.dropoffSentAt = dropoffSentAt;
    metadata.dropoffFrom = dropoffFrom;
  }

  const protectedData = {};
  if (cancelReason) {
    protectedData.cancelReason = cancelReason;
  }
  if (photoObjects) {
    protectedData.photoObjects = photoObjects;
  }
  if (fuelCharge) {
    protectedData.fuelCharge = fuelCharge;
  }
  if (certificate) {
    protectedData.certificate = certificate;
  }
  if (distanceTravelled) {
    protectedData.distanceTravelled = distanceTravelled;
  }
  if (pickUpOdometer) {
    protectedData.pickUpOdometer = pickUpOdometer;
  } else if (dropOffOdometer) {
    if (currentTransaction) {
      await handlePaymentFuel({ currentTransaction, dropOffOdometer, currentUser, sdk });
    }
    protectedData.dropOffOdometer = dropOffOdometer;
  }

  if(cleanlinessScore) {
    protectedData.cleanlinessScore = cleanlinessScore;
  }

  const bodyParams = { protectedData };

  const particularParams = !cancelType
    ? {}
    : {
        transitionType: 'cancel',
        isCommonTransition: true,
        transaction: currentTransaction || transaction,
      };

  const apiData = {
    user_id: transaction.customer.attributes.profile.publicData.shuUserId,
    booking_id: id.uuid,
    fleet_id: transaction.listing.attributes.publicData.license_plate_number,
  };
  // if (isShuCar) {
  //   sendShuCarPushConfig(apiData);
  // }

  // if (isStartCar) {
  //   startShuCar(apiData);
  // }
  if (isStopCar) {
    stopShuCar(apiData);
  }
  if (isLockCar) {
    lockShuCar(apiData);
    dispatch(transitSuccess());
    return;
  }
  if (isUnlockCar) {
    unlockShuCar(apiData);
    dispatch(transitSuccess());
    return;
  }

  return sdk.processTransitions
    .query({ transactionId: id && id.uuid })
    .then(response => {
      const possibleTransitions = response.data.data;
      console.log("Possible transitions: ", possibleTransitions);
      console.log("Coming transition", transition);
      if (!possibleTransitions.find(t => t.attributes.name === transition)) {
        alert('Your transaction page is outdated, please reload the page and try again');
        return window.location.reload();
      }

      if (isNewAdminActionTransition(transition)) {
        return pickUpDropOffByAdmin(id.uuid, transition, bodyParams)
          .then(result => {
            console.log('Admin action response data:', result);
            return result;
          })
          .catch(error => {
            console.error('Error during admin action:', error);
            throw error;
          });
      }
      return sdk.jh.transactions.transition(
        { id, particularParams, transition: transition, params: bodyParams },
        { expand: true }
      );
    })
    .then(async response => {
      await updateTransaction(metadata, id, dispatch)

      if (providerDropOffAction) {
        dispatch(
          fetchAcceptDeclineFuelPayment({
            transactionId: fuelChildTransactionId,
            transition: TRANSITION_FUEL_CHARGING_ACCEPT,
          })
        );
      }

      await sendNotification({
        userId: currentUser.id.uuid,
        transactionId: id,
        transition: transition,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });

      if (
        additionalTransactions &&
        additionalTransactions.length > 0 &&
        (transition === TRANSITION_WITHDRAW ||
          transition === TRANSITION_UNVERIFIED_WITHDRAW ||
          transition === TRANSITION_UNVERIFIED_WITHDRAW_INSTANT ||
          transition === TRANSITION_PROVIDER_CANCEL_REFUNDABLE ||
          transition === TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR ||
          transition === TRANSITION_CUSTOMER_CANCEL_REFUNDABLE)
      ) {
        let additionalTransition = TRANSITION_ADDITIONAL_WITHDRAW;

        if (
          transition === TRANSITION_PROVIDER_CANCEL_REFUNDABLE ||
          transition === TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR
        ) {
          additionalTransition = TRANSITION_ADDITIONAL_PROVIDER_CANCEL;
        }

        if (transition === TRANSITION_CUSTOMER_CANCEL_REFUNDABLE) {
          additionalTransition = TRANSITION_ADDITIONAL_CUSTOMER_CANCEL;
        }
        for (let i = 0; i < additionalTransactions.length; i++) {
          const bodyParams = {
            id: additionalTransactions[i].transactionId,
            transition: additionalTransition,
            params: {},
          };

          await sdk.transactions.transition(bodyParams, { expand: true });
        }
      }

      if (cancelReason) {
        return cancelTransaction(id.uuid)
          .then(() => {
            return response;
          })
          .catch(e => {
            return response;
          });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(transitSuccess());
      dispatch(fetchCurrentUserNotifications());
      sendTransitEmailToAdmin(id, transition, transaction, currentUser);

      return response;
    })
    .catch(e => {
      dispatch(transitError(storableError(e)));
      log.error(e, 'transit-failed', {
        txId: id,
        transition: transition,
      });
      throw e;
    });
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'customer.attributes.protectedData',
          'customer.attributes.profile.protectedData',
          'customer.attributes.profile.publicData',
          'provider',
          'provider.profileImage',
          'provider.attributes.protectedData',
          'provider.attributes.profile.protectedData',
          'provider.attributes.profile.publicData',
          'listing',
          'listing.images',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...IMAGE_VARIANTS,
      },
      { expand: true }
    )
    .then(async response => {
      const tx = response.data.data;
      dispatch(getUpdateBookingTxs(tx.id.uuid));
      // const updateBookingTransactionId = getLastUpdateTx(response.data.data);
      // if (updateBookingTransactionId) {
      //   const txId = new UUID(updateBookingTransactionId);
      //   dispatch(fetchUpdateBookingTransaction({txId}))
      // }

      const listingForMap =
        response.data.included && response.data.included.filter(i => i.type === 'listing')[0];

      dispatch(addListingForMap(listingForMap));

      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const isLongTerm = get(transaction, 'attributes.protectedData.isLongTermRental');
      if (isLongTerm) {
        const { currentUser } = getState().user;
        const cachedUser = getCurrentUser();
        const userId = (cachedUser && cachedUser.id.uuid) || (currentUser && currentUser.id.uuid);
        const childTransactionIds = get(transaction, 'attributes.metadata.childTransactionIds');
        const getBunchTransactionURL = 'transactions/retrieve-bunch-transactions';
        const data = {
          userId,
          transactionIds: childTransactionIds,
        };
        const childTransactionsRes = await drivelahApiPost(getBunchTransactionURL, data);
        const transactionParent = get(txResponse, 'data.data');
        const childTransactions = get(childTransactionsRes, 'data.data');
        dispatch(addChildLongTermTransactions(childTransactions));
        const nextTransactionId = get(transactionParent, 'attributes.metadata.nextTransaction');
        const currentChildTransactionId = get(
          transactionParent,
          'attributes.metadata.currentChildTransaction'
        );
        const nextTransaction = childTransactions.find(
          child => child.id.uuid === nextTransactionId
        );
        const currentChildTransaction = childTransactions.find(
          child => child.id.uuid === currentChildTransactionId
        );
        dispatch(addNextLongTermTransaction(nextTransaction));
        dispatch(addCurrentChildLongTermTransaction(currentChildTransaction));
      }
      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.enableAvailability &&
        transaction &&
        txIsEnquired(transaction);

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }

      fetchMonthlyTimeSlots(dispatch, listing);

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        if (txRole === 'customer') {
          return sdk.listings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        } else {
          return sdk.ownListings.show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            ...IMAGE_VARIANTS,
          });
        }
      } else {
        return response;
      }
    })
    .then(response => {
      const finalTxResponse = txResponse;
      dispatch(addMarketplaceEntities(finalTxResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(finalTxResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchTransactionPhoneNumber = ({ transactionId, finalTxResponse }) => dispatch => {
  dispatch({ type: FETCH_TX_PHONE_NUMBER_REQUEST });
  return getTransactionPhoneNumbers(transactionId)
    .then(response => {
      if (response.status !== 200) {
        return dispatch({
          type: FETCH_TX_PHONE_NUMBER_FAIL,
          payload: response.json(),
        });
      }
      return response.json();
    })
    .then(data => {
      const { customerPhoneNumberObj, hostPhoneNumberObj } = data;

      finalTxResponse.data.data.attributes.protectedData = {
        ...finalTxResponse.data.data.attributes.protectedData,
        customerPhoneNumberObj,
        hostPhoneNumberObj,
      };

      dispatch(addMarketplaceEntities(finalTxResponse));

      return dispatch({
        type: FETCH_TX_PHONE_NUMBER_SUCCESS,
        payload: {
          customerPhoneNumberObj,
          hostPhoneNumberObj,
        },
      });
    })
    .catch(error => {
      return dispatch({
        type: FETCH_TX_PHONE_NUMBER_FAIL,
        payload: error,
      });
    });
};

export const requestToUpdateBooking = params => (dispatch, getState, sdk) => {
  let {
    transaction,
    transactionId,
    listing,
    bookingStart,
    bookingEnd,
    transition,
    protectedData,
    createUpdateBooking,
  } = params;
  const state = getState();
  if (requestToUpdateBookingInProgress(state)) {
    return Promise.reject(new Error('Update booking already in progress'));
  }
  dispatch(customerUpdateBookingRequest());
  const particularParams = {
    transitionType: transition,
    isCommonTransition: true,
    transaction,
  };

  const transactionParams = {
    id: transactionId,
    transition,
    particularParams,
    params: {
      listingId: listing.id,
      bookingStart,
      bookingEnd,
      bookingDisplayStart: bookingStart,
      bookingDisplayEnd: bookingEnd,
    },
  };
  if (protectedData) {
    transactionParams.params.protectedData = protectedData;
  }
  return sdk.jh.transactions
    .transition(transactionParams)
    .then(async response => {
      dispatch(customerUpdateBookingSuccess());

      const { currentUser } = getState().user;

      await sendNotification({
        userId: currentUser.id.uuid,
        transactionId: transactionId,
        transition: transition,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });

      const lastUpdateObj = getLastUpdateObj(transaction);
      const lastUpdateBookingTxId = lastUpdateObj.txId;
      dispatch(fetchTransaction(transactionId, 'customer'));
      if (
        lastUpdateBookingTxId &&
        protectedData &&
        (transition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
          transition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
          transition === TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
          transition === TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF)
      ) {
        dispatch(
          fetchAcceptDeclineUpdateBooking({
            transactionId: lastUpdateBookingTxId,
            transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
          })
        );

        // await sendNotification({
        //   userId: currentUser.id.uuid,
        //   transactionId: transactionId,
        //   transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
        //   uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
        // });
      }

      if (
        lastUpdateBookingTxId &&
        protectedData &&
        (transition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
          transition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP ||
          transition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
          transition === TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF)
      ) {
        dispatch(
          fetchAcceptDeclineUpdateBooking({
            transactionId: lastUpdateBookingTxId,
            transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
          })
        );

        // sendNotification({
        //   userId: currentUser.id.uuid,
        //   transactionId: transactionId,
        //   transition: TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE,
        //   uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
        // });
      }

      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());

      // await sendNotification({
      //   userId: currentUser.id.uuid,
      //   transactionId: transactionId,
      //   transition: TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
      //   uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      // });

      return response;
    })
    .catch(e => {
      dispatch(customerUpdateBookingError(storableError(e)));
      throw e;
    });
};

export const fetchAcceptDeclineUpdateBooking = param => (dispatch, getState, sdk) => {
  const { transactionId, transition } = param;

  if (transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT) {
    dispatch(acceptSaleRequest());
  }
  if (transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE) {
    dispatch(declineSaleRequest());
  }

  return sdk.jh.transactions
    .transition(
      {
        processAlias: config.updateBookingChargingProcessAlias,
        id: transactionId,
        transition,
        params: {},
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());

      return response;
    })
    .catch(e => {
      if (transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT) {
        dispatch(acceptSaleError(storableError(e)));
      }
      if (transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE) {
        dispatch(declineSaleError(storableError(e)));
      }

      log.error(e, 'reject-sale-failed', {
        txId: transactionId,
        transition,
      });
      throw e;
    });
};

export const fetchUpdateBookingTransaction = params => (dispatch, getState, sdk) => {
  const { txId } = params;
  const id = txId;
  dispatch(fetchUpdateBookingTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        processAlias: config.updateBookingChargingProcessAlias,
        include: [
          'customer',
          'customer.profileImage',
          'customer.attributes.protectedData',
          'customer.attributes.profile.protectedData',
          'customer.attributes.profile.publicData',
          'provider',
          'provider.profileImage',
          'provider.attributes.protectedData',
          'provider.attributes.profile.protectedData',
          'provider.attributes.profile.publicData',
          'listing',
          'listing.images',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const entities = updatedEntities({}, response.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...IMAGE_VARIANTS,
        });
      } else {
        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchUpdateBookingTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchUpdateBookingTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchCancelUpdateBooking = param => (dispatch, getState, sdk) => {
  const { isCustomer } = param;
  const state = getState();
  const bookingTx = $bookingTx(state);
  const updateTxs = $updateBookingTxsByParentTxId(state, bookingTx.id.uuid);
  if (!updateTxs.length) {
    return Promise.resolve();
  }

  dispatch(cancelUpdateBookingsRequest());
  const requests = updateTxs.map(updateTx => {
    let transition;
    if (updateTx.attributes.lastTransition === TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT) {
      transition = isCustomer
        ? TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_CUSTOMER
        : TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE;
    } else {
      transition = isCustomer
        ? TRANSITION_UPDATE_BOOKING_CHILD_TX_CUSTOMER_CANCEL
        : TRANSITION_UPDATE_BOOKING_CHILD_TX_PROVIDER_CANCEL;
    }
    return sdk.jh.transactions.transition(
      {
        processAlias: config.updateBookingChargingProcessAlias,
        id: updateTx.id.uuid,
        transition,
        params: {},
      },
      { expand: true }
    );
  });

  return Promise.all(requests)
    .then(txsRes => {
      txsRes.map(txRes => dispatch(addMarketplaceEntities(txRes)));
      dispatch(fetchCurrentUserNotifications());
      dispatch(cancelUpdateBookingsSuccess());
      return txsRes;
    })
    .catch(error => {
      dispatch(cancelUpdateBookingsError());
      log.error(error, 'cancel-update-booking-failed', {
        bookingTxId: bookingTx.id.uuid,
        // txId: error.id.uuid,
        // transition,
      });
    });
};

export const acceptSale = (id, userObj = null, processAlias) => async (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest());

  const { currentUser } = getState().user;

  const state = getState();
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];

  const { start, end } =
    (transaction && transaction.booking && transaction.booking.attributes) || {};
  const bookingDays = calculateBookingDays(start, end);
  const additionalTransactions =
    transaction.attributes.metadata && transaction.attributes.metadata.addonsTransactions
      ? transaction.attributes.metadata.addonsTransactions
      : null;

  let transactionResponse = null;

  const particularParams = {
    transitionType: 'accept',
    isCommonTransition: true,
    transaction,
  };
  return sdk.jh.transactions
    .transition(
      {
        processAlias: processAlias ? processAlias : config.masterProcessAlias,
        id,
        particularParams,
        transition: TRANSITION_ACCEPT,
        params: {},
      },
      { expand: true }
    )
    .then(async response => {
      if (additionalTransactions && additionalTransactions.length > 0) {
        for (let i = 0; i < additionalTransactions.length; i++) {
          const bodyParams = {
            id: additionalTransactions[i].transactionId,
            transition: TRANSITION_ADDITIONAL_ACCEPT,
            params: {},
          };

          await sdk.transactions.transition(bodyParams, { expand: true });
        }
      }

      transactionResponse = response;
      return transactionResponse;
      // return captureTransaction(id.uuid);
    })
    .then(promoResponse => {
      dispatch(addMarketplaceEntities(transactionResponse));
      dispatch(acceptSaleSuccess());

      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Accept Request',
        eventValue: 3,
        eventLabel: bookingDays,
      });

      dispatch(fetchCurrentUserNotifications());
      sendNotification({
        userId: currentUser.id.uuid,
        transactionId: id,
        transition: TRANSITION_ACCEPT,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });

      return transactionResponse;
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      throw e;
    });
};

export const declineSale = (id, processAlias) => (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest());

  const state = getState();
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];
  const additionalTransactions = transaction.attributes.metadata.addonsTransactions;
  let transactionResponse = null;
  const particularParams = {
    transitionType: 'decline',
    isCommonTransition: true,
    transaction,
  };
  return sdk.jh.transactions
    .transition(
      {
        processAlias: processAlias ? processAlias : config.masterProcessAlias,
        id,
        transition: TRANSITION_DECLINE,
        particularParams,
        params: {},
      },
      { expand: true, include: ['customer', 'provider', 'listing'] }
    )
    .then(async response => {
      sendNotification({
        userId: currentUser.id.uuid,
        transactionId: id,
        transition: TRANSITION_DECLINE,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });
      if (additionalTransactions && additionalTransactions.length > 0) {
        for (let i = 0; i < additionalTransactions.length; i++) {
          const bodyParams = {
            id: additionalTransactions[i].transactionId,
            transition: TRANSITION_ADDITIONAL_DECLINE,
            params: {},
          };

          await sdk.transactions.transition(bodyParams, { expand: true });
        }
      }
      transactionResponse = response;
      return cancelTransaction(id.uuid);
    })
    .then(promoResponse => {
      dispatch(addMarketplaceEntities(transactionResponse));
      dispatch(declineSaleSuccess());
      dispatch(fetchCurrentUserNotifications());

      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Booking request declined!',
      });

      const listingId = listingRelationship(transactionResponse).id;
      const entities = updatedEntities({}, transactionResponse.data);
      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);

      // const smsData = getSmsData(denormalised[1]);
      // sendEventGeneral({
      //   eventType: BOOKING_REQUEST_DECLINED,
      //   transactionUUID: id.uuid,
      //   ...smsData,
      // });
      return transactionResponse;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      throw e;
    });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...IMAGE_VARIANTS,
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());
  const state = getState();
  const transactionRef = state.TransactionPage.transactionRef;
  const transaction = getMarketplaceEntities(state, transactionRef ? [transactionRef] : [])[0];
  const { currentUser } = state.user;
  const isHost = currentUser.id.uuid === transaction.provider.id.uuid;
  const targetId = isHost ? transaction.customer.id.uuid : transaction.provider.id.uuid;
  const senderName = currentUser.attributes.profile.displayName;

  if (!txHasBeenAccepted(transaction) && doesMessageContainPhoneNumberOrEmail(message)) {
    sendTransactionMsgContainingPhoneNoOrEmailNoti({
      transactionId: txId.uuid,
      customerName: transaction.customer.attributes.profile.displayName,
      providerName: transaction.provider.attributes.profile.displayName,
      senderRole: isHost ? 'provider' : 'customer',
      message,
    });
    message = encodeMsgContainingPhoneNoOrEmailMaybe(message);
  }

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // sendEventGeneral({
      //   eventType: USER_SEND_MESSAGE,
      //   transactionUUID: txId.uuid,
      //   senderName,
      //   targetId,
      //   isHost,
      //   message,
      // });
      sendNotification({
        userId: currentUser.id.uuid,
        messageId: messageId.uuid,
        transactionId: txId,
        transition: USER_SEND_MESSAGE,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
      });

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
const IMAGE_VARIANTS = {
  'fields.image': [
    // Profile images
    'variants.square-small',
    'variants.square-small2x',

    // Listing images:
    'variants.landscape-crop',
    'variants.landscape-crop2x',
  ],
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (tx, params, role, dispatch, sdk) => {
  const { id } = tx;
  const processName = get(tx, 'attributes.processName');
  let transition = getReview2Transition(role === CUSTOMER);
  if (processName === PROCESS_NAME_LTR_LAST) {
    transition = getReview2TransitionLTL(role === CUSTOMER);
  }

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (tx, params, role, dispatch, sdk) => {
  const { id } = tx;
  const processName = get(tx, 'attributes.processName');
  let transition = getReview1Transition(role === CUSTOMER);
  if (processName === PROCESS_NAME_LTR_LAST) {
    transition = getReview1TransitionLTL(role === CUSTOMER);
  }
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...IMAGE_VARIANTS })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(storableError(e))) {
        return sendReviewAsSecond(tx, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const txStateOtherPartyFirst =
    txIsInFirstReviewBy(tx, role !== CUSTOMER) || txIsInFirstReviewByLTL(tx, role !== CUSTOMER);

  dispatch(sendReviewRequest());

  return txStateOtherPartyFirst
    ? sendReviewAsSecond(tx, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx, params, role, dispatch, sdk);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

const createParamsForFetchTimeSlots = (maxTimeSlots, bookingRange, listingId, tz) => {
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);
  let nextBoundary = findNextBookingTimeBoundary(tz, new Date());
  let nextMonth = null;
  let count = 0;
  const params = [];
  do {
    const numberOfSlot = Math.min(timeSlotsRange, bookingRange - timeSlotsRange * count);
    nextBoundary = findStartBoundary(nextMonth || nextBoundary, tz);
    nextMonth = findEndBoundary(nextBoundary, tz, numberOfSlot);
    params.push({
      start: nextBoundary,
      end: nextMonth,
      listingId,
    });
    count++;
  } while (timeSlotsRange * count < bookingRange);
  return params;
};

// Helper function for fetchTransaction call.
export const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const initiatePaymentFuel = params => (dispatch, getState, sdk) => {
  const queryParams = {
    expand: true,
  };

  return sdk.jh.transactions
    .initiateSpeculative(params, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const tx = entities[0];
      // dispatch(speculateTransactionSuccess(tx));
      return response;
    })
    .catch(e => {
      const { listingId, bookingStart, bookingEnd } = params;
      log.error(e, 'speculate-transaction-failed', {
        listingId,
        bookingStart,
        bookingEnd,
      });
      return dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const handlePaymentFuelAfterFail = (params = {}) => async (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());
  try {
    const { currentTransaction, dropOffOdometer, distanceTravelled, listingId } = params || {};

    const isPaidFuelInclusion = get(currentTransaction, 'attributes.metadata.isPaidFuelInclusion');
    const errorFuelInclusion = get(currentTransaction, 'attributes.metadata.errorFuelInclusion');

    const state = getState();
    const currentUser = state.user.currentUser;

    await handlePaymentFuel({ currentTransaction, distanceTravelled, currentUser, listingId, sdk });

    if (!isPaidFuelInclusion && errorFuelInclusion && currentTransaction) {
      // protectedData.dropOffOdometer = dropOffOdometer;
    }
    dispatch(sendMessageSuccess());
  } catch (e) {
    dispatch(sendMessageError(storableError(e)));
  }
};

const handlePaymentFuel = async ({
  currentTransaction,
  distanceTravelled,
  currentUser,
  listingId,
  sdk,
}) => {
  const pickUpOdometer = get(currentTransaction, 'attributes.protectedData.pickUpOdometer', 0);
  const currentTransactionId = get(currentTransaction, 'id.uuid');
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const currentUserId = ensuredCurrentUser.id && ensuredCurrentUser.id.uuid;
  const paymentMethod = get(
    ensuredCurrentUser,
    'stripeCustomer.defaultPaymentMethod.attributes.stripePaymentMethodId'
  );
  const stripe = window.Stripe(config.stripe.publishableKey);
  // let mileageOfTravel = (dropOffOdometer || 0) - pickUpOdometer;
  // mileageOfTravel = mileageOfTravel > 0 ? mileageOfTravel : 0;

  const bodyParams = {
    processAlias: bookingProcessAliasFuelCharging,
    transition: TRANSITION_FUEL_CHARGING_REQUEST,
    currentPage: 'TripPage',

    params: {
      cardToken: 'Fuel_speculative_card_token',
      isFuelIncluded: true,
      listingId,
    },
  };

  const queryParams = {
    expand: true,
  };
  const transactionResponse = await sdk.jh.transactions.initiateSpeculative(
    bodyParams,
    queryParams
  );

  let [fuelChargingTransaction] = denormalisedResponseEntities(transactionResponse);
  const fuelChargingId = fuelChargingTransaction.id.uuid;
  const hasPaymentIntents =
    fuelChargingTransaction.attributes.protectedData &&
    fuelChargingTransaction.attributes.protectedData.stripePaymentIntents;

  if (!hasPaymentIntents) {
    throw new Error(
      `Missing StripePaymentIntents key in fuelChargingTransaction's protectedData. Check that your fuelChargingTransaction process is configured to use payment intents.`
    );
  }
  const { stripePaymentIntentClientSecret } = hasPaymentIntents
    ? fuelChargingTransaction.attributes.protectedData.stripePaymentIntents.default
    : null;

  const args = paymentMethod
    ? [stripePaymentIntentClientSecret, { payment_method: paymentMethod }]
    : [stripePaymentIntentClientSecret, null];

  const responseCard = await stripe.handleCardPayment(...args);

  if (responseCard.error) {
    return Promise.reject(responseCard);
  } else {
    fuelChargingTransaction = await handleConfirmPaymentTransition({
      sdk,
      currentTransactionId,
      fuelChargingId,
      currentUserId,
    });
    return fuelChargingTransaction;
  }
};

const handleConfirmPaymentTransition = async ({
  sdk,
  currentTransactionId,
  fuelChargingId,
  currentUserId,
}) => {
  const confirmParams = {
    id: fuelChargingId,
    transition: TRANSITION_FUEL_CHARGING_CONFIRM_PAYMENT,
    params: {},
  };

  if (!currentUserId || !currentTransactionId || !fuelChargingId) {
    throw new Error(`Missing current user or current transaction`);
  }

  const response = await sdk.transactions.transition(confirmParams, {
    include: ['booking', 'provider'],
    expand: true,
  });
  const [transaction] = denormalisedResponseEntities(response);
  const updateFuelChargingURL = `transactions/${currentTransactionId}/${fuelChargingId}/update-fuel-charging`;
  await drivelahApiPut(updateFuelChargingURL);
  return transaction;
};

export const addFuelInclusionAndSaveCard = params => async (dispatch, getState, sdk) => {
  try {
    const { card, billingDetails, ensuredStripeCustomer, stripe, transactionId } = params;

    dispatch(sendMessageRequest());
    if (stripe && card) {
      const paymentMethodRes = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: billingDetails,
      });
      const payment_method = paymentMethodRes.paymentMethod.id;
      dispatch(savePaymentMethod(ensuredStripeCustomer, payment_method));
    }

    const url = 'transactions/add-fuel-inclusion';
    const bodyData = { transactionId };
    await drivelahApiPost(url, bodyData);
    dispatch(sendMessageSuccess());
  } catch (error) {
    dispatch(sendMessageError(storableError(error)));
  }
};

export const estimateFuelBreakdown = params => (dispatch, getState, sdk) => {
  const {
    odometerStartDataState,
    odometerEndDataState,
    listingId,
    dlGoTripDistance,
    fuelPrice,
  } = params;

  const bodyParams = {
    processAlias: bookingProcessAliasFuelCharging,
    transition: TRANSITION_FUEL_CHARGING_REQUEST,
    currentPage: 'TripPage',

    params: {
      cardToken: 'Fuel_speculative_card_token',
      isFuelIncluded: true,
      fuelPrice,
      listingId,
      protectedData: {
        odometerStart: odometerStartDataState,
        odometerEnd: odometerEndDataState,
        dlGoTripDistance,
      },
    },
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  dispatch({ type: ESTIMATE_BREAKDOWN_REQUEST });

  return sdk.jh.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(response => {
      const [tx] = denormalisedResponseEntities(response);
      if (!tx) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }

      dispatch({ type: ESTIMATE_BREAKDOWN_SUCCESS, payload: tx });
      return tx;
    })
    .catch(e => {
      dispatch({ type: ESTIMATE_BREAKDOWN_ERROR, payload: storableError(e) });
      throw e;
    });
};

export const initiateOrder = (
  orderParams,
  odometerStartValue,
  odometerEndValue,
  dlGoTripDistance,
  transactionId
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  const {
    transition,
    parentTransactionId,
    isDelivery,
    isExcessReduction,
    isFuelInclusion,
    addonsTransactions,
    listingId,
    fuelPrice,
    ...rest
  } = orderParams;
  const { bookingStart, bookingEnd } = orderParams;

  const bodyParams = {
    processAlias: bookingProcessAliasFuelCharging,
    transition: TRANSITION_FUEL_CHARGING_REQUEST,
    currentPage: 'TripPage',

    params: {
      ...rest,
      fuelPrice,
      isFuelIncluded: true,
      bookingStart: moment(bookingStart).toDate(),
      bookingEnd: moment(bookingEnd).toDate(),
      listingId,
      protectedData: {
        parentTransactionId,
        odometerStart: odometerStartValue,
        odometerEnd: odometerEndValue,
        dlGoTripDistance,
      },
    },
  };

  const queryParams = {
    expand: true,
  };

  const createOrder = sdk.jh.transactions.initiate;

  let manualErorr = null;
  let order = null;

  return sdk.currentUser
    .show()
    .then(user => {
      return user;
    })
    .then(user => {
      return createOrder(bodyParams, queryParams);
    })
    .then(orderData => {
      return orderData;
    })
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      order = entities[0];

      await dispatch(
        updateParentTransaction(parentTransactionId, {
          fuelChildTransactionId: order.id.uuid,
        })
      );

      return null;
    })
    .then(depositResponse => {
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    })
    .catch(e => {
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        bookingStart: orderParams.bookingStart,
        bookingEnd: orderParams.bookingEnd,
      });
      const resultError = e && e.error ? e.error : e;
      dispatch(initiateOrderError(manualErorr ? manualErorr : storableError(resultError)));
      throw e;
    });
};

export const confirmPayment = ({
  savedListing,
  order: orderFromParams,
  parentTransactionId,
  ...orderParams
}) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());
  const listing = getState().CheckoutPage.listing ? getState().CheckoutPage.listing : savedListing;
  const { publicData = {} } = ensureListing(listing).attributes;
  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_FUEL_CHARGING_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .show({
      id: parentTransactionId,
    })
    .then(res => {
      const lastTransition = res.data.data.attributes.lastTransition;

      return sdk.transactions.transition(bodyParams, { expand: true });
    })
    .then(response => {
      const order = denormalisedResponseEntities(response)[0]; // response.data.data;
      dispatch(confirmPaymentSuccess(order.id));

      if (publicData.instantBooking) {
        captureTransaction(orderParams.transactionId.uuid);
      }

      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const fetchAcceptDeclineFuelPayment = param => (dispatch, getState, sdk) => {
  const { transactionId, transition } = param;

  if (transition === TRANSITION_FUEL_CHARGING_ACCEPT) {
    dispatch(acceptSaleRequest());
  }
  if (transition === TRANSITION_FUEL_CHARGING_DECLINE) {
    dispatch(declineSaleRequest());
  }

  return sdk.jh.transactions
    .transition(
      {
        processAlias: bookingProcessAliasFuelCharging,
        id: transactionId,
        transition,
        params: {},
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserNotifications());

      return response;
    })
    .catch(e => {
      if (transition === TRANSITION_FUEL_CHARGING_ACCEPT) {
        dispatch(acceptSaleError(storableError(e)));
      }
      if (transition === TRANSITION_FUEL_CHARGING_DECLINE) {
        dispatch(declineSaleError(storableError(e)));
      }

      log.error(e, 'reject-sale-failed', {
        txId: transactionId,
        transition,
      });
      throw e;
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]);
};

export const uploadInteriorPhotoToMetadata = (id, photoObjects = null) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(uploadInteriorPhotoRequest());
  return fetch(apiUrl + '/api/update-transaction-metadata', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId: id.uuid,
      photoObjects,
    }),
  })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then(() => {
      dispatch(uploadInteriorPhotoSuccess());
    })
    .catch(() => {
      dispatch(uploadInteriorPhotoError());
    });
};

export const fetchFuelChildTransaction = id => async (dispatch, getState, sdk) => {
  dispatch(fuelTransactionRequest());
  try {
    const response = await sdk.transactions.show({ id });
    dispatch(addMarketplaceEntities(response));
    dispatch(fuelTransactionSuccess());
  } catch (e) {
    dispatch(fuelTransactionFail());
  }
};

export const getDlGoTripDistance = txId => dispatch => {
  dispatch(dlGoTripDistanceRequest());
  return fetch(`${apiUrl}/api/partner/grab/fueldistance`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId: txId,
    }),
  })
    .then(response => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then(json => {
      const distance = json.data ? json.data.distanceTravelled : 0;
      dispatch(dlGoTripDistanceSuccess(distance));
      return json;
    })
    .catch(e => {
      dispatch(dlGoTripDistanceError());
      throw e;
    });
};

export const fuelNotificationWithoutCharge = txId => dispatch => {
  return fetch(apiUrl + '/api/events/receive', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId: txId,
      eventType: FUEL_NOTIFICATION_WITHOUT_CHARGE,
    }),
  });
};

export const fuelNotificationFor500 = txId => dispatch => {
  return fetch(`${apiUrl}/api/events/receive`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transactionId: txId,
      eventType: FUEL_NOTIFICATION_FOR_500,
    }),
  });
};

export const updateListingOdometerData = (lId, val) => (dispatch, getState, sdk) => {
  return fetch(`${apiUrl}/api/listings/${lId}/update-odometer-data`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      val,
    }),
  });
};

export const getActiveTrips = params => (dispatch, getState, sdk) => {
  const queryParams = {
    lastTransitions: ACTIVE_TRIPS_TRANSITIONS,
    only: 'order',
    include: [
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
      'listing',
      'listing.images',
      'reviews',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'processName',
      'lineItems',
      'protectedData',
      'metadata',
    ],
    'fields.user': [
      'profile.displayName',
      'profile.abbreviatedName',
      'profile.publicData',
      'profile.protectedData',
    ],
    'fields.image': [
      'variants.square-small',
      'variants.square-small2x',
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
    page: params.page,
    per_page: 10,
  };

  return sdk.transactions
    .query(queryParams)
    .then(response => {
      let res = [];
      for (let tx of response.data.data) {
        if (
          !ExcludeProcessNames.some(txProcessName =>
            txProcessName.includes(tx.attributes.processName)
          )
        ) {
          res.push(tx);
        }
      }

      response.data.data = res;
      const entities = denormalisedResponseEntities(response);
      // console.log('entities', entities);
      // console.log('entities', response.data.meta);
      return {
        trips: entities,
        meta: response.data.meta,
      };
    })
    .catch(e => {
      console.trace(e);
    });
};

export const updateTransactionVerification = params => (dispatch, getState, sdk) => {
  console.log('params',params)
  sdk.transactions
    .transition(
      {
        id: params.transactionId,
        transition: params.transition,
        params: {},
      },
      {
        expand: true,
        include: ['booking', 'listing'],
      }
    )
    .then(res => {
      log('updateTransition res = ', res);
      return res;
    })
    .catch(err => {
      return err;
    });
};
