import Decimal from 'decimal.js';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_TRIP_PRICE_PEAK_DAYS, LINE_ITEMS, propTypes } from '../../util/types';
import css from './BookingBreakdown.css';
import Explanation from './Explaination';


function getUnique(arr, comp) {
  const unique = arr
    .map(e => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e])
    .map(e => arr[e]);

  return unique;
}

const LineItemUnknownItemsMaybe = memo(
  props => {
    const { transaction, intl } = props;

    
    const rawItems = transaction?.attributes.lineItems.filter(
      item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
    );

    
    const items = getUnique(rawItems, 'code');

    if (!items) return null;

    const { hourlyBooking } = transaction.attributes.protectedData || {};

    const getUnitLabel = (quantity, type) => {
      const unitValue = parseFloat(quantity);
      switch (type) {
        case 'hours':
          return unitValue === 1 ? 'Hour' : 'Hours';
        case 'kms':
          return unitValue === 1 ? 'Km' : 'Kms';
        case 'days':
          return unitValue === 1 ? 'Day' : 'Days';
        default:
          return type.charAt(0).toUpperCase() + type.slice(1);
      }
    };

    const baseUnit = hourlyBooking
      ? 'hours'
      : transaction.attributes.processName === 'fuel-charging'
        ? 'kms'
        : 'days';

        return (
          items.length > 0 ? (
            <React.Fragment>
              {items.map((item, i) => {
                const label = humanizeLineItemCode(item.code);
                const formattedTotal = formatMoney(intl, item.lineTotal, 1);
                const hasQuantity = !!item.quantity;
                const unitQuantity = hasQuantity
                  ? item.quantity instanceof Decimal
                    ? item.quantity.toString()
                    : item.quantity
                  : 0;
        
                const unitLabel = getUnitLabel(unitQuantity, baseUnit);
        
                const explanationMaybe =
                  item.code === LINE_ITEM_TRIP_PRICE_PEAK_DAYS ? (
                    <Explanation text="Peak day pricing is for select high demand days set by the host." />
                  ) : null;
        
                return (
                  <div key={`${i}-item.code`} className={css.lineItem}>
                    <span className={css.itemLabel}>
                      {label}
                      {explanationMaybe}{' '}
                      {hasQuantity ? (
                        <FormattedMessage
                          id={`LineItemUnknownItemsMaybe.${unitLabel}.quantity`}
                          values={{
                            unitPrice: formatMoney(intl, item.unitPrice, 1),
                            quantity: unitQuantity,
                          }}
                          defaultMessage={`${formatMoney(intl, item.unitPrice, 1)} (x ${unitQuantity} ${unitLabel})`}
                        />
                      ) : null}
                    </span>
                    <span className={css.itemValue}>{formattedTotal}</span>
                  </div>
                );
              })}
            </React.Fragment>
          ) : null
        );
  },
  (prev, next) => isEqual(prev.transaction, next.transaction)
);

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;