import { types as sdkTypes } from '../util/sdkLoader';
import get from 'lodash/get';
import config from '../config';
import { fetchAddressFromLatLongBackendCache } from './locationCache';
const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

const placeOrigin = place => {
  if (place && place.geometry && place.geometry.location) {
    return new SDKLatLng(place.geometry.location.lat(), place.geometry.location.lng());
  }
  return null;
};

const PLACE_TYPE_BOUNDS_DISTANCES = {
  address: 500,
  country: 2000,
  region: 2000,
  postcode: 2000,
  district: 2000,
  place: 2000,
  locality: 2000,
  neighborhood: 2000,
  poi: 2000,
  'poi.landmark': 2000,
};

const GENERATED_BOUNDS_DEFAULT_DISTANCE = 5000; // meters


const placeBounds = place => {
  if (place && place.geometry && place.geometry.viewport) {
    const ne = place.geometry.viewport.getNorthEast();
    const sw = place.geometry.viewport.getSouthWest();
    return new SDKLatLngBounds(
      new SDKLatLng(ne.lat(), ne.lng()),
      new SDKLatLng(sw.lat(), sw.lng())
    );
  }
  return null;
};

export const locationRadiusBounds = (latlng, distance) => {
  const bounds = new window.google.maps.Circle({
    center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
    radius: distance,
  }).getBounds();

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
};


const placeRadiusBounds = (prediction, experiment_value='') => {
  let boundDistance = 0;
  if (prediction) {
      if(experiment_value) {
        if(experiment_value === 'ATS-A') {
          boundDistance = 2500;
        }
        if(experiment_value === 'ATS-B') {
          boundDistance = 5000;
        }
        if(experiment_value === 'ATS-D') {
          boundDistance = 5000;
        }
      }
      const distance = boundDistance ? boundDistance: GENERATED_BOUNDS_DEFAULT_DISTANCE;
      return locationRadiusBounds(placeOrigin(prediction), distance);
  }
  return null;
};


/**
 * Get a detailed place object
 *
 * @param {String} placeId - ID for a place received from the
 * autocomplete service
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 *
 * @return {Promise<util.propTypes.place>} Promise that
 * resolves to the detailed place, rejects if the request failed
 */
export const getPlaceDetails = (placeId, sessionToken, experiment_value) =>
  new Promise((resolve, reject) => {
    const serviceStatus = window.google.maps.places.PlacesServiceStatus;
    const el = document.createElement('div');
    const service = new window.google.maps.places.PlacesService(el);
    const fields = ['address_component', 'formatted_address', 'geometry', 'place_id'];
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    service.getDetails({ placeId, fields, ...sessionTokenMaybe }, (place, status) => {
      if (status !== serviceStatus.OK) {
        reject(
          new Error(`Could not get details for place id "${placeId}", error status was "${status}"`)
        );
      } else {
        let bounds = placeRadiusBounds(place, experiment_value)
        resolve({
          address: place.formatted_address,
          origin: placeOrigin(place),
          bounds:  bounds,
          address_components: place.address_components || [],
        });
      }
    });
  });

const predictionSuccessful = status => {
  const { OK, ZERO_RESULTS } = window.google.maps.places.PlacesServiceStatus;
  return status === OK || status === ZERO_RESULTS;
};

/**
 * Get place predictions for the given search
 *
 * @param {String} search - place name or address to search
 * @param {String} sessionToken - token to tie different autocomplete character searches together
 * with getPlaceDetails call
 * @param {Object} searchConfigurations - defines the search configurations that can be used with
 * the autocomplete service. Used to restrict search to specific country (or countries).
 *
 * @return {Promise<{ search, predictions[] }>} - Promise of an object
 * with the original search query and an array of
 * `google.maps.places.AutocompletePrediction` objects
 */
export const getPlacePredictions = (search, sessionToken, searchConfigurations) =>
  new Promise((resolve, reject) => {
    const service = new window.google.maps.places.AutocompleteService();
    const sessionTokenMaybe = sessionToken ? { sessionToken } : {};

    service.getPlacePredictions(
      { input: search, ...sessionTokenMaybe, ...searchConfigurations },
      (predictions, status) => {
        if (!predictionSuccessful(status)) {
          reject(new Error(`Prediction service status not OK: ${status}`));
        } else {
          const results = {
            search,
            predictions: predictions || [],
          };
          resolve(results);
        }
      }
    );
  });

/**
 * Deprecation: use function from src/util/maps.js
 * Cut some precision from bounds coordinates to tackle subtle map movements
 * when map is moved manually
 *
 * @param {LatLngBounds} sdkBounds - bounds to be changed to fixed precision
 * @param {Number} fixedPrecision - integer to be used on tofixed() change.
 *
 * @return {SDKLatLngBounds} - bounds cut to given fixed precision
 */
export const sdkBoundsToFixedCoordinates = (sdkBounds, fixedPrecision) => {
  const fixed = n => Number.parseFloat(n.toFixed(fixedPrecision));
  const ne = new SDKLatLng(fixed(sdkBounds.ne.lat), fixed(sdkBounds.ne.lng));
  const sw = new SDKLatLng(fixed(sdkBounds.sw.lat), fixed(sdkBounds.sw.lng));

  return new SDKLatLngBounds(ne, sw);
};

/**
 * Deprecation: use function from src/util/maps.js
 * Check if given bounds object have the same coordinates
 *
 * @param {LatLngBounds} sdkBounds1 - bounds #1 to be compared
 * @param {LatLngBounds} sdkBounds2 - bounds #2 to be compared
 *
 * @return {boolean} - true if bounds are the same
 */
export const hasSameSDKBounds = (sdkBounds1, sdkBounds2) => {
  if (!(sdkBounds1 instanceof SDKLatLngBounds) || !(sdkBounds2 instanceof SDKLatLngBounds)) {
    return false;
  }
  return (
    sdkBounds1.ne.lat === sdkBounds2.ne.lat &&
    sdkBounds1.ne.lng === sdkBounds2.ne.lng &&
    sdkBounds1.sw.lat === sdkBounds2.sw.lat &&
    sdkBounds1.sw.lng === sdkBounds2.sw.lng
  );
};

/*
 * Calculate a bounding box in the given location
 *
 * @param {latlng} center - center of the bounding box
 * @param {distance} distance - distance in meters from the center to
 * the sides of the bounding box
 *
 * @return {LatLngBounds} bounding box around the given location
 *
 */
export const locationBounds = (latlng, distance) => {
  const bounds = new window.google.maps.Circle({
    center: new window.google.maps.LatLng(latlng.lat, latlng.lng),
    radius: distance,
  }).getBounds();

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return new SDKLatLngBounds(new SDKLatLng(ne.lat(), ne.lng()), new SDKLatLng(sw.lat(), sw.lng()));
};


/**
 * Get the formatted address for the given latitude and longitude using the Google Maps Geocoding API.
 *
 * @param {number} latitude - The latitude of the location.
 * @param {number} longitude - The longitude of the location.
 * @param {string} [language=en] - The language code for the language in which to return the results.
 * @returns {Promise<Object>} - A promise that resolves with an object containing the formatted address components.
 */

export const getAddressDetailsByLatLong = async (latitude, longitude, language = 'en') => {
  try {
    const cacheKey = `address:${latitude},${longitude}`;

    console.log(`🔍 Fetching address details for lat: ${latitude}, lng: ${longitude}`);

    // ✅ Step 2: Check Backend Cache (New API)
    const backendCache = await fetchAddressFromLatLongBackendCache(latitude, longitude, language);
    if (backendCache) {
      console.log(`✅ Cache hit from Backend for ${cacheKey}`);
      return backendCache;
    }

    console.warn(`⚠️ Cache miss: Fetching address from Google API for ${latitude}, ${longitude}`);

    // ✅ Step 3: Call Google API if cache is missing
    const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${language}&key=${config.maps.googleMapsAPIKey}`;
    const googleResponse = await fetch(googleUrl);
    const googleData = await googleResponse.json();

    if (googleData.status !== 'OK' || !googleData.results.length) {
      return Promise.reject('Unable to retrieve address information.');
    }

    // ✅ Step 4: Format Address Data
    const addressComponents = get(googleData.results[0], 'address_components', []);
    const address = get(googleData.results[0], 'formatted_address');
    if (!address) return null;

    const JSONformattedAddress = {
      address,
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      suburb: '',
    };

    addressComponents.forEach(component => {
      if (component.types.includes('street_number')) {
        JSONformattedAddress.streetNumber = component.long_name;
      } else if (component.types.includes('route')) {
        JSONformattedAddress.streetName = component.long_name;
      } else if (component.types.includes('administrative_area_level_2') || component.types.includes('colloquial_area')) {
        JSONformattedAddress.city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        JSONformattedAddress.state = component.long_name;
      } else if (component.types.includes('postal_code')) {
        JSONformattedAddress.postalCode = component.long_name;
      } else if (component.types.includes('country')) {
        JSONformattedAddress.country = component.long_name;
      } else if (component.types.includes('locality')) {
        JSONformattedAddress.suburb = component.long_name;
      }
    });

    JSONformattedAddress.formattedAddress = {
      selectedPlace: {
        address,
        origin: {
          lat: latitude,
          lng: longitude,
        },
        postalCode: JSONformattedAddress.postalCode || '',
        suburb: JSONformattedAddress.suburb || '',
        city: JSONformattedAddress.city || '',
      },
    };

    console.log(`✅ Successfully fetched and formatted address data:`, JSONformattedAddress);

    return JSONformattedAddress;
  } catch (error) {
    console.error(`❌ Error fetching address details:`, error);
    return Promise.reject('An error occurred while retrieving address information.');
  }
};

/**
 * Get the latitude and longitude for the user's current location using the browser's geolocation API.
 * Then get the formatted address for the user's location using the Google Maps Geocoding API and return it as a promise.
 *
 * @returns {Promise<string>} - A promise that resolves with the formatted address for the user's current location.
 */
export const getAddressDetailsByBrowser = () => {
  // Check if navigator is available
  if (typeof navigator === 'undefined' || typeof navigator.geolocation === 'undefined') {
    // If it is not, log an error message to the console and reject the promise
    console.log('Geolocation is not available in this browser.');
    return Promise.reject('Geolocation is not available in this browser.');
  }

  // If navigator is available, return a promise that resolves with the formatted address for the user's location
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // Call getAddressDetailsByLatLong and resolve with the result
        getAddressDetailsByLatLong(latitude, longitude)
          .then((address) => {
            resolve(address);
          })
          .catch((error) => {
            reject(error);
          });
      },
      (error) => {
        // Handle errors from getCurrentPosition
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log('User denied the request for Geolocation.');
            reject('User denied the request for Geolocation.');
            break;
          case error.POSITION_UNAVAILABLE:
            console.log('Location information is unavailable.');
            reject('Location information is unavailable.');
            break;
          case error.TIMEOUT:
            console.log('The request to get user location timed out.');
            reject('The request to get user location timed out.');
            break;
          default:
            console.log('An unknown error occurred while getting user location.');
            reject('An unknown error occurred while getting user location.');
        }
      }
    );
  });
};
