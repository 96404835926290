import axios from 'axios';

export const getIndentityPersonData = async (code, flexUserId) => {
    try {
      const clientResponse = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL
        }api/singpass/getIndentityPersonData`,
        {
          authCode: code,
          flexUserId: flexUserId,
        },
      );
      return clientResponse;
    } catch (error) {
      return error;
    }
  };

  export const clearSingPassData = async (userId) => {
    
    
    try {
      const clientResponse = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/api/singpass/clear-singpass-data`,
        {
          flexUserId: userId
        }
      );
      return clientResponse;
    } catch (error) {
      return error;
    }
  };