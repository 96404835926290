import React from 'react';
import { number, object, shape, string } from 'prop-types';
import { Circle, GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import config from '../../config';
import css from "./StartTripMap.css";
import { mapConfigStyle } from "./mapConfig"
import noProfileImage from "../../assets/newPickupAndDropoff/no-profile-image.png"

/**
 * DynamicGoogleMap uses withGoogleMap HOC.
 * It handles some of the google map initialization states.
 */

const StartTripDynamicGoogleMap = withGoogleMap(props => {
  const { center, zoom, address, mapsConfig, maxZoom = null, dynamicCenter = null, dynamicMarkerIconURL = null, dynamicMarkerEnabled = null, distanceFromPickUp = null, isPickUp} = props;

  const { enabled, url, anchorX, anchorY, width, height } = mapsConfig.customMarker;
  const [dynamicMarkerDataURL, setDynamicMarkerDataURL] = React.useState(dynamicMarkerIconURL || noProfileImage);
  const mapRef = React.useRef(null);
  const [flag, setFlag] = React.useState(true);

  React.useEffect(() => {
    let isMounted = true;

    if (dynamicMarkerIconURL && dynamicMarkerEnabled) {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = dynamicMarkerIconURL || noProfileImage
      image.onload = () => {
        if (isMounted) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const diameter = Math.min(image.width, image.height);
          const borderWidth = 15;
          const borderColor = '#0aa3ad';
          canvas.width = diameter + borderWidth * 2;
          canvas.height = diameter + borderWidth * 2;

          // Draw the border
          ctx.beginPath();
          ctx.arc(
            (canvas.width / 2),
            (canvas.height / 2),
            (diameter + borderWidth) / 2,
            0,
            2 * Math.PI
          );
          ctx.lineWidth = borderWidth;
          ctx.strokeStyle = borderColor;
          ctx.stroke();

          // Clip the canvas to the circular area
          ctx.beginPath();
          ctx.arc(
            canvas.width / 2,
            canvas.height / 2,
            diameter / 2,
            0,
            2 * Math.PI
          );
          ctx.clip();

          // Draw the image within the circular clipping path
          ctx.drawImage(
            image,
            (canvas.width - diameter) / 2,
            (canvas.height - diameter) / 2,
            diameter,
            diameter
          );

          // Convert canvas to data URL
          const dataURL = canvas.toDataURL('image/png');
          setDynamicMarkerDataURL(dataURL);
        }
      };

      // Cleanup function to set the flag to false
      return () => {
        isMounted = false;
      };


    }
  }, [dynamicMarkerIconURL]);


  const markerIcon = enabled
    ? {
        icon: {
          url,

          // The origin for this image is (0, 0).
          origin: new window.google.maps.Point(0, 0),
          size: new window.google.maps.Size(width, width),
          // anchor: new window.google.maps.Point(anchorX, anchorY),
          anchor: new window.google.maps.Point(18, 20),
          scaledSize: new window.google.maps.Size(width, width)
        },
      }
    : {};


  const marker = <Marker position={center} {...markerIcon} title={address} key={'marker'} />;

  function getMessage(distanceFromPickUp, isPickUp) {
    if (distanceFromPickUp && distanceFromPickUp.distance <= 250){
        return isPickUp
             ? "You're close to the car! The pick-up process will be available 15 minutes before your scheduled pick-up time."
            : 'Please park the car and begin the end trip process.';
    } else {
        return isPickUp
            ? 'You need to be within 250m of the car to start the pick-up process. It will open 15 minutes before your scheduled pick-up time.'
            : 'Please head to drop off address to start end trip process.';
    }
  }

  const message = getMessage(distanceFromPickUp, isPickUp);
console.log(distanceFromPickUp, "distanceFromPickUp")
  const dynamiCustomerMarker = () => {
    return (
      <MarkerWithLabel
        position={dynamicCenter}
        labelAnchor={new window.google.maps.Point(18, 20)}
        icon={{
          url: dynamicMarkerDataURL,
          scaledSize: new window.google.maps.Size(width, width),
          size: new window.google.maps.Size(width, width),

        }}
        labelClass={css.customLabel}
      >
        <>
        <div className={css.arrowTop}></div>
        <div className={css.arrowDown}></div>
        <span className={css.customLabelWithText}>
          {message}
        </span>
        </>

      </MarkerWithLabel>
    )
  }


const [hasBoundsBeenSet, setHasBoundsBeenSet] = React.useState(false);

React.useEffect(() => {
  if (mapRef.current && mapRef.current instanceof window.google.maps.Map && dynamicCenter.lng && dynamicCenter.lat) {
    const map = mapRef.current;
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(center);
    bounds.extend(dynamicCenter);

    if (!hasBoundsBeenSet && distanceFromPickUp) {
      map.fitBounds(bounds, { padding: 50 });
      console.log("how hard access ?")
      const maxZoom = 15;
      const currentZoom = map.getZoom();
      if (typeof currentZoom === 'number' && currentZoom > maxZoom) {
        map.setZoom(maxZoom);
      }
      setHasBoundsBeenSet(true);
    }
  }
}, [center, hasBoundsBeenSet, dynamicCenter, distanceFromPickUp && distanceFromPickUp.withinRange]);

React.useEffect(() => {
  if (distanceFromPickUp) {
    setHasBoundsBeenSet(false);
  }
}, [distanceFromPickUp]);

React.useEffect(() => {
  if (mapRef.current) {
    const map = mapRef.current;

    const onZoomOrDrag = () => {
      setHasBoundsBeenSet(true);
    };

    const zoomListener = window.google.maps.event.addListener(map, 'zoom_changed', onZoomOrDrag);
    const dragListener = window.google.maps.event.addListener(map, 'dragend', onZoomOrDrag);

    return () => {
      window.google.maps.event.removeListener(zoomListener);
      window.google.maps.event.removeListener(dragListener);
    };
  }
}, []);

  const circleProps = {
    options: {
      fillColor: mapsConfig.fuzzy.circleColor,
      fillOpacity: 0.2,
      strokeColor: mapsConfig.fuzzy.circleColor,
      strokeOpacity: 0.5,
      strokeWeight: 1,
      clickable: false,
    },
    radius: 250,
    center: center,
  };

  const circle = <Circle {...circleProps} />;

  const controlPosition =
    typeof window !== 'undefined' && typeof window.google !== 'undefined'
      ? window.google.maps.ControlPosition.LEFT_TOP
      : 2;

      if(maxZoom) {
        return (
          <GoogleMap
          onLoad={(mapInstance) => {
            mapRef.current = mapInstance;
          }}
          defaultZoom={zoom}
          defaultCenter={center}
          center={center}
          options={{
            styles: mapConfigStyle,
             // Disable map type (ie. Satellite etc.)
            mapTypeControl: false,
            // Disable zooming by scrolling
            scrollwheel: true,
            // Fullscreen control toggle
            fullscreenControl: false,
             // Street View control
            streetViewControl: false,
            zoomControl: true,
            maxZoom: maxZoom ,// Zoom control position
            zoomControlOptions: {
              position: controlPosition
            },
          }}
    >
          {mapsConfig.fuzzy.enabled && (
            <>
              {circle}
              {marker}
              {dynamiCustomerMarker()}
            </>
          )}
          {!mapsConfig.fuzzy.enabled && (
            <>
              {marker}
              {dynamiCustomerMarker()}
            </>
          )}
        </GoogleMap>
        );
      }
      else {
        return (
          <GoogleMap
            onLoad={(mapInstance) => {
              mapRef.current = mapInstance;
            }}
            defaultZoom={zoom}
            defaultCenter={center}
            center={center}
            options={{
              styles: mapConfigStyle,
              // Disable map type (ie. Satellite etc.)
              mapTypeControl: false,
              // Disable zooming by scrolling
              scrollwheel: true,
              zoomControl: true,
              // Fullscreen control toggle
              fullscreenControl: false,
              // Street View control
              streetViewControl: false,

              // Zoom control position
              zoomControlOptions: {
                position: controlPosition,
               },
            }}
          >

              {mapsConfig.fuzzy.enabled
              ? <>
                {circle}
                {marker}
                {dynamiCustomerMarker()}
              </>
              : marker}
          </GoogleMap>
        );
      }
});

StartTripDynamicGoogleMap.defaultProps = {
  address: '',
  center: null,
  zoom: config.maps.fuzzy.enabled ? config.maps.fuzzy.defaultZoomLevel : 11,
  mapsConfig: config.maps,
};

StartTripDynamicGoogleMap.propTypes = {
  address: string,
  center: shape({
    lat: number.isRequired,
    lng: number.isRequired,
  }).isRequired,
  zoom: number,
  mapsConfig: object,
};

export default StartTripDynamicGoogleMap;
