import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import directionsIcon from '../../assets/tripPage/directions-solid.svg';

import css from './StartTripPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const StartTripAddressLinkMaybe = props => {
  const { className, rootClassName, location, geolocation, showAddress, isProvider, customAddress, needDirection = true, showExactShuLocation, isPickUp, currentTransaction } = props;

  const { address, building, floorUnit } = (typeof location === 'object' && location !== null) ? location : { address: location || "" };

  const isDelivery =
    (currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.protectedData &&
      currentTransaction.attributes.protectedData.isDelivery) ||
    (currentTransaction &&
      currentTransaction.attributes &&
      currentTransaction.attributes.metadata &&
      currentTransaction.attributes.metadata.isDelivery);
  const customerLocation =
    currentTransaction &&
    currentTransaction.attributes &&
    currentTransaction.attributes.protectedData &&
    currentTransaction.attributes.protectedData.customerLocation &&
    currentTransaction.attributes.protectedData.customerLocation.selectedPlace;

  const customerFullAddress = customerLocation
    ? [customerLocation.floorUnit, customerLocation.address, customerLocation.postalCode]
      .filter(Boolean) // Removes undefined, null, and empty strings
      .join(', ') // Joins with a comma and space for better formatting
    : '';

  const { lat, lng } = geolocation || {};

  const hrefToGoogleMaps = geolocation
    ? `https://maps.google.com/?q=${lat},${lng}`
    : address
    ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
    : null;


  const fullAddress =
    typeof building === 'string' && building.length > 0 ? `${building}, ${address}` : address;

  const buildingAndFloorUnit = (building && floorUnit) ? (
    <span>{building} - {floorUnit}</span>
  ) : null

  const classes = classNames(rootClassName || css.address, className);
  return showAddress && hrefToGoogleMaps ? (
    <div className={classes}>
      {isProvider ? (
        <p className={css.deliveryText}>
          <FormattedMessage id="TransactionPanel.deliveryText" />
        </p>
      ) : null}
      { showExactShuLocation ? (<div
        className={`${css.instructionsWrapper} ${css.displayFlex} ${css.justifyContentBetween} ${css.paddingBottom20}`}>
        {customAddress ? customAddress : (
          <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)}
                        href={hrefToGoogleMaps}>
            {isPickUp ?
              (<h5>Address to locate the car</h5>)
              :
              (<h5>Return the car to location</h5>)
            }
            {isDelivery ? (<span>{customerFullAddress}</span>) : (<span>{address}</span>)}
          </ExternalLink>)}

        {needDirection && <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
          <img src={directionsIcon} alt="direction" className={css.mapImageDirection}/>
        </ExternalLink>}
      </div>) : null}
    </div>
  ) : (
    <div className={classes}>
      <p className={css.deliveryText}>
        <FormattedMessage id="TransactionPanel.locationText" />
      </p>
    </div>
  );
};

export default StartTripAddressLinkMaybe;
