import React, { useState } from 'react';
import { flatten } from 'lodash';
import SectionCarPhotos from './SectionCarPhotos';
import SectionCarPhotosOnlyView from './SectionCarPhotosOnlyView';
import Newcss from '../TransactionPanel/SectionUploadCarStatus.css';
import Feedback from "../../components/PickAndDropPanel/PickAndDropSteps/Feedback.js";
import css from './TripPanel.css';
import tick from '../../assets/tripPage/tick.svg';
import sectioncss from './SectionsCarPhotos.css';

const PickUpSection = props => {
  const {
    shouldShowPickUpSection,
    isCustomer,
    photoSectionList,
    photoSectionListCustomer,
    photoSectionListCustomerDLGO,
    setActivePhotoSection,
    activePhotoSection,
    intl,
    onManageDisableScrolling,
    isProvider,
    isPickUp,
    currentUser,
    setTripPhotos,
    state,
    allTripPhotos,
    inputOdometerInvalid,
    onNoteChange,
    isDrivelahGo,
    isPickedUp,
    isDropOff,
    isPickupRequested,
    interiorPhotoDlGo,
    odometerPhotoDlGo,
    fuelGaugePhotoDlGo,
    shouldshowNewPickUpDropOffSection,
    isLimitedUser,
    transitionAdminMaybe,
    setCleanlinessValue,
    onCheckboxChange,
    adminCheckGuest,
    adminCheckGuestLocation,
    removePhoto,
    isAdminAction
  } = props;

  // For Node.js version 11.0.0 and higer
  //
  const flatTripPhotos =  [
    ...allTripPhotos,
    // ...interiorPhotoDlGo,
    // ...odometerPhotoDlGo,
    // ...fuelGaugePhotoDlGo,
  ];

  console.log(odometerPhotoDlGo, "odometerPhotoDlGo")

  const filterTripPhotos = flatten(flatTripPhotos).filter(i => i.isPickUp);
  const [activeSection, setActiveSection] = useState(false);


  // const filterTripPhotos = [
  //   ...allTripPhotos,
  //   ...interiorPhotoDlGo,
  //   ...odometerPhotoDlGo,
  //   ...fuelGaugePhotoDlGo,
  // ]
  //   .reduce((acc, val) => {
  //     console.log('acc')
  //     console.log(acc)
  //     return acc.concat(val), []
  //   })
  //   .filter(i => i.isPickUp);
  let  photoSectionOnlyViewCustomer = photoSectionListCustomer;

  const isShowUpload = (isLimitedUser && isPickUp && isDropOff) || (!isDropOff && !isPickUp)

  return (
    <div className={css.pickUpPhotoSection}>
      <div className={css.titleContainer}>
        <div className={css.bookingStatusText} onClick={() => {
          shouldShowPickUpSection(false)
          shouldshowNewPickUpDropOffSection(true)}}>
          <span>&#8249; back</span>
        </div>
        <h2 className={css.listingTitle}>
          {isCustomer ? (isLimitedUser ? 'Send pickup as admin' : 'Send pickup request') : 'Confirm pickup request'}
        </h2>
      </div>

      {isLimitedUser && (
        <div className={`${css.securityWrapper} ${css.activeSection}`}>
          <div>
            <div className={css.tickContainerWrapper}>
              <div className={sectioncss.tickContainer}>
                <img src={tick} alt="tick" className={sectioncss.tick} />
              </div>
              <div>
                <h2 className={sectioncss.photoTitle}>Security</h2>
                <p className={sectioncss.photoDescription}>
                  Please check these based on the checks done to ensure the correct guest is picking the car.
                </p>
                <div className={css.securityGuestWrapper}>
                  <input
                    className={css.customStyleCheckbox}
                    id="adminCheckGuest"
                    type="checkbox"
                    disabled={isDropOff}
                    checked={(isDropOff || adminCheckGuest) || (!isPickUp && !isDropOff)}
                    onChange={(e) => onCheckboxChange('adminCheckGuest', e.target.checked)}
                  />
                  <label htmlFor="adminCheckGuest">
                    The identity of the guest has been verified
                  </label>
                  <input
                    className={css.customStyleCheckbox}
                    id="adminCheckGuestLocation"
                    type="checkbox"
                    disabled={isDropOff}
                    checked={(isDropOff || adminCheckGuestLocation) || (!isPickUp && !isDropOff)}
                    onChange={(e) => onCheckboxChange('adminCheckGuestLocation', e.target.checked)}
                  />
                  <label htmlFor="adminCheckGuestLocation">
                    The guest is present at car location for pickup
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {photoSectionList.map(i => {
        const isShowSection =
          (i.data === 'interiorPhotos' || i.data === 'odometerPhotos' || i.data === 'fuelGaugePhotos')
            ? isDrivelahGo
              ? true //show all section in preview section
              : true
            : true;
        return isShowSection ? (
          <SectionCarPhotos
            id={i.id}
            key={i.id}
            setActivePhotoSection={setActivePhotoSection}
            // onClick={e => setActivePhotoSection(e, i.id)}
            holderClassName={
              activePhotoSection[i.id] === true ? css.activeSection : css.inactiveSection
            }
            activeSection={activePhotoSection[i.id] === true}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            isCustomer={isCustomer && !isLimitedUser}
            isProvider={isProvider}
            currentUser={currentUser}
            isPickUp={isPickUp}
            setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
            tripPhotos={state[i.data]}
            existedPhotos={filterTripPhotos}
            inputOdometerInvalid={inputOdometerInvalid}
            title={i.title}
            description={i.description}
            type={i.data}
            inboxLabel={i.inboxLabel}
            inboxPlaceholder={i.inboxPlaceholder}
            onNoteChange={onNoteChange}
            isShowUpload={isShowUpload}
            removePhoto={removePhoto}
            isAdminAction={isAdminAction}
          />
        ) : null;
      })}

      {isProvider && (
        <div className={css.uploadedTitleContainer}>
          <p className={css.uploadedTitle}>UPLOADED BY THE GUEST</p>
        </div>
      )}

      {isProvider &&
        photoSectionOnlyViewCustomer.map(i => {
          const isShowSection =
            i.data === 'interiorPhotos' ||
            i.data === 'odometerPhotos' ||
            i.data === 'fuelGaugePhotos'
              ? isDrivelahGo
                ? (i.data === 'interiorPhotos' && interiorPhotoDlGo && interiorPhotoDlGo.length > 0) ||
                (i.data === 'odometerPhotos' && odometerPhotoDlGo && odometerPhotoDlGo.length > 0) ||
                (i.data === 'fuelGaugePhotos' && fuelGaugePhotoDlGo && fuelGaugePhotoDlGo.length > 0)
                : true
              : true;


          return isShowSection ? (
            <SectionCarPhotosOnlyView
              key={i.id}
              holderClassName={css.activeSection}
              intl={intl}
              onManageDisableScrolling={onManageDisableScrolling}
              isCustomer={isCustomer}
              isProvider={isProvider}
              currentUser={currentUser}
              isPickUp={true}
              setTripPhotos={data => setTripPhotos({ [i.data]: [...data] })}
              tripPhotos={state[i.data]}
              existedPhotos={filterTripPhotos}
              inputOdometerInvalid={inputOdometerInvalid}
              title={i.title}
              description={i.description}
              type={i.data}
              onNoteChange={onNoteChange}
            />
          ) : null;
        })}
      { isLimitedUser && (
        <div className={`${isLimitedUser && css.securityWrapper} ${activeSection && css.activeSection}` }>
          <Feedback
            isLimitedUser={isLimitedUser}
            isPickUp={false}
            currentUser={currentUser}
            isCustomer={isCustomer}
            intl={intl}
            setCleanlinessValue={setCleanlinessValue}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        </div>
      )}
    </div>
  );
};

export default PickUpSection;
