import get from 'lodash/get';
import pick from 'lodash/pick';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { drivelahApiPut } from '../../util/apiHelper';
import {
  findEndBoundary,
  findNextBookingTimeBoundary,
  findNextBoundary,
  findStartBoundary,
  monthIdStringInTimeZone,
  nextMonthFn,
  timestampToDate,
} from '../../util/dates';
import {
  currentUserIsYoungDriver,
  denormalisedResponseEntities,
  listingIsCommercial,
} from '../../util/data';
import { storableError } from '../../util/errors';
import { getPromosValue, simulateCheckoutPromos } from '../../util/lightrail';
import { countDistanceTwoPoints } from '../../util/maps';
import { sendNotification } from '../../util/notification';
import { types as sdkTypes } from '../../util/sdkLoader';
import { sendTransactionMsgContainingPhoneNoOrEmailNoti } from '../../util/slackNotify';
import {
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE,
  TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST,
} from '../../util/transaction';
import { LINE_ITEM_UNITS } from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import {
  doesMessageContainPhoneNumberOrEmail,
  encodeMsgContainingPhoneNoOrEmailMaybe,
} from '../../util/validators';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const GET_LISTING_DISTANCE = 'app/ListingPage/GET_LISTING_DISTANCE';
export const GET_ALL_LISTINGS_SUCCESS = 'app/ListingPage/GET_ALL_LISTINGS_SUCCESS';
export const GET_ALL_LISTINGS_ERROR = 'app/ListingPage/GET_ALL_LISTINGS_ERROR';

export const SET_TIMEZONE = 'app/ListingPage/SET_TIMEZONE';

export const RESET_CODE_REQUEST = 'app/ListingPage/RESET_CODE_REQUEST';
export const CHECK_CODE_REQUEST = 'app/ListingPage/CHECK_CODE_REQUEST';
export const CHECK_CODE_SUCCESS = 'app/ListingPage/CHECK_CODE_SUCCESS';
export const CHECK_CODE_ERROR = 'app/ListingPage/CHECK_CODE_ERROR';

export const ESTIMATE_BREAKDOWN_REQUEST = 'app/ListingPage/ESTIMATE_BREAKDOWN_REQUEST';
export const ESTIMATE_BREAKDOWN_SUCCESS = 'app/ListingPage/ESTIMATE_BREAKDOWN_SUCCESS';
export const ESTIMATE_BREAKDOWN_ERROR = 'app/ListingPage/ESTIMATE_BREAKDOWN_ERROR';

export const FETCH_SAME_CATEGORY_SUCCESS = 'app/ListingPage/FETCH_SAME_CATEGORY_SUCCESS';
export const RESET_LISTING_DATA = 'app/ListingPage/RESET_LISTING_DATA';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  distanceToUser: {
    error: 'listing_distance_calculating',
    distance: 0,
  },
  timeSlots: null,
  monthlyTimeSlots: {
    // '2019-12': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  litingTimeZone: null,
  fetchTimeSlotsInProgress: false,
  checkedCode: null,
  checkCodeInProgress: false,
  checkCodeErorr: null,
  estimateBreakdownInProgress: false,
  estimatedTx: null,
  sameCategoryListings: [],
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
    }

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };
    case GET_LISTING_DISTANCE:
      return {
        ...state,
        distanceToUser: payload,
        previousDistanceListingId: payload.listingId,
      };
    case SET_TIMEZONE:
      return {
        ...state,
        litingTimeZone: payload,
      };
    case CHECK_CODE_REQUEST:
      return { ...state, checkedCode: null, checkCodeInProgress: true, checkCodeErorr: null };
    case RESET_CODE_REQUEST:
      return { ...state, checkedCode: null, checkCodeInProgress: false, checkCodeErorr: null };
    case CHECK_CODE_SUCCESS:
      return { ...state, checkedCode: payload, checkCodeInProgress: false };
    case CHECK_CODE_ERROR:
      return { ...state, checkedCode: null, checkCodeInProgress: false, checkCodeErorr: payload };
    case ESTIMATE_BREAKDOWN_REQUEST:
      return { ...state, estimateBreakdownInProgress: true, estimateError: false };
    case ESTIMATE_BREAKDOWN_SUCCESS:
      return { ...state, estimateBreakdownInProgress: false, estimatedTx: payload };
    case ESTIMATE_BREAKDOWN_ERROR:
      return { ...state, estimateBreakdownInProgress: false, estimateError: payload };
    case FETCH_SAME_CATEGORY_SUCCESS:
      return { ...state, sameCategoryListings: payload };
    case RESET_LISTING_DATA:
      return { ...state, estimatedTx: null };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

  export const fetchTimeSlotsRequest = monthId => ({
    type: FETCH_TIME_SLOTS_REQUEST,
    payload: monthId,
  });
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      const [listing] = denormalisedResponseEntities(data);
      const listingId = listing && listing.id && listing.id.uuid;
      const searchResultURL = `listings/${listingId}/update-views`;
      drivelahApiPut(searchResultURL);
      const { timezone } =  (listing.attributes && listing.attributes.availabilityPlan) || { };
      dispatch({ type: SET_TIMEZONE, payload: timezone || 'Asia/Singapore' });
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => dispatch => {
  const monthId = monthIdStringInTimeZone(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    per_page: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendEnquiry = (listingId, message, bookingStart,bookingEnd,bookingStartDate,bookingEndDate,query,timeZone) => (dispatch, getState, sdk) => {
  // console.log("Sending the Enquiry")
  dispatch(sendEnquiryRequest());
  const { currentUser } = getState().user;
  bookingStart = timestampToDate(parseInt(bookingStartDate));
  bookingEnd = timestampToDate(parseInt(bookingEndDate));
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.masterProcessAlias,
    params: { listingId, bookingDisplayStart: bookingStart, bookingDisplayEnd: bookingEnd, message, bookingStart, bookingEnd },
  };

  if (bodyParams &&
    bodyParams.params &&
    !bodyParams.params.protectedData) {
    bodyParams.params.protectedData = {};
  }

  if (bodyParams &&
    bodyParams.params &&
    bodyParams.params.protectedData &&
    typeof bodyParams.params.protectedData === 'object') {
    // Assign values
    const userData = currentUser;
    const isReturning = get(userData, 'attributes.profile.metadata.intercomUserStat.success_booking_as_guest', 0);
    bodyParams.params.protectedData.guestVerificationStatus = get(userData, 'attributes.profile.publicData.guestIdentityVerificationStatus', 'null');
    bodyParams.params.protectedData.guestIsReturning = isReturning && isReturning > 0 ? true : false;
    bodyParams.params.protectedData.guestMilesClub = get(userData, 'attributes.profile.publicData.premiumUserTier', 'null');
  }

  return sdk.transactions
    .initiate(bodyParams,  {
      include: [
        'provider',
        'provider.profileImage',
        'provider.protectedData',
        'customer',
        'customer.profileImage',
        'customer.protectedData',
        'booking',
        'listing',
        'listing.images',
      ],
      'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'processName',
        'lineItems',
        'metadata',
        'protectedData',
      ],
      'fields.image': [
        'variants.square-small',
        'variants.square-small2x',
        'variants.landscape-crop',
        'variants.landscape-crop2x',
      ],
      expand: true,
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const txRaw = entities[0];

      const tx = txRaw;
      // console.log("Response Data 888888888888888", tx);
      const transactionId = tx.id;

      sendNotification({
        userId: currentUser.id.uuid,
        transactionId: transactionId.uuid,
        transition: TRANSITION_ENQUIRE,
        uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
        metadata: {
          query,
        },
      });


      let finalMessage = message;

      if (doesMessageContainPhoneNumberOrEmail(message)) {
        sendTransactionMsgContainingPhoneNoOrEmailNoti({
          transactionId: transactionId.uuid,
          senderId: currentUser.id.uuid,
          fromCheckoutPage: true,
          message,
        });

        finalMessage = encodeMsgContainingPhoneNoOrEmailMaybe(message);
      }



      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: finalMessage }).then(() => {

        dispatch(sendEnquirySuccess());

        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return tx;
      });
    })
    .catch(e => {

      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

const createParamsForFetchTimeSlots = (maxTimeSlots, bookingRange, listingId, tz) => {
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);
  let nextBoundary = findNextBookingTimeBoundary(tz, new Date());
  let nextMonth = null;
  let count = 0;
  const params = [];
  do {
    const numberOfSlot = Math.min(timeSlotsRange, bookingRange - timeSlotsRange * count);
    nextBoundary = findStartBoundary(nextMonth || nextBoundary, tz);
    nextMonth = findEndBoundary(nextBoundary, tz, numberOfSlot);
    params.push({
      start: nextBoundary,
      end: nextMonth,
      listingId,
    });
    count++;
  } while (timeSlotsRange * count < bookingRange);
  return params;
};

export const fetchListingTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = (listing.attributes && listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone) || 'Asia/Singapore';
    const nextBoundary = findNextBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const loadData = params => dispatch => {
  dispatch({ type: RESET_LISTING_DATA });
  const listingId = new UUID(params.id);
  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]).then(
    responses => {
      if (responses[0] && responses[0].data && responses[0].data.data) {
        const [listing] = denormalisedResponseEntities(responses[0]);
        fetchListingTimeSlots(dispatch, listing);
        dispatch(fetchSameCategoryListing(listing));
      }
      return responses;
    }
  );
};

export const setDistanceToUser = data => ({ type: GET_LISTING_DISTANCE, payload: data });

export const getDistanceToUser = data => dispatch => {
  const { listingLocation, userLocation, listingId } = data;

  if (!listingLocation) {
    return dispatch(setDistanceToUser({ distance: null, error: 'no_listing_location' }));
  }

  if (!userLocation) {
    const checkPermission = () => {
      if (typeof navigator === 'undefined' || !navigator.permissions) {
        return false;
      }

      navigator.permissions.query({ name: 'geolocation' }).then(permission => {
        if (permission.state === 'denied') {
          return dispatch(setDistanceToUser({ distance: null, error: 'geo_permission_deny' }));
        }
      });
    };

    checkPermission();

    navigator.geolocation.getCurrentPosition(result => {
      if (result && result.coords) {
        const distance = countDistanceTwoPoints(
          listingLocation.lat,
          listingLocation.lng,
          result.coords.latitude,
          result.coords.longitude
        );
        return dispatch(setDistanceToUser({ distance: distance.toFixed(2) }));
      }
    });

    // give user 15s to allow the geo permission request...
    setTimeout(() => {
      checkPermission();
    }, 15 * 1000);
  }

  if (listingLocation && userLocation) {
    const distance = countDistanceTwoPoints(
      listingLocation.lat,
      listingLocation.lng,
      userLocation.lat,
      userLocation.lng
    );
    return dispatch(setDistanceToUser({ distance: distance.toFixed(2), listingId }));
  }
};

export const resetCode = () => ({ type: RESET_CODE_REQUEST });

const checkCodeRequest = () => ({ type: CHECK_CODE_REQUEST });

export const checkCodeError = e => ({ type: CHECK_CODE_ERROR, error: true, payload: e });

export const checkCodeSuccess = checkedCode => ({
  type: CHECK_CODE_SUCCESS,
  payload: checkedCode,
});

export const checkCode = ({ code, valueId, data, transaction, listing }) => async (dispatch, getState) => {
  dispatch(checkCodeRequest());
  let genericPromo = false;
  const currentState = getState();
  const { currentUser } = currentState.user;
  const userId = currentUser.id.uuid;
  const { estimatedTx } = currentState.ListingPage;
  // console.log("Estimated data values", estimatedTx, transaction, listing)
  if ((code || valueId) && data) {
    try {
      const getPromo = code ? getPromosValue({ code, userId }) : getPromosValue({ valueId, userId });
      const promo = await getPromo;

      if (!promo || !promo.valid) {
        // console.log("Check the data availble", transaction)
        const bucketResponse = await getEventsByBucket('book');
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
          eventData: bucketResponse.data,
          props: {
            guest: currentUser,
            host: listing ? listing.author : null,
            trip: transaction,
            booking :  {...transaction, errorMessage: 'wrong_code'},
            listing,
            vas: transaction,
            ui: {
              button: 'Check',
              page: 'ListingScreen'
            }
          },
          userId,
        });
        dispatch(checkCodeError({
          type: 'error',
          name: 'wrong_code',
          message: 'Wrong code',
          status: 400,
          statusText: 'wrong_code',
          apiErrors: 'wrong_code',
        }));
        return;
      }

      genericPromo = !!promo.isGenericCode;
      const belongsToContact = !!promo.contactId;

      if (promo && !promo.valid) {
        const bucketResponse = await getEventsByBucket('book');
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
          eventData: bucketResponse.data,
          props: {
            guest: currentUser,
            host: listing ? listing.author : null,
            trip: transaction,
            booking :  {...transaction, errorMessage: 'code_not_belong_to_user'},
            listing,
            vas: transaction,
            ui: {
              button: 'Check Cupon Code Valid Error',
              page: 'ListingScreen'
            }
          },
          userId,
        });
        dispatch(checkCodeError({
          type: 'error',
          name: 'code_not_belong_to_user',
          message: promo.message,
          status: 403,
          statusText: 'code_not_belong_to_user',
          apiErrors: 'code_not_belong_to_user',
        }));


        // triggerAnalyticsEvent({
        //   event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
        //   eventData: bucketResponse.data,
        //   props: {
        //     guest: currentUser,
        //     host:transaction ? transaction.provider : null,
        //     trip: transaction,
        //     booking: transaction,
        //     listing,
        //     vas: transaction,
        //     ui: {
        //       button: 'Check Cupon Code',
        //       page: 'ListingScreen'
        //     }
        //   },
        //   userId,
        // });
        return;
      }

      if (belongsToContact && currentUser.id.uuid !== promo.contactId) {
        dispatch(checkCodeError({
          type: 'error',
          name: 'code_not_belong_to_user',
          message: 'This code does not belong to you',
          status: 403,
          statusText: 'code_not_belong_to_user',
          apiErrors: 'code_not_belong_to_user',
        }));

        const bucketResponse = await getEventsByBucket('book');
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
          eventData: bucketResponse.data,
          props: {
            guest: currentUser,
            host: listing ? listing.author : null,
            trip: transaction,
            booking :  {...transaction, errorMessage: 'code_not_belong_to_user'},
            listing,
            vas: transaction,
            ui: {
              button: 'Check Cupon Code User',
              page: 'ListingScreen'
            }
          },
          userId,
        });
        return;
      }

      const promoData = promo;
      const currentState = getState();
      const { estimatedTx, id: listingId } = currentState.ListingPage;

      if (promoData && promoData.valid && promoData.metadata && promoData.metadata.listingAttributeApplicable) {
        const promoMetadata = promoData.metadata;
        const listing = estimatedTx.listing;
        const publicDataAttributeList = Object.keys(promoMetadata).filter(i => i.startsWith('publicData-'));
        const privateDataAttributeList = Object.keys(promoMetadata).filter(i => i.startsWith('privateData-'));
        const metadataAttributeList = Object.keys(promoMetadata).filter(i => i.startsWith('metadata-'));
        let voucherValid = false;

        if (publicDataAttributeList.length) {
          const publicDataAttribute = publicDataAttributeList[0];
          const publicDataAttributeSearch = publicDataAttribute.replace(/-/g, '.');
          const listingValue = get(listing.attributes, publicDataAttributeSearch);

          if (listingValue === promoMetadata[publicDataAttribute]) {
            voucherValid = true;
          }
        }

        if (privateDataAttributeList.length) {
          const privateDataAttribute = privateDataAttributeList[0];
          const privateDataAttributeSearch = privateDataAttribute.replace(/-/g, '.');
          const listingValue = get(listing.attributes, privateDataAttributeSearch);

          if (listingValue === promoMetadata[privateDataAttribute]) {
            voucherValid = true;
          }
        }

        if (metadataAttributeList.length) {
          const metadataAttribute = metadataAttributeList[0];
          const metadataAttributeSearch = metadataAttribute.replace(/-/g, '.');
          const listingValue = get(listing.attributes, metadataAttributeSearch);

          if (listingValue === promoMetadata[metadataAttribute]) {
            voucherValid = true;
          }
        }

        if (!voucherValid) {
          dispatch(checkCodeError({
            type: 'error',
            name: 'code_not_belong_to_user',
            message: 'This code does not belong to this listing',
            status: 403,
            statusText: 'code_not_belong_to_user',
            apiErrors: 'code_not_belong_to_user',
          }));

          const bucketResponse = await getEventsByBucket('book');
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
            eventData: bucketResponse.data,
            props: {
              guest: currentUser,
              host: listing ? listing.author : null,
              trip: transaction,
              booking :  {...transaction, errorMessage: 'code_not_belong_to_user'},
              listing,
              vas: transaction,
              ui: {
                button: 'Promo Error Belong',
                page: 'ListingScreen'
              }
            },
            userId,
          });
          return;
        }
      }

      

      const lineItems = estimatedTx &&
        estimatedTx.attributes &&
        estimatedTx.attributes.lineItems ? estimatedTx.attributes.lineItems : [];

      let unitPurchase = lineItems.find(item => item.code === LINE_ITEM_UNITS);
      if(promoData && promoData.valid && promoData.discount && promoData.discount.percent_off && promoData.metadata && promoData.metadata.lineItem) {
        unitPurchase = lineItems.find(item => item.code === promoData.metadata.lineItem);

      }
    // const customerCommision = lineItems.find(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION);



    const totalPurchase = unitPurchase.unitPrice.amount * unitPurchase.quantity;
      const checkoutData = {
        id: 'TEST_TRANSACTION',
        code: code,
        lineItems: [
          {
            type: 'product',
            productId: listingId.uuid,
            unitPrice: totalPurchase,
          },
        ],
        contactId: currentUser.id.uuid,
      };

      const simulateResponse = await simulateCheckoutPromos(checkoutData);
      const processedResponse = await simulateResponse.json();
      const simulateData = processedResponse.data;

      if (!simulateData || !simulateData.totals || !simulateData.totals.discount) {
        dispatch(checkCodeError({
          type: 'error',
          name: 'wrong_code',
          message: 'Wrong code',
          status: 400,
          statusText: 'wrong_code',
          apiErrors: 'wrong_code',
        }));

        const bucketResponse = await getEventsByBucket('book');
        triggerAnalyticsEvent({
          event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
          eventData: bucketResponse.data,
          props: {
            guest: currentUser,
            host: listing ? listing.author : null,
            trip: transaction,
            booking: transaction,
            listing,
            vas: transaction,
            ui: {
              button: 'Promo Error',
              page: 'ListingScreen'
            }
          },
          userId,
        });
        return;
      }

      const discount = simulateData ? simulateData.totals.discount : 0;
      const metadata = simulateData ? simulateData.metadata : {};
      const voucherType = simulateData ? simulateData.discount : {};

      const bucketResponse = await getEventsByBucket('book');
      triggerAnalyticsEvent({
        event_id: event_trigger_ids.BOOK_USED_PROMO_CODE,
        eventData: bucketResponse.data,
        props: {
          guest: currentUser,
          host: listing ? listing.author : null,
          trip: transaction,
          booking: transaction,
          listing,
          vas: transaction,
          ui: {
            button: 'Promo Error',
            page: 'ListingScreen'
          }
        },
        userId,
      });

      dispatch(checkCodeSuccess({
        discount,
        code,
        isGenericCode: genericPromo,
        metadata,
        voucherType
      }));
    } catch (error) {
      dispatch(checkCodeError({
        type: 'error',
        name: 'code_validation_error',
        message: 'An error occurred while validating the code',
        status: 500,
        statusText: 'code_validation_error',
        apiErrors: error.message,
      }));

      console.error('Code validation error', error);
    }
  } else {
    dispatch(checkCodeError({
      type: 'error',
      name: 'code_needed',
      message: 'You need to input a code or select data',
      status: 400,
      statusText: 'code_needed',
      apiErrors: 'code_needed',
    }));
  }
};
// export const checkCode = async ({ code, valueId, data }) => (dispatch, getState) => {
//   dispatch(checkCodeRequest());
//   let genericPromo = false;
//   const { currentUser } = getState().user;
//   const userId = currentUser.id.uuid;
//   const { estimatedTx, id: listingId } = getState().ListingPage;
//   console.log("Check code values", code, valueId, data)
//   console.log("RRRRRR SSSSSS", estimatedTx, currentUser)
//   if ((code || valueId) && data) {
//     const getPromo = code ? getPromosValue({ code, userId }) : getPromosValue({ valueId, userId });
//     const { currentUser } = getState().user;
//     return getPromo
//       .then(async promo => {
//         if (!promo || !promo.valid) {
//           dispatch(
//             checkCodeError({
//               type: 'error',
//               name: 'wrong_code',
//               message: 'Wrong code',
//               status: 400,
//               statusText: 'wrong_code',
//               apiErrors: 'wrong_code',
//             })
//           );
//           return Promise.resolve();
//         }
//         const { isGenericCode } = promo || {};
//         genericPromo = !!isGenericCode;
//         const belongsToContact = !!promo.contactId;
//         if(promo && !promo.valid) {
//           dispatch(
//             checkCodeError({
//               type: 'error',
//               name: 'code_not_belong_to_user',
//               message: promo.message,
//               status: 403,
//               statusText: 'code_not_belong_to_user',
//               apiErrors: 'code_not_belong_to_user',
//             })
//           );
//           try {
//             const bucketResponse = await getEventsByBucket('book');
//               triggerAnalyticsEvent({
//                 event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
//                 eventData: bucketResponse.data,
//                 props: {
//                   guest: currentUser,
//                   host: estimatedTx ? estimatedTx.provider: null,
//                   trip: transaction,
//                   booking: transaction,
//                   listing: estimatedTx ? estimatedTx.listing: null,
//                   vas: transaction,
//                   ui: {
//                     button: 'Check Cupon Code',
//                     page: 'ListingScreen'
//                   }
//                 },
//                 userId,
//               });
//             } catch (error) {
//               console.log("Coupon code error", error)
//             }
//           return Promise.reject();
//         }
//         if (belongsToContact) {
//           if (!currentUser || !currentUser.id || currentUser.id.uuid !== promo.contactId) {
//             dispatch(
//               checkCodeError({
//                 type: 'error',
//                 name: 'code_not_belong_to_user',
//                 message: 'This code does not belong to you',
//                 status: 403,
//                 statusText: 'code_not_belong_to_user',
//                 apiErrors: 'code_not_belong_to_user',
//               })
//             );
//             try {
//               const bucketResponse = await getEventsByBucket('book');
//                 triggerAnalyticsEvent({
//                   event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
//                   eventData: bucketResponse.data,
//                   props: {
//                     guest: currentUser,
//                     host: estimatedTx ? estimatedTx.provider: null,
//                     trip: transaction,
//                     booking: transaction,
//                     listing: estimatedTx ? estimatedTx.listing: null,
//                     vas: transaction,
//                     ui: {
//                       button: 'Check Cupon Code',
//                       page: 'ListingScreen'
//                     }
//                   },
//                   userId,
//                 });
//               } catch (error) {
//                 console.log("Coupon code error", error)
//               }
//             return Promise.resolve();
//           }
//         }

//         return {
//           ...promo,
//         };
//       })
//       .then(async  (promoData) => {
//         const currentState = getState();
//         const { estimatedTx, id: listingId } = currentState.ListingPage;


//         if(promoData && promoData.valid && promoData.metadata && promoData.metadata.listingAttributeApplicable)  {
//           const promoMetadata = promoData.metadata;
//           const listing = estimatedTx.listing;
//           const publicDataAttributeList = Object.keys(promoMetadata).filter(i => { return i.startsWith('publicData-') });
//           const privateDataAttributeList = Object.keys(promoMetadata).filter(i => { return i.startsWith('privateData-') });
//           const metadataAttributeList = Object.keys(promoMetadata).filter(i => { return i.startsWith('metadata-') });
//           let voucherValid = false;

//           if(publicDataAttributeList.length ) {
//             const publicDataAttribute = publicDataAttributeList[0];
//             const publicDataAttributeSearch = publicDataAttribute.replace(/-/g, '.');
//             const listingValue = get(listing.attributes, publicDataAttributeSearch);

//             if(listingValue == promoMetadata[publicDataAttribute]) {
//               voucherValid = true
//             }
//           }
//           if(privateDataAttributeList.length) {
//             const privateDataAttribute = privateDataAttributeList[0];
//             const privateDataAttributeSearch = privateDataAttribute.replace(/-/g, '.');

//             const listingValue = get(listing.attributes, privateDataAttributeSearch);
//             if(listingValue == promoMetadata[privateDataAttribute]) {
//               voucherValid = true
//             }
//           }
//           if(metadataAttributeList.length) {
//             const metadataAttribute = metadataAttributeList[0];
//             const metadataAttributeSearch = metadataAttribute.replace(/-/g, '.');

//             const listingValue = get(listing.attributes, metadataAttributeSearch);
//             if(listingValue == promoMetadata[metadataAttribute]) {
//               voucherValid = true
//             }
//           }

//           if(!voucherValid) {
//             dispatch(
//               checkCodeError({
//                 type: 'error',
//                 name: 'code_not_belong_to_user',
//                 message: 'This code does not belong to this listing',
//                 status: 403,
//                 statusText: 'code_not_belong_to_user',
//                 apiErrors: 'code_not_belong_to_user',
//               })
//             );
//             try {
//               const bucketResponse = await getEventsByBucket('book');
//                 triggerAnalyticsEvent({
//                   event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
//                   eventData: bucketResponse.data,
//                   props: {
//                     guest: currentUser,
//                     host: estimatedTx ? estimatedTx.provider: null,
//                     trip: transaction,
//                     booking: transaction,
//                     listing: estimatedTx ? estimatedTx.listing: null,
//                     vas: transaction,
//                     ui: {
//                       button: 'Promo Error',
//                       page: 'ListingScreen'
//                     }
//                   },
//                   userId,
//                 });
//               } catch (error) {
//                 console.log("Coupon code error", error)
//               }
//             return Promise.reject();
//           }
//         }
//         const lineItems = estimatedTx &&
//           estimatedTx.attributes &&
//           estimatedTx.attributes.lineItems ? estimatedTx.attributes.lineItems : [];
//         let unitPurchase = lineItems.find(item => item.code === LINE_ITEM_UNITS);
//         if(promoData && promoData.valid && promoData.discount && promoData.discount.percent_off && promoData.metadata && promoData.metadata.lineItem) {
//           unitPurchase = lineItems.find(item => item.code === promoData.metadata.lineItem);

//         }
//         // const customerCommision = lineItems.find(item => item.code === LINE_ITEM_CUSTOMER_COMMISSION);



//         const totalPurchase = unitPurchase.unitPrice.amount * unitPurchase.quantity;
//         const checkoutData = {
//           id: 'TEST_TRANSACTION',
//           code: code,
//           lineItems: [
//             {
//               type: 'product',
//               productId: listingId.uuid,
//               unitPrice: totalPurchase,
//             },
//           ],
//         };

//         // if (genericPromo) {
//           checkoutData.contactId = (currentUser &&  currentUser.id && currentUser.id.uuid || "");
//         // }

//         return simulateCheckoutPromos(checkoutData);
//       })
//       .then(response => {
//         if (response.status > 199 && response.status < 300) {
//           return response.json();
//         } else {
//           return { data: null };
//         }
//       })
//       .then( async processedResponse => {
//         const simulateData = processedResponse.data;

//         if (!simulateData || !simulateData.totals || !simulateData.totals.discount) {

//           dispatch(
//             checkCodeError({
//               type: 'error',
//               name: 'wrong_code',
//               message: 'Wrong code',
//               status: 400,
//               statusText: 'wrong_code',
//               apiErrors: 'wrong_code',
//             })
//           );
//           try {
//             const bucketResponse = await getEventsByBucket('book');
//               triggerAnalyticsEvent({
//                 event_id: event_trigger_ids.BOOK_USED_PROMO_CODE_ERROR,
//                 eventData: bucketResponse.data,
//                 props: {
//                   guest: currentUser,
//                   host: estimatedTx ? estimatedTx.provider: null,
//                   trip: transaction,
//                   booking: transaction,
//                   listing: estimatedTx ? estimatedTx.listing: null,
//                   vas: transaction,
//                   ui: {
//                     button: 'Promo Error',
//                     page: 'ListingScreen'
//                   }
//                 },
//                 userId,
//               });
//             } catch (error) {
//               console.log("Coupon code error", error)
//             }
//           return Promise.resolve();
//         }
//         const discount = simulateData ? simulateData.totals.discount : 0;
//         const metadata = simulateData ? simulateData.metadata : {};
//         const voucherType = simulateData ? simulateData.discount : {};

//         try {
//             const bucketResponse = await getEventsByBucket('book');
//               triggerAnalyticsEvent({
//                 event_id: event_trigger_ids.BOOK_USED_PROMO_CODE,
//                 eventData: bucketResponse.data,
//                 props: {
//                   guest: currentUser,
//                   host: estimatedTx ? estimatedTx.provider: null,
//                   trip: transaction,
//                   booking: transaction,
//                   listing: estimatedTx ? estimatedTx.listing: null,
//                   vas: transaction,
//                   ui: {
//                     button: 'Promo Error',
//                     page: 'ListingScreen'
//                   }
//                 },
//                 userId,
//               });
//         } catch (error) {
//           console.log("Coupon code error", error)
//         }

//         dispatch(
//           checkCodeSuccess({
//             discount,
//             code,
//             isGenericCode: genericPromo,
//             metadata,
//             voucherType
//           })
//         );
//       });
//   } else {
    
//     return dispatch(
//       checkCodeError({
//         type: 'error',
//         name: 'code_needed',
//         message: 'You need to input a code or select data',
//         status: 400,
//         statusText: 'code_needed',
//         apiErrors: 'code_needed',
//       })
//     );
//   }
// };

export const estimateBreakdown = params => (dispatch, getState, sdk) => {
  const {
    processAlias,
    bookingStart,
    bookingEnd,
    listing,
    provider,
    currentTx,
    currentUser,
    discount,
    isPaidAmount,
    paidAmount,
    diffHours,
    regularHoursDiff,
    peakHoursDiff,
    credits,
    checkedCode = {},
    // tripFeesExperiment,
  } = params;

  const isYoung = currentUserIsYoungDriver(currentUser);
  const isCommercial = listingIsCommercial(listing);
  let transition = null;
  if (isYoung) {
    if (isCommercial) {
      transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
    } else {
      transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
    }
  } else {
    if (isCommercial) {
      transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
    } else {
      transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
    }
  }

  if(processAlias === config.updateBookingChargingProcessAlias) {
    transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST;
  }

  const bodyParams = isPaidAmount ? {
    processAlias: (processAlias && processAlias != 'undefined') ? processAlias : config.masterProcessAlias,
    transition,
    currentPage: 'ListingPage',
    params: {
      bookingStart,
      bookingEnd,
      bookingDisplayStart: bookingStart,
      bookingDisplayEnd: bookingEnd,
      credits,
      isPaidAmount,
      // tripFeesExperiment,
      // paidAmount,
      diffHours,
      ...(regularHoursDiff > 0) && { regularHoursDiff },
      ...(peakHoursDiff > 0) && { peakHoursDiff },
      voucherCode: {
        discount,
        ...checkedCode,
      },
      listingId: listing.id,
      provider: provider.id,
      currentTx,
      protectedData: {
        ...checkedCode,
        // tripFeesExperiment,
      },
    },
  } : {
    processAlias: (processAlias && processAlias != 'undefined') ? processAlias : config.masterProcessAlias,
    transition,
    currentPage: 'ListingPage',
    params: {
      bookingStart,
      bookingEnd,
      bookingDisplayStart: bookingStart,
      bookingDisplayEnd: bookingEnd,
      credits,
      // tripFeesExperiment,
      voucherCode: {
        discount,
        ...checkedCode,
      },
      listingId: listing.id,
      protectedData: {
        ...checkedCode,
        // tripFeesExperiment,
      },
    },
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  dispatch({ type: ESTIMATE_BREAKDOWN_REQUEST });

  return sdk.jh.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(response => {
      const [tx] = denormalisedResponseEntities(response);
      dispatch({ type: ESTIMATE_BREAKDOWN_SUCCESS, payload: tx });
      return tx;
    })
    .catch(e => {
      dispatch({ type: ESTIMATE_BREAKDOWN_ERROR, payload: storableError(e) });
    });
};

const fetchSameCategoryListing = currentListing => (dispatch, getState, sdk) => {
  const { price, publicData = {} } = currentListing.attributes || {};
  const { category } = publicData;
  if (!price) return;

  const minPrice = Math.floor(price.amount * 0.7);
  const maxPrice = Math.ceil(price.amount * 1.3);
  const categoryMaybe = category && !category.includes('ADMIN') ? { pub_category: category } : {};
  const params = {
    ...categoryMaybe,
    price: `${minPrice},${maxPrice}`,
    page: 1,
    per_page: 24,
    pub_isDeposit: false,
    meta_live: true,
    sort: 'pub_isSuperHostSorting,pub_instantBookingSorting,meta_isDrivelahGoSorting',
    include: ['author', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };
  return sdk.listings
    .query(params)
    .then(response => {
      const listings = denormalisedResponseEntities(response);
      if (listings.length) dispatch({ type: FETCH_SAME_CATEGORY_SUCCESS, payload: listings });
    })
    .catch(e => {

    });
};
