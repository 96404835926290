import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import tick from '../../assets/tripPage/tick.svg';
import css from './SectionsCarPhotos.css';

const ACCEPT_TYPE = 'image/*';

/**
 * isPickUp: boolean
 * Show that modal is pickup modal
 * isShowUpload: boolean
 * Show that transaction is show upload or not
 */

class UploadSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { existedPhotos, intl, type } = this.props;

    const customerTripPhotos = existedPhotos.filter(
      photo => !!photo.isCustomer && photo[type] === true
    );
    const providerTripPhotos = existedPhotos.filter(
      photo => !photo.isCustomer && photo[type] === true
    );
    const showYourPhotos = customerTripPhotos;
    const pickUpPhotos = [...showYourPhotos];

    const uploadTextLabel = <FormattedMessage id="SectionUploadCarStatus.remarks" />;
    const uploadPlaceholder = intl.formatMessage({
      id: 'SectionUploadCarStatus.uploadPlaceholder',
    });
    const formatPercentage = (value) => {
      const num = Number(value);
      return Number.isInteger(num) ? `${num}%` : `${num.toFixed(1)}%`;
    };
    const noteText = customerTripPhotos[0] && customerTripPhotos[0].note || '';
    const displayNote = noteText ? `${this.props.title === 'Fuel Gauge Photo' ? formatPercentage(Number(noteText).toFixed(1)) : noteText}` : '';

    return (
      <div className={css.content}>
        <div className={css.pickUpPhotosHolder}>
          <div className={classNames(css.photosContainer, css.pickUpPhotosContainer)}>
            {pickUpPhotos.map(photo => (
              <div className={css.photoWrapper} key={photo.timestamp}>
                <div className={css.photoWrapperInner}>
                  <img
                    src={photo.fileUrl}
                    className={css.tripPhoto}
                    onClick={() => window.open(photo.fileUrl, '_blank')}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label className={css.inputTextLabel}>{uploadTextLabel}</label>
          <input
            type="text"
            className={css.inputText}
            id="noteCarPhotoStatus"
            name="noteCarPhotoStatus"
            value={displayNote}
            disabled={true}
            placeholder={uploadPlaceholder}
          />
        </div>
      </div>
    );
  }
}

class SectionCarPhotosOnlyView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      rootClassName,
      className,
      intl,
      existedPhotos,
      onClick,
      holderClassName,
      title,
      description,
      type,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classNames(classes, holderClassName)}>
        <div onClick={onClick} className={classNames(css.sectionPhotoHolder)}>
          <div className={css.tickContainer}>
            <img src={tick} alt="tick" className={css.tick} />
          </div>

          <div>
            <h2 className={css.photoTitle}>{title}</h2>
            <p className={css.photoDescription}>{description}</p>
          </div>
        </div>
        <UploadSection
          existedPhotos={existedPhotos.filter(i => type in i)}
          intl={intl}
          type={type}
          title = {title}
        />
      </div>
    );
  }
}

SectionCarPhotosOnlyView.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionCarPhotosOnlyView.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCarPhotosOnlyView;
