import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import css from './ModalTripInstructions.css';
import  Modal  from '../../../Modal/Modal'
import get from 'lodash/get';

const ModalPickupInstructions = props => {
  const { isOpen, onClose, onManageDisableScrolling, listing } = props;
  const pickupDropoffInstructions = get(listing, 'attributes.publicData.pickupDropoffInstructions');
  const parkingInstructions = get(listing, 'attributes.publicData.parkingInstructions')

  const [showFullText, setShowFullText] = useState({
    parkingInstructions: false,
    pickupInstructions: false,
  });

  const formatTextWithLinksAndLineBreaks = text => {
    if (!text) return '-';
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a key={index} href={part} style={{ color: '#0aa3ad' }} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return (
        <React.Fragment key={index}>
          {part.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    });
  };

  const toggleReadMore = section => {
    setShowFullText(prevState => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const renderWithReadMore = (text, section) => {
    const isExpanded = showFullText[section];
    const maxLength =220;
    const maxLines = 3;

    if (!text) return '-';

    // Split text into lines based on '\n'
    const lines = text.split('\n');
    const lineCount = lines.length;

    // Determine if "Read More" is needed based on length or line count
    const needsReadMore = text.length > maxLength || lineCount > maxLines;

    // Decide which text to display (truncated or full)
    let displayedText = isExpanded
      ? text
      : (text.length > maxLength ? text.slice(0, maxLength) : lines.slice(0, maxLines).join('\n'));

    // Add ellipsis only if the text length exceeds maxLength
    if (!isExpanded && text.length > maxLength) {
      displayedText += '...';
    }

    return (
      <>
        {formatTextWithLinksAndLineBreaks(displayedText)}
        {needsReadMore && (
          <button
            className={css.readMoreButton}
            onClick={() => toggleReadMore(section)}
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        )}
      </>
    );
  };


  return (
    <Modal
      isOpen={isOpen}
      containerClassName={css.modal}
      onManageDisableScrolling={onManageDisableScrolling}
      onClose={onClose}
      closeButtonMessage=" "
    >
      {' '}
      <>
        <div
          className={`${css.modalInfoTripContainer}  ${css.displayFlex} ${css.justifyContentBetween}`}
        >
          <div className={`${css.pickupModalContainer} ${css.instructionsWrapper}`}>
            {parkingInstructions && (
              <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Parking instructions</h5>
                <span>{renderWithReadMore(parkingInstructions, 'parkingInstructions')}</span>
              </div>
            )}

            {pickupDropoffInstructions && (
              <div className={`${css.tripBox} ${css.borderBottom}`}>
                <h5>Pickup and return instructions</h5>
                <span>{renderWithReadMore(pickupDropoffInstructions, 'pickupInstructions')}</span>
              </div>
            )}
          </div>
        </div>
      </>
    </Modal>
  );
};

ModalPickupInstructions.defaultProps = {
  isOpen: false,
  onClose: () => {
  },
  onManageDisableScrolling: () => {
  },
};

ModalPickupInstructions.prototype = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onManageDisableScrolling: func.isRequired,
};

export default ModalPickupInstructions;
