import get from 'lodash/get';

import config from '../../../config';
import * as Actions from './actions';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';

const apiUrl = config.apiUrl;

const HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

const constructPass = tx => {
  const txCreatedAt = get(tx, 'attributes.createdAt', 0);
  const txLastTransitionedAt = get(tx, 'attributes.lastTransitionedAt', 0);
  return new Date(txCreatedAt).getTime() + new Date(txLastTransitionedAt).getTime();
};

const handleErrors = response => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const cancelUpdateBookingByAdmin = tx => async (dispatch, getState) => {
  if (!window.confirm('Are you sure?')) {
    return ;
  }
  dispatch(Actions.cancelUpdateBookingRequest());
  return fetch(`${apiUrl}/api/admin-actions/update-booking/cancel`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      p_tx_id: tx.id.uuid,
      pass: constructPass(tx),
    }),
  })
    .then(handleErrors)
    .then(res => res.json())
    .then(res => {
      dispatch(Actions.cancelUpdateBookingSuccess());
      dispatch(addMarketplaceEntities(res));
      alert('Update booking was successfully canceled!');
    })
    .catch(error => {
      dispatch(Actions.cancelUpdateBookingError());
      alert(error.message);
    })
    ;
};

export const acceptUpdateBookingByAdmin = tx => (dispatch, getState) => {
  if (!window.confirm('Are you sure?')) {
    return ;
  }
  dispatch(Actions.acceptUpdateBookingRequest());
  return fetch(`${apiUrl}/api/admin-actions/update-booking/accept`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({
      p_tx_id: tx.id.uuid,
      pass: constructPass(tx),
    }),
  })
    .then(handleErrors)
    .then(res => res.json())
    .then(res => {
      dispatch(Actions.acceptUpdateBookingSuccess());
      dispatch(addMarketplaceEntities(res));
      alert('Update booking was successfully updated!');
    })
    .catch(error => {
      dispatch(Actions.acceptUpdateBookingError());
      alert(error.message);
    })
    ;
};

export const pickUpDropOffByAdmin = async (id, transition, bodyParams) => {

  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    const response = await fetch(`${apiUrl}/api/admin-transition/pickup-dropoff-by-admin`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        id: id,
        transition: transition,
        bodyParams: bodyParams
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText} (${response.status})`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error during admin pickup/dropoff:', error);
    throw error;
  }
};
