import React, { useState, useRef, useEffect } from 'react';
import css from '../index.css';
import { useDispatch, useSelector } from 'react-redux';
import {EndTripContentText} from './constant'
import lockimg from '../../../assets/newPickupAndDropoff/Lock.svg';
import collectimg from '../../../assets/newPickupAndDropoff/Collect.svg';
import carimg from '../../../assets/newPickupAndDropoff/Small car.svg';
import img from '../../../assets/newPickupAndDropoff/endTrip.svg';
import IconSpinner from '../../IconSpinner/IconSpinner';
import ButtonsSection from '../../TripPanel/ButtonsSection';
import CodeGen from '../Components/LockBoxCode';
import { getLockboxCode } from '../../../util/notification';
import AlertBox from '../../AlertBox/AlertBox';
import { Button } from '../../../components';

const EndTripComponent = ({
    isPickUp,
    currentUser,
    isCustomer,
    onPushEventGTMPickUpDropOff,
    onPushPickUpAndDropOffEventGTM,
    fuelPrice,
    onEstimateFuelBreakdown,
    payForFuelSubmit,
    getDlGoDistance,
    setDropOffMarker,
    fuelNotificationWithoutCharge,
    transaction,
    currentPhotos,
    activePhotoSection,
    exteriorPhotos,
    interiorPhotos,
    odometerPhotos,
    fuelGaugePhotos,
    fuelReceiptPhotos,
    isShuEnabledCar,
    showPickUpSection,
    transitInProgress,
    transitionMaybe,
    onTransit,
    isProvider,
    allTripPhotos,
    isDropOff,
    isDrivelahGo,
    ownListing,
    onUploadInteriorPhotoToMetadata,
    shouldShowPickUpSection,
    shouldShowDropOffSection,
    uploadInteriorPhotoProgress,
    distanceFromPickUp,
    showDropOffSection,
    isPickupRequested,
    isLockBox,
    pickUpOdometer,
    engineStart,
    timeZone,
    onUpdateListingOdometerData
}) => {

  const [currentGuid, setCurrentGuide] = useState('GUID_DLGO_PUSH')
  const [lockBoxDigits, setLockBoxDigits] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const currentTextContent =  EndTripContentText.endtripcontent ;
  const listingId = transaction.listing.id.uuid;
  const Guide = {
    GUID_LOCKBOX: [
      {
        title: "Collect all of your belongings",
        IconComponent: collectimg,

      }, {
        title: `Ensure all the doors and windows are closed`,
        IconComponent: carimg,
      },
      {
        title: "Return the key back into the Lock box and ensure it is securely locked.",
        IconComponent: lockimg,
        Component: (data, text) =>  <CodeGen codeNumber={data} text={text} />,
      },
    ],
    GUID_DEFAULT: [
      {
        title: "Handover the keys to the host or follow instructions as provided by them.",
        IconComponent: lockimg,
      }, {
        title: "Collect all of your belongings",
        IconComponent: collectimg,
      }, {
        title: `Ensure all the doors and windows are closed`,
        IconComponent: carimg,
      }
    ],
    GUID_DLGO_CRANK: [
      {
        title: "The car will automatically lock once you press the end trip button.",
        IconComponent: lockimg,
      }, {
        title: "Ensure you have returned to key to glove box",
        IconComponent: lockimg,
      }, {
        title: `Collect all of your belongings`,
        IconComponent: collectimg,
      }, {
        title: `Ensure all the doors and windows are closed`,
        IconComponent: carimg,
      }
    ],
    GUID_DLGO_PUSH: [
      {
        title: "The car will automatically lock once you press the end trip button.",
        IconComponent: lockimg,
      }, {
        title: "Collect all of your belongings",
        IconComponent: collectimg,
      }, {
        title: `Ensure all the doors and windows are closed`,
        IconComponent: carimg,
      }
    ]
  }
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isDrivelahGo) {
      if (engineStart === 'crank') setCurrentGuide('GUID_DLGO_CRANK');
      else setCurrentGuide('GUID_DLGO_PUSH')
    }
    else if (isLockBox) setCurrentGuide('GUID_LOCKBOX');
    else setCurrentGuide('GUID_DEFAULT');
  }, [isDrivelahGo, isLockBox, engineStart])

  useEffect(() => {
    const getLockboxCode = async ()=> {
      await generateLockBoxCode()
    }
    if (isLockBox) {
      getLockboxCode();
    }
  }, [])

  const generateLockBoxCode = async ()=> {
    try {
      setLoading(true);
      const data = await getLockboxCode(listingId)
      setLockBoxDigits(data && data.code.split(''));
    } catch (error) {
      console.log(error.response.data.error);
      setAlertMessage(error.response.data.error)
      setAlert(true);
    } finally {
      setLoading(false);
    }
  }

  const regenerateLockBoxCode = (
    <Button
      className={css.retryButton}
      onClick={() => {
        setAlert(false);
        setAlertMessage(null);
        generateLockBoxCode();
      }}
    >
      Generate Again
    </Button>
  );


  return (
    <>
      <div className={css.stepContainer}>
        <div className={css.wrapper}>
          <div className={css.contentWrapper}>
            <h1>{currentTextContent.title}</h1>
            <p>{currentTextContent.message}</p>
          </div>
        </div>
        {Guide[currentGuid].map(({title, IconComponent, Component, NotificationComponent}, i) => (
         <React.Fragment>

           <div key={`guide-${title}`} className={css.allSetInstructions}>
           <div className={css.textWrapper_endTrip}>
            <img className={IconComponent==lockimg ? css.lockimg : ''} src={IconComponent} alt={IconComponent}/>
            <span>{title}</span>
           </div>
            {Component && Component(lockBoxDigits, "Code will reset once your trip ends")}
            { alert && (
            <AlertBox
              title="LockBox error"
              message={`${alertMessage}`}
              type="error"
              button={regenerateLockBoxCode}
            />
          )}
          </div>
         </React.Fragment>
        ))}
        <div className={css.endTripImg}><img src={img} alt={img}/></div>
      </div>

      {/* <div className={css.footerContainer}> */}
        {/* <div className={`${css.footerWrapper} ${css.stepContainer} ${css[currentTextContent.className]}`}> */}
          {/* <button
            className={css.footerButton}
            // onClick={handleNextStep}
            disabled={loading}
            >
            {loading ? "Uploading..." : currentTextContent.buttonText}
            <div>
              {loading && (
                <div className={css.uploadLoading}>
                  <IconSpinner />
                </div>
              )}
            </div>
          </button> */}
        { <ButtonsSection
            transaction={transaction}
            acceptButtonName={currentTextContent.buttonText}
            currentUser={currentUser}
            currentPhotos={currentPhotos}
            activePhotoSection={activePhotoSection}
            exteriorPhotos={exteriorPhotos}
            interiorPhotos={interiorPhotos}
            odometerPhotos={odometerPhotos}
            fuelGaugePhotos={fuelGaugePhotos}
            customerPickUpInteriorAction = {true}
            allTripPhotos = {allTripPhotos}
            fuelPrice={fuelPrice}
            onEstimateFuelBreakdown={onEstimateFuelBreakdown}
            payForFuelSubmit={payForFuelSubmit}
            fuelNotificationWithoutCharge={fuelNotificationWithoutCharge}
            setDropOffMarker={setDropOffMarker}
            getDlGoDistance={getDlGoDistance}
            pickUpOdometer={pickUpOdometer}
            onUploadInteriorPhotoToMetadata = {onUploadInteriorPhotoToMetadata}
            shouldShowPickUpSection = {shouldShowPickUpSection}
            shouldShowDropOffSection = {shouldShowDropOffSection}
            uploadInteriorPhotoProgress = {uploadInteriorPhotoProgress}
            transitInProgress = {transitInProgress}
            showPickUpSection = {showPickUpSection}
            onPushPickUpAndDropOffEventGTM ={onPushPickUpAndDropOffEventGTM}
            ownListing = {ownListing}
            transitionMaybe = {transitionMaybe}
            isShuEnabledCar = {isShuEnabledCar}
            isProvider = {isProvider}
            isDrivelahGo = {isDrivelahGo}
            isDropOff = {isDropOff}
            onTransit = {onTransit}
            onPushEventGTMPickUpDropOff= {onPushEventGTMPickUpDropOff}
            isCustomer = {isCustomer}
            isPickUp = {isPickUp}
            distanceFromPickUp = {distanceFromPickUp}
            showDropOffSection = {showDropOffSection}
            isPickupRequested = {isPickupRequested}
            isNewPickUpDropSection = {true}
            fuelReceiptPhotos = {fuelReceiptPhotos}
            loading = {loading}
            timeZone={timeZone}
            onUpdateListingOdometerData={onUpdateListingOdometerData}
          />
         }
        {/* </div>
      </div> */}
    </>
  );
};

export default EndTripComponent;
